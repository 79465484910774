import * as React from 'react';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../../stores';
import { Link } from 'react-router-dom';
import './Applications.css';
import { format } from 'date-fns';
import { Trans } from 'react-i18next';
import localeChanger from '../../helpers/localeChanger';
import i18next from 'i18next';
import Load from '../Load';
import i18n from 'i18next';
import checkImg from '../../assets/icons/competitionType-icon.svg';

export interface ApplicationsProps {}

export interface ApplicationsState {}

@injectAppState
@observer
class Applications extends React.Component<
  AppStateObserver,
  ApplicationsProps,
  ApplicationsState
> {
  state = {};

  componentDidMount() {
    this.props.appState.applicationsStore.loadEntities();
  }

  render() {
    return <React.Fragment>{this.renderApplications()}</React.Fragment>;
  }

  renderApplicationStatus(application) {
    if (application.applicationStatus === 'REFUSED_BY_APPLICANT')
      return <span>{i18next.t('Withdrawn')}</span>;
    if (application.applicationStatus === 'PASSED')
      return (
        <span className="text__success">
          <img src={checkImg} alt="checkImg" />
          &nbsp;{i18next.t(application.applicationStatus)}
        </span>
      );
    return <span>{i18next.t(application.applicationStatus)}</span>;
  }

  renderApplications() {
    const { applicationList, isLoadingList } =
      this.props.appState.applicationsStore;

    const { language } = this.props.appState.userStore;
    const localeDate = localeChanger(i18n.language ? i18n.language : 'ru');

    // console.log(toJS(applicationList));

    if (isLoadingList) {
      return (
        <div className="loader-container">
          <Load />
        </div>
      );
    }
    if (applicationList) {
      return (
        <div className="container">
          <div className="row">
            {applicationList.map((application, idx) => (
              <div className="col my-application" key={idx}>
                <p className="portal__text">
                  <Trans>ApplicationNumber</Trans>
                </p>
                <p className="portal__pi__text">
                  <span>{application.serialNumber}</span>
                </p>
                <p className="portal__text">
                  <Trans>ApplyDate</Trans>
                </p>
                <p className="portal__pi__text">
                  <span>
                    {application.dateApply
                      ? format(
                          Date.parse(application.dateApply),
                          'dd MMMM yyyy',
                          {
                            locale: localeDate,
                          }
                        )
                      : format(
                          Date.parse(application.dateRegistration),
                          'dd MMMM yyyy',
                          {
                            locale: localeDate,
                          }
                        )}
                  </span>
                </p>
                <p className="portal__text">
                  <Trans>Status</Trans>
                </p>
                <p className="portal__pi__text">
                  {this.renderApplicationStatus(application)}
                </p>
                <p className="portal__text">
                  <Trans>CompetitionName</Trans>
                </p>
                <p className="portal__pi__text">
                  <span>{application.competition.name}</span>
                </p>
                <p className="portal__text">
                  <Trans>CompetitionType</Trans>
                </p>
                <p className="portal__pi__text">
                  <span>
                    {application.programs[0]?.name || (
                      <Trans>NoCompetitionType</Trans>
                    )}
                  </span>
                </p>
                <p className="portal__text">
                  <Trans>CompetitionStatus</Trans>
                </p>
                <p className="portal__pi__text">
                  <span>
                    <Trans>{application.competition.status}</Trans>
                  </span>
                </p>
                <Link
                  className="button-input btn btn-success"
                  to={`/application/${application.id}/${application.competition.id}`}
                >
                  <Trans>ViewAsMember</Trans>
                </Link>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
}

export default Applications;
// <Link
//     to={`/application/${application.id}/${application.competition.id}`}
// >
