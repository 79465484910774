import { Trans } from 'react-i18next';
import React from 'react';
import { injectAppState } from '../../stores';

const BeneficiaryText = injectAppState((props) => {
  let { language } = props.appState.userStore.language;

  if (language === 'kz') {
    return (
      <span>
        &nbsp;
        <a
          target="_blank"
          href="https://fund.nu.edu.kz/kz/jas-social-impact-2022/#Igilik-alushy"
        >
          <Trans>beneficiaty</Trans>
        </a>
      </span>
    );
  }

  if (language === 'en') {
    return (
      <span>
        &nbsp;
        <a
          target="_blank"
          href="https://fund.nu.edu.kz/en/jas-social-impact-2022/#Beneficiary"
        >
          <Trans>beneficiaty</Trans>
        </a>
      </span>
    );
  }

  return (
    <span>
      &nbsp;
      <a
        target="_blank"
        href="https://fund.nu.edu.kz/jas-social-impact-2022/#Blagopoluchatel"
      >
        <Trans>linkInfo</Trans>
      </a>
    </span>
  );
});

export default BeneficiaryText;
