import * as React from 'react';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../../stores';
import {
  Link,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import './SignIn.css';
import { Trans } from 'react-i18next';
import logo from '../../assets/icons/logo.png';
import { Form } from 'react-bootstrap';
import { cubaREST } from '../../cubaREST';
import i18n from '../../i18n';
import i18next from 'i18next';
import eyeIcon from '../../assets/icons/eye.svg';

export interface SignInProps {}

export interface SignInState {}

@injectAppState
@observer
class SignIn extends React.Component<
  AppStateObserver & RouteComponentProps,
  SignInProps,
  SignInState
> {
  state = {
    username: '',
    password: '',
    usernameError: '',
    passwordError: '',
    error: i18next.t('enterLogin'),
    showPassword: false,
  };

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value });
  };
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    this.login();
  };

  login = () => {
    const { username, password } = this.state;

    if (this.validate(username, password)) {
      this.props.appState.userStore
        .login(this.state.username.trim(), this.state.password)
        .then(() => {
          this.props.appState.applicationsStore.getSocialCategory();
        })
        .then(() => {
          this.props.history.push('/');
        });
    }
  };

  validate = (username, password) => {
    if (username === '')
      this.setState({ usernameError: i18next.t('enterLogin') }); //enterLogin
    else this.setState({ usernameError: '' });

    if (password === '')
      this.setState({ passwordError: i18next.t('enterThePassword') });
    else this.setState({ passwordError: '' });

    return username && password;
  };

  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { language, loginError } = this.props.appState.userStore;

    if (cubaREST.restApiToken) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <div className="container">
          <div className="row m-0">
            <div className="col-md-6 px-4">
              <div className="portal__title">
                <Trans>PortalHeading</Trans>
              </div>
            </div>
            <div className="col-md-6 px-4">
              <Form
                className="login__card__home login__card--sign-in"
                onSubmit={this.handleOnSubmit}
              >
                <img src={logo} className="sign-in__logo" alt="logo" />
                <h1 className="fs-3 fw-bold form-header">
                  <Trans>authorization</Trans>:
                </h1>
                <div className="sign-in__login mb-3">
                  <input
                    type="text"
                    className="form-input"
                    placeholder={i18n.t('email')}
                    value={this.state.username}
                    onChange={(event) => this.handleUsernameChange(event)}
                  />
                </div>
                {this.state.usernameError && (
                  <p className="red-error">
                    {(this.state.usernameError = i18next.t('enterLogin'))}
                  </p>
                )}
                <div className="sign-in__password mb-3">
                  <input
                    type={this.state.showPassword ? 'text' : 'password'}
                    className="form-input"
                    placeholder={i18n.t('Password')}
                    value={this.state.password}
                    onChange={(event) => this.handlePasswordChange(event)}
                  />
                  <img
                    onClick={this.toggleShowPassword}
                    src={eyeIcon}
                    alt="eye icon"
                    className="input__password-icon"
                  />
                </div>
                {this.state.passwordError && (
                  <p className="red-error">
                    {(this.state.passwordError = i18next.t('enterThePassword'))}
                  </p>
                )}
                {this.renderLoginError(loginError)}
                <button
                  onClick={this.login}
                  className="form-button btn btn-success btn-lg mb-4"
                >
                  <Trans>Login</Trans>
                </button>
                <div className="btn-wrapper">
                  <Link to="/sign-up" className="back__home__reg">
                    <Trans>SignUp</Trans>
                  </Link>
                </div>
                <div className="btn-wrapper">
                  <Link to="forgot-password" className="back__home__reg">
                    <Trans>ForgotPassword</Trans>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderLoginError(error) {
    if (error && !this.state.usernameError && !this.state.passwordError) {
      return (
        <p className="red-error">
          <Trans>{error}</Trans>
        </p>
      );
    }
  }
}

export default withRouter(SignIn);
