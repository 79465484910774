import * as React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, NavLink } from 'react-router-dom';
import { AppStateObserver, injectAppState } from '../../stores';
import Applications from '../../components/Applications';
import Personal from '../../components/Personal';
import Contracts from '../Contracts';
import Messages from '../Messages';
import ChangePassword from '../ChangePassword';
import i18n from '../../i18n';
import './Cabinet.css';
import { Trans } from 'react-i18next';

export interface CabinetProps {}

export interface CabinetState {}

@injectAppState
@observer
class Cabinet extends React.Component<
  AppStateObserver,
  CabinetProps,
  CabinetState
> {
  state = {
    menuLinks: [
      { name: 'personalData', path: '/cabinet/personal' },
      // { name: "fsrMessages", path: "/cabinet/messages" },
      { name: 'myApplications', path: '/cabinet/applications' },
      // { name: "myContracts", path: "/cabinet/contracts" },
      { name: 'changePassword', path: '/cabinet/change-password' },
    ],
    links: [
      {
        path: '/',
        name: 'Main',
      },
      {
        path: '/cabinet',
        name: 'li4nyKabinet',
      },
    ],
  };

  logout = () => {
    this.props.appState.userStore.logout();
  };

  componentDidMount() {
    this.props.appState.breadcrumbsStore.setLinks(this.state.links);
  }

  componentWillUnmount() {
    this.props.appState.breadcrumbsStore.clearLinks();
  }

  render() {
    const { language } = this.props.appState.userStore;

    return (
      <>
        <h1 className="title__cabinet">
          <Trans>profile</Trans>
        </h1>
        <div className="cabinet__card">
          <div className="cabinet__menu">
            {this.state.menuLinks.map((item, index) => (
              <NavLink
                className="cabinet__item"
                activeClassName="cabinet__item_active"
                to={item.path}
                key={index}
              >
                {i18n.t(item.name)}
              </NavLink>
            ))}
          </div>
          <div className="h-divider" />
          <div className="cabinet-view__container">
            <Switch>
              <Route path="/cabinet/applications" component={Applications} />
              <Route path="/cabinet/personal" component={Personal} />
              <Route path="/cabinet/contracts" component={Contracts} />
              <Route path="/cabinet/messages" component={Messages} />
              <Route
                path="/cabinet/change-password"
                component={ChangePassword}
              />
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

export default Cabinet;
