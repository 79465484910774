import React from 'react';
import { Trans } from 'react-i18next';
import Indicators from '../Tables/Indicators';
import Plan from '../Tables/Plan';
class FieldEfficiencyAndResult extends React.Component<any, any> {
  state = {
    projectCoverage: this.props.efficiencyAndResult?.projectCoverage || null,
    indicators: this.props.efficiencyAndResult?.indicators || null,
    socialContribution:
      this.props.efficiencyAndResult?.socialContribution || null,
    goalAndTask: this.props.efficiencyAndResult?.goalAndTask || null,
    plan: this.props.efficiencyAndResult?.plan || null,
    application: this.props.app_id ? { id: this.props.app_id } : null,
  };
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.state[name] = value;
    this.props.handleEfficiencyAndResultChange(this.state);
  };
  handlePlanChange = (plan) => {
    this.setState({ plan: plan });
    this.props.handleEfficiencyAndResultChange(this.state);
  };

  render() {
    return (
      <>
        <h1 className={'extra-h1'}>
          <Trans>EFFECTIVNOST</Trans>
        </h1>
        <table style={{ paddingTop: '20px' }} className="talap-form">
          <tbody>
            <Indicators
              program={this.props.program}
              namespace="efficiencyAndResult"
            />
            <Plan
              program={this.props.program}
              namespace="efficiencyAndResult"
            />
          </tbody>
        </table>
      </>
    );
  }
}

export default FieldEfficiencyAndResult;
