import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { AppStateObserver, injectAppState } from 'stores';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { IG, PG, SE, SG, SI, SP } from 'consts';
import FileComponent from 'components/FileComponent';
import { ApplicationFormContext } from 'components/ApplicationFormProvider';
import ExtraFields from '../ExtraFields';
import Load from '../Load';
import './ProcessApplicationEditForm.css';
import validateFileSize                     from "../../helpers/validateFileSize";

export interface ProcessApplicationEditFormProps {
  espFile;
  password;
  application;
  tabs;
  mounted;
  experiences;
  directions;
  comment;
  checkProgram;
  handleCheckProgram;
}

export interface ProcessApplicationEditFormState {}

@injectAppState
@observer
class ProcessApplicationEditForm extends Component<
  ProcessApplicationEditFormProps & AppStateObserver,
  ProcessApplicationEditFormState
> {
  static contextType = ApplicationFormContext;

  state = {
    application: {},
    currentProgram: {
      name: '',
      id: null,
    },
    prompt: false,

    appDocs: this.props.application.appDocs,
    docFirst: this.props.application.docFirst,
    programs: this.props.application.programs,
    tabs: this.props.tabs,
    mounted: this.props.mounted,
    activeTab: 0,
    expenseItemName: '',
    expenseItem: {
      name: '',
      id: '',
    },
    relevance: this.props.application.relevance || null,
    efficiencyAndResult: this.props.application.efficiencyAndResult || null,
    resource: this.props.application.resource || null,
    sustainability: this.props.application.sustainability || null,
    innovativeness: this.props.application.innovativeness || null,
    projectIdea: this.props.application.projectIdea || null,
    businessModel: this.props.application.businessModel || null,
    effectiveness: this.props.application.effectiveness || null,
    socialCategory: this.props.application.socialCategory || null,
    scalability: this.props.application.scalability || null,
    organizationPotential: this.props.application.organizationPotential || null,
    environmental: this.props.application.environmental || null,
    expenseForm: {
      item: {},
      itemName: '',
      currency: {},
      currencyName: '',
      rateKZT: 0,
      percent: 0,
      pit: 0,
      total: 0,
      totalKZT: 0,
      cost: 0,
      grid: [],
    },
    expenses: this.props.application.expense,
    teamMembers: this.props.application.teamMembers || [],
    experienceForm: {
      email: '',
      iin: null,
      firstName: '',
      lastName: '',
      middleName: '',
      workExperience: null,
      role: '',
    },
    stateId: '',
    university: '',
    school: '',
    department: '',
    startStudyDate: '',
    endStudyDate: '',
    requestedAmount: this.props.application.requestedAmount || null,
    directionId:
      (this.props.application.direction &&
        this.props.application.direction.id) ||
      '',
    fileLoading: {
      type: null,
      owner: null,
    },
    direction: this.props.application.direction || {},
    ivanFile:
      this.props.application.appDocs.length > 0
        ? this.props.application.appDocs
        : this.props.application.docFirst,
    agreeProgramPassCheck: false,
    checkProgram: this.props.checkProgram,
  };

  // на какую вкладку хотим перейти
  private preCurrentProgram: { name: string; id: string };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.appState.applicationsStore.application.programs.length) {
      const { id, name } =
        this.props.appState.applicationsStore.application.programs[0];

      this.setState({ currentProgram: { id, name } });
      // this.context.handleApplicationFormChange({ programs: [{ id, name }] }, false);
    }

    const appDocs = this.props.appState.applicationsStore.application.appDocs;

    if (appDocs && appDocs.length) this.setState({ checkProgram: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentProgram.id &&
      prevState.currentProgram.id !== this.state.currentProgram.id
    ) {
      this.context.clearApplicationForm({
        programs: [{ ...this.state.currentProgram }],
      });
    }
  }

  loadFile = (id) => {
    return this.props.appState.filesStore.downloadFile(id);
  };

  handleCheckProgram = (bool) => {
    this.setState({ checkProgram: bool });

    if (this.props.handleCheckProgram) this.props.handleCheckProgram(bool);
  };

  handleFileChange = (files, docType, docOwner) => {
    if (!validateFileSize(50, files)) {
      toast.error(i18next.t("info.fileSizeLimit", { ns: "errors" }));
      return;
    }
    const { application } = this.props.appState.applicationsStore;
    let appDocs = application.appDocs;

    this.setState({
      fileLoading: { type: docType.name, owner: docOwner.name },
    });

    if (files[0]) {
      this.props.appState.filesStore
        .uploadFile(files[0])
        .then((response) => {
          const file = {
            valid: true,
            application: {
              id: application.id,
            },
            doc: {
              file: response.data,
            },
          };

          appDocs.push(file);

          this.context.handleApplicationFormChange({ appDocs });
          this.setState({ fileLoading: { type: null, owner: null } });
        })
        .catch((error) => {
          this.setState({ fileLoading: { type: null, owner: null } });
          toast.error(i18next.t('Error'));
          console.log(error);
        });
    }
  };

  createExpenses = () => {
    const expenses = this.props.application.expense;
    const { expenseItems, currencies } = this.props.appState.applicationsStore;

    let f = async () => {
      let result = [];
      for (let i = 0; i < expenses.length; i++) {
        let expense = expenses[i];
        for (let j = 0; j < expenseItems.length; j++) {
          const item = expenseItems[j];
          if (expense.itemName === '') {
            expense.item = expenseItems[0];
            break;
          }
          if (expense.itemName === item.name) {
            expense.item = item;
            break;
          }
        }
        for (let k = 0; k < currencies.length; k++) {
          const currency = currencies[k];
          if (expense.currencyName === '') {
            expense.currency = currencies[0];
            break;
          }
          if (expense.currencyName === currency.name) {
            expense.currency = currency;
            break;
          }
        }
        delete expense.currencyName;
        delete expense.itemName;
        expense.grid = [];
        expense.grid.push({
          planAmount: expense.cost,
          planDate: this.props.application.endStudyDate,
        });
        result.push(expense);
      }
      return result;
    };
    return f();
  };

  createDocs = () => {
    return (async () => {
      let appDocs = [];
      let appDocsLength = this.props.application.appDocs.length;
      // if appdocs exist
      if (appDocsLength > 0) {
        let index = 0;
        for (const doc of this.state.ivanFile) {
          let dublicate = false;
          let docs = doc.docs;
          let id = doc.id;
          let docId = doc.doc.id;
          let valid = doc.valid;
          // check if doc.id is not duplicated in state and props
          if (docId === this.props.application.appDocs[index].doc.id) {
            dublicate = true;
          }
          if (!dublicate) {
            try {
              let document =
                await this.props.appState.filesStore.updateDocument(doc.doc);
              docs.unshift({
                ...document,
                isArchive: false,
                applicationDoc: { id: id },
              });
              appDocs.push({
                doc: { ...document, isArchive: false },
                docs: [...docs],
                id: id,
                valid: valid,
              });
            } catch (error) {
              console.log(error);
            }
          } else {
            appDocs.push({ doc: doc.doc, docs: docs, id: id, valid: valid });
          }
          index++;
        }
      }
      if (appDocsLength === 0) {
        for (const doc of this.state.ivanFile) {
          try {
            let document = await this.props.appState.filesStore.updateDocument(
              doc
            );
            appDocs.push(document);
          } catch (error) {
            console.log(error);
          }
        }
      }
      return appDocs;
    })();
  };

  createTMDocs(teamMembers) {
    return (async () => {
      for (const tm of teamMembers) {
        let resume = tm.resume;
        try {
          tm.resume = await this.props.appState.filesStore.updateTMDocument(
            resume
          );
        } catch (error) {
          console.log(error);
        }
      }
      return teamMembers;
    })();
  }

  handleTabClick = (index) => {
    let tabs = [...this.state.tabs];

    for (let i = 0; i < tabs.length; i++) {
      tabs[i].active = i === index;
    }

    this.setState({ tabs, activeTab: index });
  };

  handleProgramChange = (event) => {
    let program = {
      name: event.target.dataset.name,
      id: event.target.dataset.id,
    };

    // запомним куда мы хотели перейти
    this.preCurrentProgram = program;

    if (!this.context.isFormChanged) {
      this.setState({ currentProgram: program });
      this.context.handleApplicationFormChange({ programs: [{ ...program }] });
    } else {
      this.setState({ prompt: true });
    }
  };

  handleExpenseItemChange = (event, index) => {
    const itemName = event.target.value;
    const expenses = this.props.application.expense;
    expenses[index].itemName = itemName;
  };

  handleCurrencyChange = (event, index) => {
    const expenses = this.props.application.expense;
    let form = expenses[index];
    form.currencyName = event.target.value;
    form.total = Number(form.cost);
    this.setState({ expenses: form });
  };

  handleInputChange = (event, index) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const expenses = this.props.application.expense;
    let form = expenses[index];
    form[name] = value;

    form.total = Number(form.cost);
  };

  addExpense = () => {
    const newExpense = { ...this.state.expenseForm };
    const expenses = this.props.application.expense;
    expenses.push(newExpense);
  };

  deleteExpense = (index) => {
    let expenses = this.props.application.expense;
    if (expenses.length > 0) expenses.splice(index, 1);
  };

  checkDocuments(files, file, list2, type, owner) {
    // check docFirst without list array

    let list = JSON.parse(JSON.stringify(this.state.ivanFile));

    if (files.length !== 0) {
      if (this.props.application.appDocs.length > 0) {
        let myDocument = {
          file: file,
          name: file.name,
          docType: type,
          docOwner: owner,
          applicant: this.props.appState.applicantsStore.applicant,
        };

        for (var i = 0; i < list.length; i++) {
          if (type.id === list[i].doc.docType.id) {
            list[i].doc = myDocument;
            for (var j = 0; j < list[i].docs.length; j++) {
              list[i].docs[j].isArchive = true;
            }
          }
        }
      } else {
        let myDocument = {
          file: file,
          name: file.name,
          docType: type,
          docOwner: owner,
          applicant: this.props.appState.applicantsStore.applicant,
        };
        list.push(myDocument);
      }
    }
    return list;
  }

  handleSCPInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    this.state[name] = value;
  };
  handleInputChangeExpenses = (event, index) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const expenses = [...this.state.expenses];
    let form = expenses[index];
    form[name] = value;

    form.pit = (Number(form.cost) / 100) * form.percent;
    form.total = Number(form.cost) + form.pit;
    this.setState({ expenses: form });
  };

  handleRelevanceChange = (rel) => {
    this.setState({ relevance: rel });
  };
  handleSCChange = (sc) => {
    this.setState({ socialCategory: sc });
  };
  handleEfficiencyAndResultChange = (effAndRes) => {
    this.setState({ efficiencyAndResult: effAndRes });
  };
  handleProjectIdeaChange = (pi) => {
    this.setState({ projectIdea: pi });
  };
  handleBusinessModelChange = (bm) => {
    this.setState({ businessModel: bm });
  };
  handleEffectivenessChange = (eff) => {
    this.setState({ effectiveness: eff });
  };
  handleScalabilityChange = (sc) => {
    this.setState({ scalability: sc });
  };
  handleResourceChange = (res) => {
    console.log('resource change');
    this.setState({ resource: res });
  };
  handleTMChange = (tm) => {
    this.setState({ teamMembers: tm });
  };
  handleSustainabilityChange = (sus) => {
    console.log('sus change');
    this.setState({ sustainability: sus });
  };
  handleInnovativenessChange = (inn) => {
    console.log('inn change');
    this.setState({ innovativeness: inn });
  };
  handleOrganizationPotentialChange = (op) => {
    this.setState({ organizationPotential: op });
  };
  handleEnvChange = (env) => {
    this.setState({ environmental: env });
  };
  handleAgreementJAS = () => {
    let lang =
      this.props.appState.userStore.language ||
      localStorage.getItem('i18nextLng');

    if (lang === 'ru') {
      return (
        <p className="form__question">
          Будете ли Вы проходить полную программу
          <a
            className="tabs__link"
            target="_blank"
            href="https://fund.nu.edu.kz/jas-social-impact-2022/"
          >
            {' '}
            JAS Camp{' '}
          </a>
          до 8 недель инкубации+до 2 недель модуль импакт предпринимательства +
          оценка команды)?
        </p>
      );
    }
    if (lang === 'en') {
      return (
        <p className="form__question">
          Will you participate in the full{' '}
          <a
            className="tabs__link"
            target="_blank"
            href="https://fund.nu.edu.kz/en/jas-social-impact-2022/"
          >
            JAS Camp{' '}
          </a>{' '}
          program (up to 8 weeks of incubation+ up to 2 weeks of
          Entrepreneurship impact module + team evaluation)?
        </p>
      );
    }
    if (lang === 'kz') {
      return (
        <p className="form__question">
          Сіз{' '}
          <a
            className="tabs__link"
            target="_blank"
            href="https://fund.nu.edu.kz/kz/jas-social-impact-2022/"
          >
            JAS Camp
          </a>{' '}
          толық бағдарламасынан өтесіз бе (инкубацияның 8 аптасына дейін+2
          аптаға дейін импакт-кәсіпкерлік модулі + команданы бағалау)?
        </p>
      );
    }
  };

  transLang = (lang, doc) => {
    switch (lang) {
      case 'en':
        return (doc =
          'Documents confirming the passage of acceleration / incubation within the last two years (1 zip archive)');
      case 'kz':
        return (doc =
          'Соңғы екі жыл ішінде жеделдету/инкубациядан өткенін растайтын құжаттар (1 zip мұрағат)');
      case 'ru':
      default:
        return (doc =
          'Документы, подтверждающие прохождение акселерации/инкубации в течение последних двух лет (1 zip архив)');
    }
  };

  render() {
    const { isCommittingEntity, isLoadingApplication, application } =
      this.props.appState.applicationsStore;
    const { competition } = this.props.appState.competitionsStore;
    const requiredDocuments = competition.reqDocs;

    if (isCommittingEntity || isLoadingApplication) {
      return (
        <div className="loader-container">
          <Load />
        </div>
      );
    }

    if (application) {
      return (
        <>
          {
            <Popup open={this.state.prompt} closeOnDocumentClick modal>
              <>
                <div className="modal">
                  <div className="modal__header modal-title">
                    <h1>
                      <Trans>Confirmation</Trans>
                    </h1>
                  </div>
                  <div
                    className="modal__content"
                    style={{ textAlign: 'center' }}
                  >
                    <Trans>changeProgram</Trans>
                    <br />
                    <Trans>fieldLoose</Trans>
                  </div>
                  <div className="btn__actions">
                    <button
                      className="button-input btn btn-success"
                      onClick={() => {
                        this.setState({
                          currentProgram: this.preCurrentProgram,
                        });
                        this.setState({ prompt: false });
                      }}
                    >
                      <Trans>Yes</Trans>
                    </button>
                    <button
                      className="button-input btn btn-light btn-popup"
                      onClick={() => this.setState({ prompt: false })}
                    >
                      <Trans>Cancel</Trans>
                    </button>
                  </div>
                </div>
              </>
            </Popup>
          }
          <div className="m-t input-tabs">
            <div className="input-tabs__wrap">{this.renderProgramsTabs()}</div>
            <div className="compform__divider" />
          </div>

          <ExtraFields
            program={this.state.currentProgram}
            app_id={application.id}
            relevance={this.state.relevance}
            efficiencyAndResult={this.state.efficiencyAndResult}
            resource={this.state.resource}
            teamMembers={this.state.teamMembers}
            environmental={application.environmental}
            sustainability={this.state.sustainability}
            innovativeness={this.state.innovativeness}
            projectIdea={this.state.projectIdea}
            businessModel={this.state.businessModel}
            effectiveness={this.state.effectiveness}
            scalability={this.state.scalability}
            socialCategory={this.state.socialCategory}
            organizationPotential={this.state.organizationPotential}
            handleRelevanceChange={this.handleRelevanceChange}
            handleSocialCategoryChange={this.handleSCChange}
            handleEfficiencyAndResultChange={
              this.handleEfficiencyAndResultChange
            }
            handleResourceChange={this.handleResourceChange}
            handleSustainabilityChange={this.handleSustainabilityChange}
            handleInnovativenessChange={this.handleInnovativenessChange}
            handleTMChange={this.handleTMChange}
            handleProjectIdeaChange={this.handleProjectIdeaChange}
            handleBusinessModelChange={this.handleBusinessModelChange}
            handleEffectivenessChange={this.handleEffectivenessChange}
            handleScalabilityChange={this.handleScalabilityChange}
            handleOrganizationPotentialChange={
              this.handleOrganizationPotentialChange
            }
            handleEnvChange={this.handleEnvChange}
          />

          {[SG, IG].includes(this.state.currentProgram.name) && (
            <>
              {this.handleAgreementJAS()}
              <div className={'td-radio radio__tb1 field-radio__btn--group'}>
                <input
                  type="radio"
                  name="check"
                  id="checkYes"
                  checked={this.state.checkProgram}
                  onChange={() => this.handleCheckProgram(true)}
                />
                <label htmlFor="checkYes" className="td-radio__label">
                  <Trans>yesJAS</Trans>
                </label>
              </div>
              <div className={'td-radio radio__tb1 field-radio__btn--group'}>
                <input
                  type="radio"
                  name="check"
                  id="checkNo"
                  checked={!this.state.checkProgram}
                  onChange={() => this.handleCheckProgram(false)}
                />
                <label htmlFor="checkNo" className="td-radio__label">
                  <Trans>noJAS</Trans>
                </label>
              </div>
            </>
          )}

          {!this.state.checkProgram && requiredDocuments && (
            <div>
              {requiredDocuments
                .slice()
                .sort((a, b) => a.docType.name.localeCompare(b.docType.name))
                .map((document, idx) => {
                  return (
                    <div key={document.id}>
                      <div className="form__question__descrption m-t-20">
                        {document.docType &&
                          this.transLang(
                            this.props.appState.userStore.language,
                            document.docType.name
                          )}
                        {document.mandatory && (
                          <span className="mandatory">*</span>
                        )}
                      </div>
                      <div className="inputfile-wrap inputfile-wrap__download">
                        {this.renderAppDocFile()}
                        {this.renderFileLoader(
                          document.docType && document.docType.name,
                          (document.docOwner && document.docOwner?.name) || null
                        )}
                        <label
                          className="button-input btn btn-success"
                          htmlFor={`inputfile-${idx}`}
                        >
                          <Trans>ChooseFile</Trans>
                        </label>
                        <input
                          type="file"
                          onChange={(e) =>
                            this.handleFileChange(
                              e.target.files,
                              document.docType,
                              document.docOwner
                            )
                          }
                          id={`inputfile-${idx}`}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {[
            'Start Grant',
            'Impulse Grant',
            'Idea Grant',
            'Social Projects',
          ].includes(this.state.currentProgram.name) && (
            <>
              <div className="compform__divider" />
              <p className="form__question__descrption">
                <Trans>agreement</Trans>
              </p>
              <p className="form__question__descrption">
                <Trans>agreement2</Trans>
              </p>
            </>
          )}
        </>
      );
    }
  }

  renderProgramsTabs() {
    const { compPrograms } = this.props.appState.competitionsStore;

    return (
      <>
        {compPrograms &&
          compPrograms.map((program) => (
            <div key={program.id}>
              <div className="input-tabs__name">
                <input
                  type="checkbox"
                  className="input-tabs__input"
                  data-name={program.name}
                  data-id={program.id}
                  id={program.id}
                  checked={program.name === this.state.currentProgram.name}
                  onChange={(event) => this.handleProgramChange(event)}
                />
                <label className="input-tabs__label" htmlFor={program.id}>
                  {program.name}
                </label>
              </div>
            </div>
          ))}
      </>
    );
  }

  renderDocFile(document, docFirst) {
    // console.log(toJS(docFirst));
    if (docFirst.length > 0) {
      let doc;

      for (let i = 0; i < docFirst.length; i++) {
        if (
          document.docType.id === docFirst[i].docType.id &&
          document.docOwner.id === docFirst[i].docOwner.id
        ) {
          doc = docFirst[i];
          return (
            <FileComponent
              getFile={this.loadFile}
              id={doc.file.id}
              name={doc.file.name}
              extension={doc.file.extension}
              withFileIcon={false}
              withDownloadIcon={false}
            />
          );
        }
      }
    }
  }

  renderAppDocFile() {
    const appDocs = this.props.application.appDocs;
    return appDocs.map((doc) => {
      const file = doc.doc.file;

      return (
        <div className="flex"
             key={file.id}>
          <FileComponent
            key={file.id}
            id={file.id}
            name={file.name}
            extension={file.extension}
            withFileIcon={false}
            withDownloadIcon={false}
          />&nbsp;
          <span onClick={() => this.deleteFile(doc)} className="icon-delete " style={{ marginTop: '5px' }}>+</span>
        </div>
      );
    });
  }

  deleteFile(appDoc) {
    const appDocs = this.props.application.appDocs;
    const index = appDocs.findIndex(doc => doc.doc.file.id === appDoc.doc.file.id);
    if (index !== -1) {
      appDocs.splice(index, 1);
    }
    this.context.handleApplicationFormChange({ appDocs });
  }

  renderArchivedFile(document, ivanFile) {
    if (ivanFile.length > 0) {
      let doc;
      let idx;
      for (var i = 0; i < ivanFile.length; i++) {
        if (document.docType.id === ivanFile[i].doc.docType.id) {
          idx = i;
        }
      }
      let archivedFiles = [];
      if (typeof idx !== 'undefined') {
        for (let j = 0; j < ivanFile[idx].docs.length; j++) {
          if (ivanFile[idx].docs.length > 2) {
            for (let j = 0; j < ivanFile[idx].docs.length; j++) {
              if (ivanFile[idx].docs[j].isArchive === true) {
                archivedFiles.push(ivanFile[idx].docs[j]);
              }
            }
            if (archivedFiles.length > 1) {
              doc = archivedFiles[1];
              return (
                <FileComponent
                  getFile={this.loadFile}
                  id={doc.file.id}
                  name={doc.file.name}
                  extension={doc.file.extension}
                  withFileIcon={false}
                  withDownloadIcon={false}
                />
              );
            }
          }
          if (ivanFile[idx].docs.length <= 2) {
            for (
              let k = 0;
              k < this.props.application.appDocs[idx].docs.length;
              k++
            ) {
              if (
                this.props.application.appDocs[idx].docs[k].isArchive ===
                  false &&
                ivanFile[idx].docs[k].isArchive === true
              ) {
                doc = ivanFile[idx].docs[k];
                return (
                  <FileComponent
                    getFile={this.loadFile}
                    id={doc.file.id}
                    name={doc.file.name}
                    extension={doc.file.extension}
                    withFileIcon={false}
                    withDownloadIcon={false}
                  />
                );
              }
            }
            if (ivanFile[idx].docs[j].isArchive === true) {
              doc = ivanFile[idx].docs[j];
              return (
                <FileComponent
                  getFile={this.loadFile}
                  id={doc.file.id}
                  name={doc.file.name}
                  extension={doc.file.extension}
                  withFileIcon={false}
                  withDownloadIcon={false}
                />
              );
            }
          }
        }
      }
      return;
    }
  }

  renderArchivedFile2(document, appDocs) {
    if (appDocs.length > 0) {
      let doc;
      let idx;
      for (let i = 0; i < appDocs.length; i++) {
        if (document.docType.id === appDocs[i].doc.docType.id) {
          idx = i;
          break;
        }
      }
      let archivedFiles = [];
      if (typeof idx !== 'undefined') {
        for (let j = 0; j < appDocs[idx].docs.length; j++) {
          if (appDocs[idx].docs[j].isArchive === true) {
            archivedFiles.unshift(appDocs[idx].docs[j]);
          }
        }
      }
      if (archivedFiles.length > 1) {
        doc = archivedFiles[1];
        return (
          <FileComponent
            getFile={this.loadFile}
            id={doc.file.id}
            name={doc.file.name}
            extension={doc.file.extension}
            withFileIcon={false}
            withDownloadIcon={false}
          />
        );
      }
      return;
    }
  }

  renderFileLoader(type, owner) {
    if (
      this.state.fileLoading.type === type &&
      this.state.fileLoading.owner === owner
    ) {
      return <Load />;
    }
  }
}

export default ProcessApplicationEditForm;
