import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import renderHeader from './title';
import { ApplicationFormContext } from 'components/ApplicationFormProvider';
import { IProgram } from 'components/ApplicationFormProvider/types';
import { sectionNumber } from '../../ApplicationFormProvider/sectionNumber';
import i18next from 'i18next';

interface Props {
  namespace: string;
  program: IProgram;
}

const Indicators = (props: Props) => {
  const {
    applicationForm,
    handleApplicationFormChange,
    readOnly,
    getError,
    clearError,
  } = useContext(ApplicationFormContext);
  const [indicators, setIndicators] = useState([]);

  // let unitOfMeasurement = [
  //   { unit: i18next.t('indicatorsUnit.unit') },
  //   { unit: i18next.t('indicatorsUnit.percent') },
  //   { unit: i18next.t('indicatorsUnit.people') },
  // ];

  const unitOfMeasurement = [
    { unit: 'unit' },
    { unit: 'percent' },
    { unit: 'people'},
  ];

  useEffect(() => {
    const maxIndicators = applicationForm[props.namespace].indicators ?? [];

    while (maxIndicators.length < 4)
      maxIndicators.push({
        unit: '',
        name: '',
        ultimateGoal: 0,
      });

    setIndicators(maxIndicators);
  }, [applicationForm[props.namespace].indicators]);

  const addIndicators = () => {
    const indForm = {
      name: '',
      unit: '',
      ultimateGoal: 0,
    };
    const indicatorsOfSuccess = [...indicators];
    indicatorsOfSuccess.push(indForm);
    setIndicators(indicatorsOfSuccess);
    handleApplicationFormChange({
      [props.namespace]: { indicators: indicatorsOfSuccess },
    });
  };

  const deleteIndicators = (index) => {
    let indicatorsOfSuccess = [...indicators];
    if (indicatorsOfSuccess.length > 0) indicatorsOfSuccess.splice(index, 1);

    setIndicators(indicatorsOfSuccess);
    handleApplicationFormChange({
      [props.namespace]: { indicators: indicatorsOfSuccess },
    });
  };

  const handleInputChangeIndicators = (event, index) => {
    clearError('indicators');
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let row = indicators[index];

    if (name === 'ultimateGoal') {
      value = value.replace(/\s/g, '');
      if (value.length > 10 || isNaN(Number(value))) return;
      row[name] = Number(value);
    } else {
      row[name] = value;
    }
    indicators[index][name] = row[name];

    setIndicators(indicators);
    handleApplicationFormChange({ [props.namespace]: { indicators } });
  };

  const handleSelectValue = ({ target }, index) => {
    clearError('indicators');

    const name = target.name;
    const value = target.value;

    let indicatorsOfSuccess = [...indicators];

    indicatorsOfSuccess[index] = {
      ...indicatorsOfSuccess[index],
      [name]: value,
    };

    setIndicators(indicatorsOfSuccess);
    handleApplicationFormChange({
      [props.namespace]: { indicators: indicatorsOfSuccess },
    });
  };

  const renderIndicators = () => {
    return (
      <>
        {/* indicators: {JSON.stringify(indicators)} */}
        <table className="second__table">
          <tbody>
            <tr className="first__table-title">
              <th id="width-60">
                <Trans>indicatorsObj.nameInd</Trans>
              </th>
              <th id="width-20">
                <Trans>indicatorsObj.unit</Trans>
              </th>
              <th id="width-20">
                <Trans>indicatorsObj.ultimateGoal</Trans>
              </th>
            </tr>
            {indicators &&
              indicators.map((ind, idx) => {
                return (
                  <tr key={idx}>
                    <td className="expense-plan__td m-t-10">
                      <input
                        value={ind.name}
                        className="expense-plan__input"
                        id={`name_${idx}`}
                        type="text"
                        maxLength={200}
                        name="name"
                        onChange={(event) =>
                          handleInputChangeIndicators(event, idx)
                        }
                        readOnly={readOnly}
                      />
                    </td>
                    <td className="expense-plan__td">
                      <select
                        value={indicators[idx].unit}
                        name="unit"
                        onChange={(event) => handleSelectValue(event, idx)}
                        className="default-input default-input--select"
                        disabled={readOnly}
                      >
                        <option value="" disabled hidden>
                          {i18next.t('choose')}
                        </option>
                        {unitOfMeasurement.map((source, i) => {
                          return (
                            <option key={i} value={source.unit}>
                              {i18next.t(`indicatorsUnit.${source.unit}`)}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    <td className="expense-plan__td">
                      <input
                        value={
                          ind?.ultimateGoal === 0
                            ? ''
                            : ind?.ultimateGoal &&
                              ind?.ultimateGoal.toLocaleString('ru')
                        }
                        className="expense-plan__input"
                        id={`ultimateGoal_${idx}`}
                        type="text"
                        name="ultimateGoal"
                        onChange={(event) =>
                          handleInputChangeIndicators(event, idx)
                        }
                        readOnly={readOnly}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </>
    );
  };

  const programName = props.program.name;

  return (
    <>
      <h3 className="form__question" id="indicators">
        {sectionNumber.renderSubSection(`${props.namespace}.indicators`)}.{' '}
        {renderHeader(programName)}
      </h3>
      <p className="form__question__descrption">
        <Trans>indicatorsOfSuccessExtra</Trans>
      </p>
      <div
        className={
          getError('indicators')
            ? 'application-table__body m-t red-border-error'
            : 'application-table__body m-t'
        }
      >
        <div className="application-table">{renderIndicators()}</div>
      </div>
      {/* <div className="add-expense__container">
                <button className="add-expense" onClick={this.addIndicators}>
                    <Trans>Add</Trans>
                </button>
            </div> */}
    </>
  );
};

export default Indicators;
