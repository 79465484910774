export const SI = 'Social Invest';
export const SE = 'Social Entrepreneurship Grant';
export const SP = 'Social Projects';
export const IG = 'Impulse Grant';
export const SG = 'Start Grant';
export const PG = 'Idea Grant';

export const applicationActiveStatuses = [
  'NEW',
  'PENDING',
  'REWORK_DOCS',
  'TEAMLEAD_REWORK',
  'REVISION_AFTER_VALIDATION',
  'BUSINESS_PLAN',
];
export const applicationCompleteStatuses = [
  'PHASE_1_FINISHED',
  'VALIDATION',
  'INTERNAL_VOTING_DIRECTION',
  'INTERNAL_VOTING',
  'WORK_ORGAN',
  'JAS_CAMP',
  'EXTERNAL_VOTING_DIRECTION',
  'EXTERNAL_VOTING',
  'PERMISSION',
];
export const applicationFailStatuses = [
  'NOT_PASSED',
  'REFUSED',
  'REFUSED_BY_APPLICANT',
];
