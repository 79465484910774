import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { IG, PG, SE, SG, SI, SP } from '../consts';
import wrongText from './wrongText';
import { emailRegex } from 'const';

interface IErrors extends Record<string, string> {}

export const useVerify = () => {
  const [errors, setErrors] = useState<IErrors>({});
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    setIsValid(!Object.keys(errors).length);
  }, [errors]);

  const addError = (error: Record<string, string>) => {
    const [[errorKey, errorValue]] = Object.entries(error);

    setErrors({ ...errors, [errorKey]: errorValue });
  };

  const getError = (errorKey: string) => errors[errorKey];

  const clearError = (errorKey: string) => {
    const updatedErrors = { ...errors };
    delete updatedErrors[errorKey];
    setErrors(updatedErrors);
  };

  const clearAllErrors = () => setErrors({});

  const verifyApplication = (application, applicant) => {
    const errors = checkApplication(application, applicant);
    console.log(errors);

    setErrors(errors);

    return !Object.keys(errors).length;
  };

  return {
    errors,
    getError,
    addError,
    clearError,
    clearAllErrors,
    isValid,
    verifyApplication,
  };
};

const setErrorText = (inputName: string): string => {
  return i18next.t(`applicationFormValidation.${inputName}`, {
    ns: 'errors',
  });
};

export const checkApplication = (application, applicant) => {
  const errors: IErrors = {};

  const addError = (error: Record<string, string>) => {
    Object.assign(errors, error);
  };

  // applicationForm
  const applicationFormErrors = checkApplicationForm(
    application.applicationForm
  );

  if (applicationFormErrors) addError(applicationFormErrors);
  // organizationPotential
  if (application.programs[0]?.name === IG) {
    const organizationPotentialErrors = checkOrganizationPotential(
      application.programs,
      application.organizationPotential
    );
    if (organizationPotentialErrors) addError(organizationPotentialErrors);
  }

  // projectIdea
  const projectIdeaErrors = checkProjectIdeaFields(
    application.programs,
    application.projectIdea
  );
  if (projectIdeaErrors) addError(projectIdeaErrors);

  // Scalability
  if (application.programs[0]?.name === PG) {
    const scalabilityErrors = checkScalability(
      application.programs,
      application.effectiveness
    );
    if (scalabilityErrors) addError(scalabilityErrors);
  }

  // Scalability
  if (application.programs[0]?.name === IG) {
    const scalabilityErrors = checkScalability(
      application.programs,
      application.scalability
    );
    if (scalabilityErrors) addError(scalabilityErrors);
  }

  // indicators
  let indicators = application.projectIdea.indicators;

  if (application.programs[0]?.name === PG)
    indicators = application.effectiveness.indicators;

  if (application.programs[0]?.name === IG)
    indicators = application.scalability.indicators;

  const indicatorsErrors = checkIndicators(application.programs, indicators);
  if (indicatorsErrors) addError(indicatorsErrors);

  // calendarPlan
  let calendarPlan = application.projectIdea.calendarPlan;

  if (application.programs[0]?.name === PG)
    calendarPlan = application.effectiveness.calendarPlan;

  if (application.programs[0]?.name === IG)
    calendarPlan = application.scalability.calendarPlan;

  const calendarPlanErrors = checkCalendarPlan(
    application.programs,
    calendarPlan
  );
  if (calendarPlanErrors) addError(calendarPlanErrors);

  if (application.programs[0]?.name === SG) {
    // businessModel
    const businessErrors = checkBusiness(
      application.programs,
      application.businessModel
    );
    if (businessErrors) addError(businessErrors);

    // teamMembers
    const tmErrors = checkTM(application.teamMembers, applicant);
    if (tmErrors) addError(tmErrors);
  }

  if (application.programs[0]?.name === IG) {
    // businessModel
    const businessErrors = checkBusiness(
      application.programs,
      application.businessModel
    );
    if (businessErrors) addError(businessErrors);
  }

  if (application.programs[0]?.name === IG) {
    // teamMembers
    const tmErrors = checkTM(application.teamMembers, applicant);
    if (tmErrors) addError(tmErrors);
  }

  // Resource
  const resourceErrors = checkExtraResource(
    application.programs,
    application.resource
  );
  if (resourceErrors) addError(resourceErrors);

  // teamMembers
  const tmErrors = checkTM(application.teamMembers, applicant);
  if (tmErrors) addError(tmErrors);

  if (
    application.programs[0]?.name === SG ||
    application.programs[0]?.name === IG
  ) {
    const innovaErrors = checkInnovativeness(
      application.programs,
      application.innovativeness
    );
    if (innovaErrors) addError(innovaErrors);
  }

  if (
    application.programs[0]?.name === PG ||
    application.programs[0]?.name === SG ||
    application.programs[0]?.name === IG
  ) {
    // environmental
    const environmentalErrors = checkEnvironmental(
      application.programs,
      application.environmental
    );
    if (environmentalErrors) addError(environmentalErrors);
  }

  if (Object.keys(errors).length) {
    const [fieldName, errorValue] = Object.entries(errors)[0];

    wrongText(errorValue);

    const fieldRefs: NodeListOf<HTMLElement> =
      document.getElementsByName(fieldName);
    const yOffset = -250;

    if (fieldRefs.length) {
      const y =
        fieldRefs[0].getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    } else {
      const fieldRef = document.getElementById(fieldName);
      const y =
        fieldRef.getBoundingClientRect().top + window.pageYOffset + yOffset;
      if (fieldRef) window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
  return errors;
};

const checkProjectIdeaFields = (programs, projectIdea) => {
  const projectIdeaErors: any = {};
  const inputRequiredErrorText = i18next.t('input.required', { ns: 'errors' });

  if (!projectIdea)
    return {
      socialProblem: i18next.t('FillEmptyFieldsProjectIdea'),
    };

  if (!projectIdea.socialProblem)
    projectIdeaErors.socialProblem = setErrorText('socialProblem');

  // разные сценарии для разных программ тк поля перемешаны
  if (programs[0]?.name === SP) {
    if (!projectIdea.problemSolving)
      projectIdeaErors.problemSolving = setErrorText('problemSolving');

    if (!projectIdea.geography)
      projectIdeaErors.geography = setErrorText('geography');

    if (!projectIdea.beneficiaries)
      projectIdeaErors.beneficiaries = setErrorText('beneficiaries');

    if (!projectIdea.motivationalQuestion)
      projectIdeaErors.motivationalQuestion = setErrorText(
        'motivationalQuestion'
      );
  }

  if (programs[0]?.name === PG) {
    if (!projectIdea.motivationalQuestion)
      projectIdeaErors.motivationalQuestion = setErrorText(
        'motivationalQuestion'
      );

    if (!projectIdea.problemSolving)
      projectIdeaErors.problemSolving = setErrorText('problemSolving');

    if (!projectIdea.beneficiaries)
      projectIdeaErors.beneficiaries = setErrorText('beneficiaries');

    if (!projectIdea.customers)
      projectIdeaErors.customers = setErrorText('customers');

    if (!projectIdea.marketingChannels)
      projectIdeaErors.marketingChannels = setErrorText('marketingChannels');

    if (!projectIdea.projectPartners)
      projectIdeaErors.projectPartners = setErrorText('projectPartners');
  }

  if (programs[0]?.name === IG) {
    if (!projectIdea.beneficiaries)
      projectIdeaErors.beneficiaries = setErrorText('beneficiaries');

    if (!projectIdea.motivationalQuestion)
      projectIdeaErors.motivationalQuestion = setErrorText(
        'motivationalQuestion'
      );

    if (!projectIdea.geography)
      projectIdeaErors.geography = setErrorText('geography');
  }

  if (programs[0]?.name === SG) {
    if (!projectIdea.motivationalQuestion)
      projectIdeaErors.motivationalQuestion = setErrorText(
        'motivationalQuestion'
      );

    if (!projectIdea.problemSolving)
      projectIdeaErors.problemSolving = setErrorText('problemSolving');

    if (!projectIdea.viability)
      projectIdeaErors.viability = setErrorText('viability');

    if (!projectIdea.geography)
      projectIdeaErors.geography = setErrorText('geography');

    if (!projectIdea.beneficiaries)
      projectIdeaErors.beneficiaries = setErrorText('beneficiaries');
  }

  if (Object.keys(projectIdeaErors).length === 0) return false;
  return projectIdeaErors;
};

const checkOrganizationPotential = (programs, organizationPotential) => {
  const organizationPotentialErors: any = {};
  if (!organizationPotential.operatingBusiness) {
    organizationPotentialErors.operatingBusiness =
      setErrorText('operatingBusiness');
  }

  if (
    (organizationPotential.financialRevenue &&
      !organizationPotential.financialRevenue.length) ||
    (organizationPotential.financialExpenses &&
      !organizationPotential.financialExpenses.length)
  ) {
    organizationPotentialErors.financialRevenue =
      setErrorText('financialRevenue');
  }

  const financialRevenue = organizationPotential.financialRevenue.filter(
    (revenue) => !revenue.services
  );
  if (financialRevenue.length)
    financialRevenue.financialRevenue = setErrorText('financialRevenue');

  const financialExpenses = organizationPotential.financialExpenses.filter(
    (expense) =>
      !expense.taxExpenses || !expense.marketing || !expense.directCosts
  );
  if (financialExpenses.length) {
    financialRevenue.financialRevenue = setErrorText('financialRevenue');
  }

  if (Object.keys(organizationPotentialErors).length === 0) return false;

  return organizationPotentialErors;
};

const checkScalability = (programs, scalability) => {
  const scalabilityErrors: any = {};

  if (!scalability.idea) {
    if (programs[0].name === IG) {
      scalabilityErrors.idea = setErrorText('ideaIG');
    } else scalabilityErrors.idea = setErrorText('idea');
  }

  if (programs[0].name === IG) {
    if (!scalability.goal) {
      scalabilityErrors.goal = setErrorText('goal');
    }
  }

  if (programs[0].name === PG) {
    if (!scalability.geography) {
      scalabilityErrors.geography = setErrorText('geography');
    }
  }
  if (Object.keys(scalabilityErrors).length === 0) return false;

  return scalabilityErrors;
};

const checkInnovativeness = (programs, inn) => {
  const innovationErrors: any = {};

  if (!inn.innovation) {
    innovationErrors.innovation = setErrorText('innovation');
  }

  if (!inn.competitors) {
    innovationErrors.competitors = setErrorText('competitors');
  }

  if (Object.keys(innovationErrors).length === 0) return false;
  return innovationErrors;
};

const checkSustainability = (sustainability) => {
  if (!sustainability || !Object.keys(sustainability).length) {
    return {
      sustainability: i18next.t('EditSustainability'),
    };
  }
  if (!sustainability.customer) {
    return {
      customer: i18next.t('input.required', { ns: 'errors' }),
    };
  }
  if (!sustainability.scaling) {
    return {
      scaling: i18next.t('input.required', { ns: 'errors' }),
    };
  }
  if (
    sustainability.financialModel &&
    (!sustainability.financialModel.expenditure ||
      !sustainability.financialModel.costAndVolume ||
      !sustainability.financialModel.incomeSource ||
      !sustainability.financialModel.incomeDistribution)
  ) {
    return {
      financialModel: i18next.t('input.table', { ns: 'errors' }),
    };
  }

  return false;
};

const checkEfficiencyAndResult = (eff) => {
  if (!eff || !Object.keys(eff).length) {
    return {
      efficiencyAndResult: i18next.t('EditEfficiency'),
    };
  }
  if (!eff.projectCoverage) {
    return {
      projectCoverage: i18next.t('EditEfficiencyPC'),
    };
  }
  if (!eff.indicatorsOfSuccess) {
    return {
      indicatorsOfSuccess: i18next.t('EditEfficiencyIoS'),
    };
  }
  if (!eff.socialContribution) {
    return {
      socialContribution: i18next.t('EditEfficiencySC'),
    };
  }
  if (!eff.goalAndTask) {
    return {
      goalAndTask: i18next.t('EditEfficiencyGaT'),
    };
  }
  if (!eff.plan || !eff.plan.length) {
    return {
      plan: i18next.t('EditEfficiencyPlan'),
    };
  }

  if (eff.plan) {
    // const regex = new RegExp("([0-2]{1}[0-9]{1}|3[0-1]{1})[.](0[1-9]|1[0-2])[.][0-9]{4}");
    for (let plan of eff.plan) {
      if (!plan.event || !plan.description || !plan.deadline) {
        return {
          plan: i18next.t('input.table', { ns: 'errors' }),
        };
      }
      let deadline = plan.deadline.split('-');
      if (deadline[0] === 'null' || deadline[1] === 'null') {
        return {
          plan: i18next.t('input.table', { ns: 'errors' }),
        };
      }
    }
  }

  return false;
};

const checkRelevance = (rel) => {
  if (!rel || !Object.keys(rel).length) {
    return {
      relevance: i18next.t('EditRelevance'),
    };
  }
  if (!rel.socialProblem) {
    return {
      socialProblem: i18next.t('input.required', { ns: 'errors' }),
    };
  }
  if (!rel.couse) {
    return {
      couse: i18next.t('input.required', { ns: 'errors' }),
    };
  }

  return false;
};

const checkIndicators = (prog, indicators) => {
  if (indicators && indicators.length) {
    for (let i = 0; i < indicators.length; i++) {
      if (!indicators[i].name)
        return {
          indicators: setErrorText('indicators'),
        };

      if (!indicators[i].unit || indicators[i].unit === i18next.t('choose'))
        return {
          indicators: setErrorText('indicators'),
        };

      if (!indicators[i].ultimateGoal)
        return {
          indicators: setErrorText('indicators'),
        };
    }

    return false;
  } else {
    return {
      name: i18next.t('EditEfficiencyIoS'),
      unit: i18next.t('EditEfficiencyIoS'),
      ultimateGoal: i18next.t('EditEfficiencyIoS'),
    };
  }
};

const checkCalendarPlan = (programs, calendarPlan) => {
  if (!calendarPlan.length) {
    return {
      calendarPlan: setErrorText('calendarPlan'),
    };
  }

  const planRows = calendarPlan.filter((plan) => {
    let dateArr = plan.deadline.split('-');
    return (
      !plan.event ||
      plan.deadline === '-' ||
      dateArr[0] === 'null' ||
      dateArr[1] === 'null'
    );
  });

  if (planRows.length)
    return {
      calendarPlan: setErrorText('calendarPlan'),
    };

  return false;
};

const checkExtraResource = (programs, resource) => {
  const inputRequiredErrorText = i18next.t('input.required', { ns: 'errors' });
  const extraResourcesErrors: any = {};
  if (
    programs[0].name === SI ||
    programs[0].name === SE ||
    programs[0].name === SG ||
    programs[0].name === IG
  ) {
    if (!resource.partners) {
      extraResourcesErrors.partners = setErrorText('partners');
    }
  }

  if (programs[0].name === SI || programs[0].name === SE) {
    if (!resource.results) {
      extraResourcesErrors.results = inputRequiredErrorText;
    }
  }

  if (!resource.budget || !resource.budget.length) {
    extraResourcesErrors.budget = setErrorText('budget');
  }

  if (programs[0].name === SI) {
    if (!resource.marketTest) {
      extraResourcesErrors.marketTest = inputRequiredErrorText;
    }
    if (!resource.income) {
      extraResourcesErrors.income = inputRequiredErrorText;
    }

    if (!resource.funds) {
      extraResourcesErrors.funds = inputRequiredErrorText;
    }
    if (!resource.description) {
      extraResourcesErrors.description = inputRequiredErrorText;
    }
  }

  if (programs[0].name === SE) {
    if (!resource.experience)
      extraResourcesErrors.experience = inputRequiredErrorText;
  }

  if (resource.budget) {
    let sumAll = 0;
    let grantSum = 'Средства гранта Фонда';
    for (let budget of resource.budget) {
      if (
        !budget.expenditureItem ||
        !budget.amount ||
        !budget.quantity ||
        !budget.source
      ) {
        extraResourcesErrors.expenditureItem = setErrorText('budget');
      }
      if (budget.source === grantSum) {
        if (typeof budget.price === 'number') {
          sumAll += budget.price;
        } else if (budget.price) {
          sumAll += budget.price.replace(/^\s+|\s+|\s$/g, '') - 0;
        }
      }
    }
    if (sumAll > 10000000 && programs[0].name == SI) {
      extraResourcesErrors.budget = i18next.t('limits.limitSI');
    }
    if (sumAll > 5000000 && programs[0].name == SE) {
      extraResourcesErrors.budget = i18next.t('limits.limitSE');
    }
    if (sumAll > 1000000 && programs[0].name == SP) {
      extraResourcesErrors.budget = i18next.t('limits.limitSP');
    }
    if (sumAll > 5000000 && programs[0].name === IG) {
      extraResourcesErrors.budget = i18next.t('limits.limitIG');
    }
    if (sumAll > 3000000 && programs[0].name === SG) {
      extraResourcesErrors.budget = i18next.t('limits.limitSG');
    }
    if (sumAll > 1000000 && programs[0].name === PG) {
      extraResourcesErrors.budget = i18next.t('limits.limitPG');
    }
  }

  if (programs[0].name !== PG && programs[0].name !== SP) {
    if (!resource.materialBase || !resource.materialBase.length) {
      extraResourcesErrors.materialBase = setErrorText('materialBase');
    }
    if (resource?.materialBase?.length > 0) {
      for (let mb of resource.materialBase) {
        if (!mb.purpose || !mb.name) {
          extraResourcesErrors.purpose = setErrorText('purpose');
        }
      }
    }
  }

  if (Object.keys(extraResourcesErrors).length === 0) return false;
  return extraResourcesErrors;
};

const checkApplicationForm = (applicationForm) => {
  const fieldApllicationFormErrors: any = {};

  if (!applicationForm) {
    return { projectName: i18next.t('EditApplicationForm') };
  }

  if (!applicationForm.projectName) {
    fieldApllicationFormErrors.projectName = setErrorText('projectName');
  }

  if (applicationForm.projectDuration === '-') {
    fieldApllicationFormErrors.projectDuration =
      setErrorText('projectDuration');
  }

  if (!applicationForm.applicantKind) {
    fieldApllicationFormErrors.applicantKind = setErrorText('applicantKind');
  }

  if (applicationForm.projectDuration) {
    let projectDuration = applicationForm.projectDuration.split('-');
    if (projectDuration[0] === 'null' || projectDuration[1] === 'null') {
      fieldApllicationFormErrors.projectDuration =
        setErrorText('projectDuration');
    }
  }

  if (applicationForm.applicantKind === 'ORGANIZATION') {
    if (!applicationForm.organizationName) {
      fieldApllicationFormErrors.organizationName =
        setErrorText('organizationName');
    }

    if (!applicationForm.activityDirection) {
      fieldApllicationFormErrors.activityDirection =
        setErrorText('activityDirection');
    }
  }

  if (Object.keys(fieldApllicationFormErrors).length === 0) return false;
  return fieldApllicationFormErrors;
};

const checkTM = (tm, applicant) => {
  const teamMembersErrors: any = {};
  if (!tm.length) {
    teamMembersErrors.teamMembers = setErrorText('teamMembers');
  }
  for (let i in tm) {
    if (!tm[i].email) {
      teamMembersErrors.email = setErrorText('teamMembers');
    }
    if (tm[i].email && !emailRegex.test(tm[i].email)) {
      teamMembersErrors.email = setErrorText('invalidEmailInTM');
    }
    if (!tm[i].iin) {
      teamMembersErrors.iin = setErrorText('teamMembers');
    }
    if (tm[i].iin && tm[i].iin.length !== 12) {
      teamMembersErrors.iin = setErrorText('invalidUINInTM');
    }
    if (!tm[i].firstName) {
      teamMembersErrors.firstNameTM = setErrorText('teamMembers');
    }
    if (!tm[i].lastName) {
      teamMembersErrors.lastNameTM = setErrorText('teamMembers');
    }
    if (!tm[i].role) {
      teamMembersErrors.roleTM = setErrorText('teamMembers');
    }
    if (!tm[i].workExperience) {
      teamMembersErrors.workExperienceTM = setErrorText('teamMembers');
    }
  }

  if (Object.keys(teamMembersErrors).length === 0) return false;
  return teamMembersErrors;
};

const checkEnvironmental = (programs, environmental) => {
  const environmentalErrors: any = {};

  if (!environmental.environmental)
    environmentalErrors.environmental = setErrorText('environmental');

  if (!environmental.methods)
    environmentalErrors.methods = setErrorText('methods');

  if (!environmental.friendliness)
    environmentalErrors.friendliness = setErrorText('friendliness');

  if (Object.keys(environmentalErrors).length === 0) return false;
  return environmentalErrors;
};

const checkBusiness = (programs, businessModel) => {
  const inputRequiredErrorText = i18next.t('input.required', { ns: 'errors' });
  const tableRequiredErrorText = i18next.t('input.table', { ns: 'errors' });
  const businessErrors: any = {};

  if (programs[0].name === SG) {
    if (!businessModel.product)
      businessErrors.product = setErrorText('product');
  }

  if (!businessModel.clientPortrait)
    businessErrors.clientPortrait = setErrorText('clientPortrait');

  if (!businessModel.marketingChannels)
    businessErrors.marketingChannels = setErrorText('marketingChannels');

  if (!businessModel.financialModel.costAndVolume) {
    if (programs[0].name === IG) {
      businessErrors.costAndVolume = setErrorText('financialRevenueAfterScal');
    } else businessErrors.costAndVolume = setErrorText('costAndVolume');
  }

  if (!businessModel.financialModel.expenditure) {
    if (programs[0].name === IG) {
      businessErrors.expenditure = setErrorText('financialRevenueAfterScal');
    } else businessErrors.expenditure = setErrorText('expenditure');
  }

  if (!businessModel.financialModel.incomeDistribution) {
    if (programs[0].name === IG) {
      businessErrors.incomeDistribution = setErrorText(
        'financialRevenueAfterScal'
      );
    } else businessErrors.incomeDistribution = setErrorText('expenditure');
  }

  if (Object.keys(businessErrors).length === 0) return false;

  return businessErrors;
};
