import React from 'react';
import FieldEfficiencyAndResult from '../FieldEfficiencyAndResult';
import FieldRelevance from '../FieldRelevance';
import FieldResource from '../FieldResource';
import FieldSustainability from '../FieldSustainability';
import FieldInnovativeness from '../FieldInnovativeness';
import FieldApplicationForm from '../FieldApplicationForm';
import './style.css';
import TM from '../TM';
import { IG, PG, SE, SG, SI, SP } from '../../consts';
import FieldProjectIdea from '../FieldProjectIdea';
import BusinessModel from '../FieldBusinessModel';
import FieldEffectiveness from '../FieldEffectiveness';
import FieldScalability from '../FieldScalability';
import FieldOrganizationPotential from '../FieldOrganizationPotential';
import FieldEco from '../FieldEco';
import FieldSocialCategory from '../FieldSocialCategory';
import ShortInfo from '../ShortInfo';

export const ExtraFields = (props) => {
  const {
    program,
    businessModel,
    projectIdea,
    teamMembers,
    relevance,
    app_id,
    resource,
    sustainability,
    innovativeness,
    efficiencyAndResult,
    effectiveness,
    scalability,
    organizationPotential,
    environmental,
    socialCategory,
    handleResourceChange,
    handleRelevanceChange,
    handleEfficiencyAndResultChange,
    handleSustainabilityChange,
    handleInnovativenessChange,
    handleTMChange,
    handleProjectIdeaChange,
    handleBusinessModelChange,
    handleEffectivenessChange,
    handleScalabilityChange,
    handleOrganizationPotentialChange,
    handleEnvChange,
    handleSocialCategoryChange,
  } = props;

  if (program) {
    switch (program.name) {
      case SP:
        return (
          <>
            <ShortInfo program={program.name} />
            <FieldApplicationForm app_id={app_id} />
            <FieldSocialCategory
              socialCategory={socialCategory}
              handleSocialCategoryChange={handleSocialCategoryChange}
            />
            <FieldProjectIdea app_id={app_id} program={program} />
            {/* <FieldEffectiveness app_id={app_id} program={program} effectiveness={effectiveness} handleEffectivenessChange={handleEffectivenessChange} /> */}
            <FieldResource
              app_id={app_id}
              program={program}
              resource={resource}
              handleResourceChange={handleResourceChange}
            />
            <TM
              program={SP}
              teamMembers={teamMembers}
              handleTMChange={handleTMChange}
            />
          </>
        );
      case IG:
        return (
          <>
            <ShortInfo program={program.name} />
            <FieldApplicationForm app_id={app_id} />
            <FieldSocialCategory
              socialCategory={socialCategory}
              handleSocialCategoryChange={handleSocialCategoryChange}
            />
            <FieldOrganizationPotential
              app_id={app_id}
              program={program}
              organizationPotential={organizationPotential}
              handleOrganizationPotentialChange={
                handleOrganizationPotentialChange
              }
            />
            <FieldProjectIdea
              app_id={app_id}
              program={program}
              projectIdea={projectIdea}
              handleProjectIdeaChange={handleProjectIdeaChange}
            />
            <FieldScalability
              app_id={app_id}
              program={program}
              scalability={scalability}
              handleScalabilityChange={handleScalabilityChange}
            />
            <BusinessModel
              app_id={app_id}
              program={program}
              businessModel={businessModel}
              handleBusinessModelChange={handleBusinessModelChange}
            />
            <TM teamMembers={teamMembers} handleTMChange={handleTMChange} />
            <FieldResource
              app_id={app_id}
              program={program}
              resource={resource}
              handleResourceChange={handleResourceChange}
            />
            <FieldInnovativeness
              app_id={app_id}
              program={program}
              innovativeness={innovativeness}
              handleInnovativenessChange={handleInnovativenessChange}
            />
            <FieldEco
              app_id={app_id}
              program={program}
              environmental={environmental}
              handleEnvChange={handleEnvChange}
            />
          </>
        );
      case SG:
        // start
        return (
          <>
            <ShortInfo program={program.name} />
            <FieldApplicationForm app_id={app_id} />
            <FieldSocialCategory
              socialCategory={socialCategory}
              handleSocialCategoryChange={handleSocialCategoryChange}
            />
            <FieldProjectIdea app_id={app_id} program={program} />
            <BusinessModel app_id={app_id} program={program} />
            <TM teamMembers={teamMembers} handleTMChange={handleTMChange} />
            <FieldResource app_id={app_id} program={program} />
            <FieldInnovativeness
              app_id={app_id}
              program={program}
              innovativeness={innovativeness}
              handleInnovativenessChange={handleInnovativenessChange}
            />
            <FieldEco
              app_id={app_id}
              program={program}
              environmental={environmental}
              handleEnvChange={handleEnvChange}
            />
          </>
        );
      case PG:
        // pilot
        return (
          <>
            <ShortInfo program={program.name} />
            <FieldApplicationForm app_id={app_id} />
            <FieldSocialCategory
              socialCategory={socialCategory}
              handleSocialCategoryChange={handleSocialCategoryChange}
            />
            <FieldProjectIdea
              app_id={app_id}
              program={program}
              projectIdea={projectIdea}
              handleProjectIdeaChange={handleProjectIdeaChange}
            />
            <FieldEffectiveness
              app_id={app_id}
              program={program}
              effectiveness={effectiveness}
              handleEffectivenessChange={handleEffectivenessChange}
            />
            {/*<BusinessModel app_id={app_id} program={program} businessModel={businessModel} handleBusinessModelChange={handleBusinessModelChange} />*/}
            <FieldResource
              app_id={app_id}
              program={program}
              resource={resource}
              handleResourceChange={handleResourceChange}
            />
            <TM
              program={program.name}
              teamMembers={teamMembers}
              handleTMChange={handleTMChange}
            />
            <FieldEco app_id={app_id} program={program} />
          </>
        );
      case SI:
      case SE:
        return (
          <>
            <ShortInfo program={program.name} />
            <FieldApplicationForm app_id={app_id} />
            <FieldSocialCategory
              socialCategory={socialCategory}
              handleSocialCategoryChange={handleSocialCategoryChange}
            />
            <FieldRelevance
              app_id={app_id}
              program={program}
              relevance={relevance}
              handleRelevanceChange={handleRelevanceChange}
            />
            <FieldEfficiencyAndResult
              app_id={app_id}
              program={program}
              efficiencyAndResult={efficiencyAndResult}
              handleEfficiencyAndResultChange={handleEfficiencyAndResultChange}
            />
            <FieldSustainability
              app_id={app_id}
              program={program}
              sustainability={sustainability}
              handleSustainabilityChange={handleSustainabilityChange}
            />
            <TM teamMembers={teamMembers} handleTMChange={handleTMChange} />
            <FieldResource
              app_id={app_id}
              program={program}
              resource={resource}
              handleResourceChange={handleResourceChange}
            />
            <FieldInnovativeness
              app_id={app_id}
              program={program}
              innovativeness={innovativeness}
              handleInnovativenessChange={handleInnovativenessChange}
            />
          </>
        );
    }
  }

  return null;
};

export default ExtraFields;
