export default {
  ru: {
    applicationFormValidation: {
      invalidEmailInTM:
        'Введите корректную эл. почту в таблице "Члены проектной комманды"',
      invalidUINInTM:
        'Введите корректный ИИН в таблице "Члены проектной комманды"',
      projectName: 'Заполните поле "Название проекта"',
      projectDuration: 'Заполните поле "Продолжительность проекта"',
      applicantKind: 'Выберите одну из категорий',
      organizationName: 'Заполните поле "Название организации"',
      activityDirection: 'Заполните поле "Направление деятельности"',
      socialProblem: 'Заполните поле "Социальная проблема"',
      problemSolving: 'Заполните поле "Решение проблемы"',
      geography: 'Заполните поле "География реализации"',
      beneficiaries: 'Заполните поле "Благополучатели"',
      motivationalQuestion: 'Заполните поле "Мотивационный вопрос"',
      customers: 'Заполните поле "Клиенты"',
      marketingChannels: 'Заполните поле "Каналы маркетинга"',
      projectPartners: 'Заполните поле "Партнеры проекта"',
      viability: 'Заполните поле "Жизнеспособность проекта"',
      indicators: 'Заполните таблицу "Индикаторы успешности проекта"',
      calendarPlan: 'Заполните таблицу "Календарный план"',
      partners: 'Заполните поле "Партнеры проекта"',
      budget: 'Заполните таблицу "Бюджет проекта"',
      teamMembers: 'Заполните таблицу "Члены проектной команды"',
      idea: 'Заполните поле "Идея пилота"',
      goal: 'Заполните поле "Цель масштабирования"',
      ideaIG: 'Заполните поле "Идея масштабирования"',
      environmental:
        'Заполните поле "Экологичность вашего товара, продукта, услуги"',
      methods:
        'Заполните поле "Экологичные методы, подходы и ресурсы в процессе реализации Проекта"',
      friendliness:
        'Заполните поле "Экологически благоприятная среда для благополучателей"',
      product: 'Заполните поле "Продукт/услуга"',
      clientPortrait: 'Заполните поле "Портрет клиента"',
      costAndVolume: 'Заполните таблицу "Финансовая модель"',
      expenditure: 'Заполните таблицу "Финансовая модель"',
      incomeDistribution: 'Заполните таблицу "Финансовая модель"',
      materialBase: 'Заполните таблицу "Материально-техническая база"',
      purpose: 'Заполните таблицу "Материально-техническая база"',
      innovation: 'Заполните поле "Инновационность в проекте"',
      competitors: 'Заполните поле "Конкуренты"',
      operatingBusiness: 'Заполните поле "Действующий бизнес"',
      financialRevenue: 'Заполните таблицу "Финансовая модель"',
      financialRevenueAfterScal:
        'Заполните таблицу "Финансовая модель проекта после масштабирования"',
    },
    changeEmail: {
      success: 'На указанную почту отправлено письмо с подтверждением',
      errorEmailDuplicate:
        'Данный email уже имеется в базе, попробуйте еще раз',
    },
    verifyEmal: {
      success:
        'Ваша почта успешно изменена, пройдите по ссылке для авторизации',
      generalError:
        'Произошел сбой при попытке сменить почту, пожалуйста попробуйте снова',
      oldLinkError: 'Срок действия ссылки истек, пожалуйста попробуйте снова',
      emailAlreadyActivatedError: 'Данная электронная почта уже активирована',
      applicantNotFoundError: 'Данный пользователь не найден',
      invalidLinkError: 'Неверная ссылка, пожалуйста попробуйте снова',
    },
    input: {
      projectNameRequired: 'Поле "Название проекта" обязательно для заполнения',
      required: 'Поле обязательно для заполнения',
      radio: 'Выберите один из вариантов',
      table: 'Заполните таблицу полностью',
      bigSizePhoto:
        'Размер фото превышает допустимый предел, пожайлуста выберите другой файл',
    },
    registration: {
      firstname: 'Введите имя',
      lastname: 'Введите фамилию',
      inn: 'Введите ИИН',
      email: 'Введите email',
      success: 'Заявка на регистрацию отправлена, ожидайте письмо на почту',
      errorIinDuplicate: 'Данный ИИН уже имеется в базе, попробуйте еще раз',
      errorEmailDuplicate:
        'Данный email уже имеется в базе, попробуйте еще раз',
      errorIdDuplicate: 'Данный ID уже имеется в базе, попробуйте еще раз',
      errorGeneralDuplicate:
        'Возможно, данный email, ИИН или ID уже имеется в базе, попробуйте еще раз',
    },
    info: {
      firstname: 'Введите имя',
      lastname: 'Введите фамилию',
      birthDay: 'Введите дату рождения',
      gender: 'Выберите пол',
      inn: 'Введите ИИН',
      validinn: 'Введите ИИН полностью',
      cellNumber: 'Введите номер телефона в формате +7 (XXX) XXX-XXXX',
      gpa: 'Введите GPA до десятичных',
      entryPoints: 'Введите баллы при поступлении',
      grade: 'Введите курс',
      dateEnroll: 'Введите дату зачисления',
      department: 'Введите департамент',
      position: 'Введите должность',
      dateHired: 'Введите дату найма',
      dateFired: 'Введите дату увольнения',
      orgName: 'Введите название организации',
      orgBank: 'Введите название банка',
      orgBin: 'Введите БИН',
      orgBik: 'Введите БИК',
      orgKbe: 'Введите КБЕ',
      orgIban: 'Введите IBAN',
      orgDesc: 'Введите описание',
      specialty: 'Введите специальность',
      address: 'Введите адрес',
      university: 'Введите ВУЗ/ТиПО',
      startYear: 'Введите год начала',
      contactPerson: 'Введите контактное лицо',
      endYear: 'Введите год окончания',
      faculty: 'Введите факультет',
      age: 'Вам должно быть 16 лет',
      fileSizeLimit: 'Размер файла превышает допустимый предел',
    },
    unknown: 'Что-то пошло не так',
  },
  en: {
    applicationFormValidation: {
      invalidEmailInTM:
        'Enter a valid email in the "Project team members" table',
      invalidUINInTM: 'Enter a valid UIN in the "Project team members" table',
      projectName: '"Project name" required field',
      projectDuration: '"Project duration" required field',
      applicantKind: 'Choose one of the categories',
      organizationName: '"Organization name" required field',
      activityDirection: '"Organization activity direction" required field',
      socialProblem: '"Social issue" required field',
      problemSolving: '"Solution" required field',
      geography: '"Project territory" required field',
      beneficiaries: '"Beneficiary" required field',
      motivationalQuestion: '"Motivational question" required field',
      customers: '"Clients" required field',
      marketingChannels: '"Marketing channels" required field',
      projectPartners: '"Partners" required field',
      viability: '"Viability of the project" required field',
      indicators: '"What indicators will you achieve?" required field',
      calendarPlan: '"Action plan" required field',
      partners:
        '"Who are your current or potential key partners and/or suppliers?" required field',
      budget: '"Project budget" required field',
      teamMembers: '"Project team members" required field',
      idea: '"Pilot idea" required field',
      goal: '"Scaling objective" required field',
      ideaIG: '"Scaling idea" required field',
      environmental:
        '"Environmental friendliness of your product, work, service" required field',
      methods:
        '"Environmentally friendly methods, approaches and resources in the process of project implementation" required field',
      friendliness:
        '"Environmental friendly place for beneficiaries" required field',
      product: '"Product/service" required field',
      clientPortrait: '"Portrait of the client" required field',
      costAndVolume: '"Financial model" required field',
      expenditure: '"Financial model" required field',
      incomeDistribution: '"Financial model" required field',
      materialBase:
        '"If you have the material and technical base for the implementation of the project, please specify what it is and what it will be used for" required field',
      purpose:
        '"If you have the material and technical base for the implementation of the project, please specify what it is and what it will be used for" required field',
      innovation:
        '"What is the innovative development, approach, and trend in your project?" required field',
      competitors: '"Who are your competitors?" required field',
      operatingBusiness: '"Operating business" required field',
      financialRevenue: '"Financial model" required field',
      financialRevenueAfterScal:
        '"Financial model of the operating business" required field',
    },
    changeEmail: {
      success: 'A confirmation email was sent to the entered email',
      errorEmailDuplicate: 'This email already exists, please try again',
    },
    verifyEmal: {
      success:
        'Your email has been successfully changed, follow the link to sign in',
      generalError:
        'There was a failure while trying to change email, please try again',
      oldLinkError: 'The link has expired, please try again',
      emailAlreadyActivatedError: 'This email is already activated',
      applicantNotFoundError: 'The user not found',
      invalidLinkError: 'Invalid link, please try again',
    },
    input: {
      projectNameRequired: 'The "Project name" field is required',
      required: 'Required field',
      radio: 'Choose one of the options',
      table: 'Complete the table',
      bigSizePhoto: 'Photo size exceeds the limit, please select another file',
    },
    registration: {
      firstname: 'Enter your name',
      lastname: 'Enter your lastname',
      inn: 'Enter the UIN',
      email: 'Enter your email',
      success:
        'The application for registration has been sent, expect an email',
      errorIinDuplicate: 'This UIN already exists, please try again',
      errorEmailDuplicate: 'This email already exists, please try again',
      errorIdDuplicate: 'This ID already exists, please try again',
      errorGeneralDuplicate:
        'Perhaps, this email, UIN or ID already exists, please try again',
    },
    info: {
      firstname: 'Enter your name',
      lastname: 'Enter your lastname',
      birthDay: 'Enter your date of birth',
      gender: 'Choose a gender',
      inn: 'Enter the UIN',
      validinn: 'Enter the full UIN',
      cellNumber: 'Enter the phone number in the format +7 (XXX) XXX-XXXX',
      gpa: 'Enter the GPA to decimal',
      entryPoints: 'Enter the points upon admission',
      grade: 'Enter the grade',
      dateEnroll: 'Enter the date of enrollment',
      department: 'Enter the department',
      position: 'Enter the position',
      dateHired: 'Enter the date of hiring',
      dateFired: 'Enter the date of dismissal',
      orgName: 'Enter organization name',
      orgBank: 'Enter name of the bank',
      orgBin: 'Enter the BIN',
      orgBik: 'Enter the BIK',
      orgKbe: 'Enter the KBE',
      orgIban: 'Enter the IBAN',
      orgDesc: 'Enter a description',
      specialty: 'Enter your specialty',
      address: 'Enter the address',
      university: 'Enter university or other',
      startYear: 'Enter the start year',
      contactPerson: 'Enter a contact person',
      endYear: 'Enter the year of graduation',
      faculty: 'Enter the faculty',
      age: 'You must be 16 years old',
      fileSizeLimit: 'The size of the file exceeds the acceptable limit',
    },
    unknown: 'Something went wrong',
  },
  kz: {
    applicationFormValidation: {
      invalidEmailInTM:
        '"Жоба тобының мүшелері" өрісінде дұрыс электрондық поштаны енгізіңіз',
      invalidUINInTM: '"Жоба тобының мүшелері" өрісінде дұрыс ЖСН енгізіңіз',
      projectName: '"Жоба атауы" өрісін толтырыңыз',
      projectDuration: '"Жобаның ұзақтығы" өрісін толтырыңыз',
      applicantKind: 'Санаттардың бірін таңдаңыз',
      organizationName: '"Ұйымның атауы" өрісін толтырыңыз',
      activityDirection: '"Қызмет бағыты" өрісін толтырыңыз',
      socialProblem: '"Әлеуметтік мәселе" өрісін толтырыңыз',
      problemSolving: '"Мәселені шешу" өрісін толтырыңыз',
      geography: '"Іске асыру аумағы" өрісін толтырыңыз',
      beneficiaries: '"Игілік алушылар" өрісін толтырыңыз',
      motivationalQuestion: '"Уәждемелік сұрақ" өрісін толтырыңыз',
      customers: '"Клиенттер" өрісін толтырыңыз',
      marketingChannels: '"Маркетинг арналары" өрісін толтырыңыз',
      projectPartners: '"Жобаның серіктестері" өрісін толтырыңыз',
      viability: '"Жобаның тіршілікке қабілеттілігі" өрісін толтырыңыз',
      indicators: '"Жобаның индикаторлары" өрісін толтырыңыз',
      calendarPlan: '"Күнтізбелік жоспар" өрісін толтырыңыз',
      partners:
        '"Сіздің негізгі серіктесіңіз және (немесе) жеткізушілеріңіз кім?" өрісін толтырыңыз',
      budget: '"Жоба бюджеті" өрісін толтырыңыз',
      teamMembers: '"Жоба тобының мүшелері" өрісін толтырыңыз',
      idea: '"Пилоттың идеясы" өрісін толтырыңыз',
      goal: '"Масштабтау мақсаты" өрісін толтырыңыз',
      ideaIG: '"Масштабтау идеясы" өрісін толтырыңыз',
      environmental:
        '"Өз өніміңіздің, жұмысыңыздың, қызметіңіздің экологиялық жағынан тазалығы" өрісін толтырыңыз',
      methods:
        '"Жобаны iске асыру процесiндегi экологиялық таза әдiстер, тәсiлдер мен ресурстар" өрісін толтырыңыз',
      friendliness:
        '"Игілік алушылар үшін экологиялық таза орта" өрісін толтырыңыз',
      product: '"Өнім/қызмет көрсету" өрісін толтырыңыз',
      clientPortrait: '"Клиенттің портреті" өрісін толтырыңыз',
      costAndVolume: '"Қаржылық моделі" өрісін толтырыңыз',
      expenditure: '"Қаржылық моделі" өрісін толтырыңыз',
      incomeDistribution: '"Қаржылық моделі" өрісін толтырыңыз',
      materialBase:
        '"Егер сізде жобаны іске асыру үшін материалдық-техникалық база болса, онда ол қандай және не үшін пайдаланылатынын көрсетіңіз" өрісін толтырыңыз',
      purpose:
        '"Егер сізде жобаны іске асыру үшін материалдық-техникалық база болса, онда ол қандай және не үшін пайдаланылатынын көрсетіңіз" өрісін толтырыңыз',
      innovation:
        '"Сіздің жобаңыздағы жаңашыл әзірлеу, көзқарас, тенденция қандай?" өрісін толтырыңыз',
      competitors: '"Сіздің бәсекелестеріңіз кім?" өрісін толтырыңыз',
      operatingBusiness: '"Қолданыстағы бизнес" өрісін толтырыңыз',
      financialRevenue: '"Қаржылық моделі" өрісін толтырыңыз',
      financialRevenueAfterScal:
        '"Масштабтаудан кейінгі қаржылық моделі" өрісін толтырыңыз',
    },
    changeEmail: {
      success: 'Енгізілген поштаға растау хаты жіберілді',
      errorEmailDuplicate: 'Бұл email бұрыннан бар, әрекетті қайталаңыз',
    },
    verifyEmal: {
      success:
        'Сіздің поштаңыз сәтті өзгертілді, авторизация сілтемесіне өтіңіз',
      generalError: 'Поштаны өзгерту әрекеті сәтсіз аяқталды, қайталап көріңіз',
      oldLinkError: 'Сілтеменің мерзімі аяқталды, қайталап көріңіз',
      emailAlreadyActivatedError: 'Бұл электрондық пошта іске қосылды',
      applicantNotFoundError: 'Бұл пайдаланушы табылмады',
      invalidLinkError: 'Қате сілтеме, қайталап көріңіз',
    },
    input: {
      projectNameRequired: '"Жоба атауы" өрісі міндетті түрде толтырылады',
      required: 'Өрісті толтыру міндетті',
      radio: 'Опциялардың бірін таңдаңыз',
      table: 'Кестені толығымен толтырыңыз',
      bigSizePhoto:
        'Фотосуреттің өлшемі рұқсат етілген шектен асады, басқа файлды таңдаңыз',
    },
    registration: {
      firstname: 'Атын енгізіңіз',
      lastname: 'Тегін енгізіңіз',
      inn: 'ЖСН енгізіңіз',
      email: 'Email енгізіңіз',
      success: 'Тіркеуге өтінім жіберілді, поштаға хат күтіңіз',
      errorIinDuplicate: 'Бұл ЖСН бұрыннан бар, әрекетті қайталаңыз',
      errorEmailDuplicate: 'Бұл email бұрыннан бар, әрекетті қайталаңыз',
      errorIdDuplicate: 'Бұл ID бұрыннан бар, әрекетті қайталаңыз',
      errorGeneralDuplicate:
        'Мүмкін, бұл email, ЖСН немесе ID бұрыннан бар, қайталап көріңіз',
    },
    info: {
      firstname: 'Атын енгізіңіз',
      lastname: 'Тегін енгізіңіз',
      birthDay: 'Туған күнді енгізіңіз',
      gender: 'Еденді таңдаңыз',
      inn: 'ЖСН енгізіңіз',
      validinn: 'ЖСН толығымен енгізіңіз',
      cellNumber: 'Телефон нөмірін +7 (XXX) XXX-XXXX форматында енгізіңіз',
      gpa: 'Ондыққа дейін GPA енгізіңіз',
      entryPoints: 'Қабылдау кезінде ұпайларды енгізіңіз',
      grade: 'Курсты енгізіңіз',
      dateEnroll: 'Қабылдау күнін енгізіңіз',
      department: 'Бөлімді енгізіңіз',
      position: 'Позицияны енгізіңіз',
      dateHired: 'Жалдау күнін енгізіңіз',
      dateFired: 'Жұмыстан босату күнін енгізіңіз',
      orgName: 'Атауын енгізіңіз',
      orgBank: 'Банк атауын енгізіңіз',
      orgBin: 'БСН енгізіңіз',
      orgBik: 'БИК енгізіңіз',
      orgKbe: 'KBE енгізіңіз',
      orgIban: 'IBAN енгізіңіз',
      orgDesc: 'Сипаттаманы енгізіңіз',
      specialty: 'Мамандықты енгізіңіз',
      address: 'Мекенжайды енгізіңіз',
      university: 'Университетті немесе басқасын енгізіңіз',
      startYear: 'Басталу жылын енгізіңіз',
      contactPerson: 'Байланысатын адамды енгізіңіз',
      endYear: 'Аяқталу жылын енгізіңіз',
      faculty: 'Факультетті енгізіңіз',
      age: 'Сіз 16 жаста болуыңыз керек',
      fileSizeLimit: 'Файл өлшемі рұқсат етілген шектен асады',
    },
    unknown: 'Бірдеңе дұрыс болмады',
  },
};
