import * as React from 'react';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../../stores';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import logo from '../../assets/icons/logo.png';
import i18n from 'i18next';
import { cubaREST } from '../../cubaREST';
import i18next from 'i18next';

export interface ForgotPasswordProps {}

export interface ForgotPasswordState {}

@injectAppState
@observer
class ForgotPassword extends React.Component<
  AppStateObserver & RouteComponentProps,
  ForgotPasswordProps,
  ForgotPasswordState
> {
  state = {
    username: '',
    usernameError: '',
  };
  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value });
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    if (!this.state.username) {
      this.setState({ usernameError: i18next.t('EnterRecoveryEmail') });
      return;
    }
    this.props.appState.userStore
      .addNewRestoreHash(this.state.username)
      .then((res) => {
        this.setState({ usernameError: '' });
        let status = JSON.parse(res).status;

        if (status === 'SUCCESS') {
          this.props.history.push('/forgot-password/confirmation');
        }
        if (status === 'ERROR') {
          toast.error(i18next.t('notUserNameEmail'), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { language } = this.props.appState.userStore;

    if (cubaREST.restApiToken) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        <form
          className="login__card__home form-sign_up scrollbar"
          onSubmit={this.handleOnSubmit}
        >
          <img src={logo} className="sign-in__logo" alt="logo" />
          <h1 className="fs-3 fw-bold form-header">
            <Trans>resetYourPassword</Trans>:
          </h1>
          <div className="sign-in__login mb-3">
            <input
              type="text"
              className="form-input"
              placeholder={i18n.t('email')}
              value={this.state.username}
              onChange={(event) => this.handleUsernameChange(event)}
            />
          </div>
          {this.state.usernameError ? (
            <p className="red-error">
              {(this.state.usernameError = i18next.t('EnterRecoveryEmail'))}
            </p>
          ) : (
            ''
          )}
          <button className="form-button btn btn-success btn-lg mb-4">
            <Trans>Send</Trans>
          </button>
          <div className="h-divider" />
          <Link to="/" className="back__home__reg d-flex mb-2">
            <Trans>goBack</Trans>
          </Link>
        </form>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
