import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import renderHeader from './title';
import i18next from 'i18next';
import { ApplicationFormContext } from 'components/ApplicationFormProvider';
import { sectionNumber } from '../../ApplicationFormProvider/sectionNumber';

const Indicators = (props) => {
  const {
    applicationForm,
    handleApplicationFormChange,
    readOnly,
    getError,
    clearError,
  } = useContext(ApplicationFormContext);
  const [indicators, setIndicators] = useState([]);

  const unitOfMeasurement = [
    { unit: 'unit' },
    { unit: 'percent' },
    { unit: 'people' },
  ];

  useEffect(() => {
    const maxIndicators = applicationForm.effectiveness.indicators ?? [];

    // if (maxIndicators.length === 0) {
    //   maxIndicators.push({
    //     unit: i18next.t('indicatorsUnit.people'),
    //     name: i18next.t('nameIndicators'),
    //     ultimateGoal: 3,
    //   });
    // }

    while (maxIndicators.length < 3)
      maxIndicators.push({
        unit: '',
        name: '',
        ultimateGoal: 0,
      });

    setIndicators(maxIndicators);
  }, [applicationForm.effectiveness.indicators]);

  const handleInputChangeIndicators = (event, index) => {
    clearError('indicators');
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const indicators = applicationForm.effectiveness.indicators;
    let form = indicators[index];
    if (name === 'ultimateGoal') {
      value = value.replace(/\s/g, '');
      if (value.length > 10 || isNaN(Number(value))) return;
      form[name] = Number(value);
    } else {
      form[name] = value;
    }
    indicators[index][name] = form[name];

    setIndicators(indicators);
    handleApplicationFormChange({ effectiveness: { indicators } });
  };

  const handleSelectValue = ({ target }, index) => {
    clearError('indicators');
    const name = target.name;
    const value = target.value;
    let row = indicators[index];
    row[name] = value;
    indicators[index][name] = row[name];
    handleApplicationFormChange({ effectiveness: { indicators } });
  };

  const renderIndicators = () => {
    return (
      <table className="second__table">
        <tbody>
          <tr className="first__table-title">
            <th id="width-40">
              <Trans>indicatorsObj.nameInd</Trans>
            </th>
            <th id="width-20">
              <Trans>indicatorsObj.unit</Trans>
            </th>
            <th id="width-40">
              <Trans>indicatorsObj.ultimateGoal</Trans>
            </th>
          </tr>
          <tr>
            <td
              className="expense-plan__td"
              style={{ backgroundColor: '#eeeeee', borderRadius: '0' }}
            >
              <p className="expense-plan__p">{i18next.t('nameIndicators')}</p>
            </td>
            <td
              className="expense-plan__td"
              style={{ backgroundColor: '#eeeeee', borderRadius: '0' }}
            >
              <p className="expense-plan__p">
                {i18next.t('indicatorsUnit.people')}
              </p>
            </td>
            <td
              className="expense-plan__td"
              style={{ backgroundColor: '#eeeeee', borderRadius: '0' }}
            >
              <p className="expense-plan__p">3</p>
            </td>
          </tr>
          {indicators &&
            indicators.map((ind, idx) => (
              <tr key={idx}>
                <td className="expense-plan__td m-t-10">
                  <input
                    value={ind.name}
                    className="expense-plan__input"
                    id={`name_${idx}`}
                    type="text"
                    maxLength={200}
                    name="name"
                    onChange={(event) =>
                      handleInputChangeIndicators(event, idx)
                    }
                    readOnly={readOnly}
                  />
                </td>
                <td className="expense-plan__td">
                  <select
                    value={indicators[idx].unit}
                    name="unit"
                    onChange={(event) => handleSelectValue(event, idx)}
                    className="default-input default-input--select"
                    disabled={readOnly}
                  >
                    <option value="" disabled hidden>
                      {i18next.t('choose')}
                    </option>
                    {unitOfMeasurement.map((source, i) => {
                      return (
                        <option key={i} value={source.unit}>
                          {i18next.t(`indicatorsUnit.${source.unit}`)}
                        </option>
                      );
                    })}
                  </select>
                </td>
                <td className="expense-plan__td">
                  <input
                    value={
                      ind?.ultimateGoal === 0
                        ? ''
                        : ind?.ultimateGoal &&
                          ind?.ultimateGoal.toLocaleString('ru')
                    }
                    className="expense-plan__input"
                    id={`ultimateGoal_${idx}`}
                    type="text"
                    name="ultimateGoal"
                    onChange={(event) =>
                      handleInputChangeIndicators(event, idx)
                    }
                    readOnly={readOnly}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const programName = props.program.name;

  return (
    <>
      <h3 className="form__question" id="indicators">
        {sectionNumber.renderSubSection('effectiveness.indicatorsFormPG')}.{' '}
        {renderHeader(programName)}
      </h3>
      <p className="form__question__descrption">
        <Trans>indicatorsOfSuccessExtra</Trans>
      </p>
      <div
        className={
          getError('indicators')
            ? 'application-table__body red-border-error'
            : 'application-table__body'
        }
      >
        <div className="application-table">{renderIndicators()}</div>
      </div>
    </>
  );
};

export default Indicators;
