import * as React from 'react';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../../stores';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Trans } from 'react-i18next';
import logo from '../../assets/icons/logo.png';
import './Register.css';
import i18n from '../../i18n';
import i18next from 'i18next';
import eyeIcon from '../../assets/icons/eye.svg';

export interface RegisterProps {}

export interface RegisterState {}

@injectAppState
@observer
class Register extends React.Component<
  AppStateObserver & RouteComponentProps,
  RegisterProps,
  RegisterState
> {
  state = {
    password: '',
    passwordConfirm: '',
    hash: '',
    error: '',
    passwordError: '',
    passwordConfirmError: '',
    showPassword: false,
    showPasswordConfirm: false,
  };
  handlePasswordConfirmChange = (event) => {
    this.setState({ passwordConfirm: event.target.value });
  };
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    this.login();
  };

  validate = (password1, password2) => {
    if (password1 === '') {
      this.setState({ passwordError: i18next.t('enterThePassword') });
    } else this.setState({ passwordError: '' });
    if (password2 === '') {
      this.setState({
        passwordConfirmError: i18next.t('passwordConfirmError'),
      });
    } else this.setState({ passwordConfirmError: '' });
    if (password1 && password2) {
      if (password1 === password2) {
        this.setState({ error: '' });
        return true;
      } else {
        this.setState({ error: i18next.t('PasswordsMatch') });
        return false;
      }
    } else {
      return false;
    }
  };

  componentDidMount() {
    this.setState({ hash: this.props.match.params['hash'] });
  }

  login = () => {
    const { password, passwordConfirm } = this.state;
    if (this.validate(password, passwordConfirm)) {
      this.props.appState.userStore
        .restoreUserByHash({
          hash: this.state.hash,
          password: this.state.password,
        })
        .then((res) => {
          console.log(res);
          this.props.history.push('/sign-in');
        });
    }
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      password,
      passwordConfirm,
      error,
      showPassword,
      showPasswordConfirm,
    } = this.state;
    const { language } = this.props.appState.userStore;
    return (
      <React.Fragment>
        <form
          className="login__card__home form-sign_up scrollbar"
          onSubmit={this.handleOnSubmit}
          autoComplete={'off'}
        >
          <img src={logo} className="sign-in__logo" alt="logo" />
          <h1 className="fs-3 fw-bold form-header">
            <Trans>RegistrationConfirmation</Trans>
          </h1>
          <div className="mb-3 position-relative">
            {/* <label className="sign-in__label">Пароль</label> */}
            <input
              type={showPassword ? 'text' : 'password'}
              className="form-input"
              placeholder={i18n.t('enterThePassword')}
              value={password}
              onChange={(event) => this.handlePasswordChange(event)}
            />
            <img
              className="input__password-icon"
              onClick={() => this.setState({ showPassword: !showPassword })}
              src={eyeIcon}
              alt="eye icon"
            />
          </div>
          {this.state.passwordError ? (
            <p className="red-error">
              {(this.state.passwordError = i18next.t('enterThePassword'))}
            </p>
          ) : (
            ''
          )}
          <div className="mb-3 position-relative">
            {/* <label className="sign-in__label">Повторите пароль</label> */}
            <input
              type={showPasswordConfirm ? 'text' : 'password'}
              className="form-input"
              placeholder={i18n.t('ConfirmPassword')}
              value={passwordConfirm}
              onChange={(event) => this.handlePasswordConfirmChange(event)}
            />
            <img
              className="input__password-icon"
              onClick={() =>
                this.setState({ showPasswordConfirm: !showPasswordConfirm })
              }
              src={eyeIcon}
              alt="eye icon"
            />
          </div>
          {this.state.passwordConfirmError ? (
            <p className="red-error">
              {
                (this.state.passwordConfirmError = i18next.t(
                  'passwordConfirmError'
                ))
              }
            </p>
          ) : (
            ''
          )}
          {this.state.error ? (
            <p className="red-error">
              {(this.state.error = i18next.t('PasswordsMatch'))}
            </p>
          ) : (
            ''
          )}
          <div className="h-divider" />
          <Link to="/sign-up" className="back__home__reg d-flex mb-2">
            <Trans>SignUp</Trans>
          </Link>
          <button
            onClick={this.login}
            className="form-button btn btn-success btn-lg mb-4"
          >
            <Trans>Login</Trans>
          </button>
        </form>
      </React.Fragment>
    );
  }
}

export default Register;
