import * as React from 'react';
import '../Competition/Competition.css';
import { Link } from 'react-router-dom';
import { format, differenceInCalendarDays } from 'date-fns';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../../stores';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { cubaREST } from '../../cubaREST';
import i18next from 'i18next';
import localeChanger from '../../helpers/localeChanger';
import i18n from 'i18next';
import { applicationActiveStatuses, applicationCompleteStatuses } from 'consts';

export interface SentApplicationViewProps {
  name: string;
  dateStart: string;
  dateEnd: string;
  id: string;
  status: string;
  info: string;
  isMainPage?: boolean;
  appId: string;
  anketaOk: any;
  applicationStatus?: string;
}

export interface SentApplicationViewState {}

@injectAppState
@observer
class SentApplicationView extends React.Component<
  AppStateObserver & SentApplicationViewProps,
  {}
> {
  public static defaultProps = {
    isMainPage: false,
  };

  componentDidMount() {
    if (this.props.appId)
      this.props.appState.applicationsStore.loadEntity(this.props.appId);
  }

  handleNotActive = () => {
    toast.error(
      'Вам не доступна подача заявки, заполните анкету в личном кабинете',
      {
        position: toast.POSITION.BOTTOM_CENTER,
      }
    );
  };

  // deny
  buttonDeny = (applicationId) => {
    return cubaREST.invokeService('fsr_ApplicationService', 'endApplication', {
      applicationId: applicationId,
    });
  };

  onClickDeny = () => {
    this.buttonDeny(this.props.appId)
      .then(() => {
        toast.success(i18next.t('SuccessDeny'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        this.props.appState.competitionsStore.loadCompetitions();
        this.props.appState.applicationsStore.loadEntities();
      })
      .catch(() => {
        toast.error('Возникла ошибка', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  denyButton = () => {
    return (
      <Popup
        trigger={
          <div className="btn-deny">
            <button className="button-input btn btn-light button-deny">
              <Trans>Deny</Trans>
            </button>
          </div>
        }
        modal
        closeOnDocumentClick
      >
        {(close) => (
          <div className="modal">
            <div className="modal__header modal-title">
              <h1>
                <Trans>areUSure</Trans>
              </h1>
            </div>
            <div className="btn__actions">
              <button
                className="button-input btn btn-success"
                onClick={this.onClickDeny}
              >
                <Trans>Yes</Trans>
              </button>
              <button
                className="button-input btn btn-light btn-popup"
                onClick={close}
              >
                <Trans>Cancel</Trans>
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  };

  render() {
    const { name, dateStart, dateEnd, id, status, info, appId } = this.props;
    const { language } = this.props.appState.userStore;
    const localeDate = localeChanger(i18n.language ? i18n.language : 'ru');
    const competitionLasts = dateStart
      ? differenceInCalendarDays(new Date(dateStart), new Date())
      : null;
    let competitionRightComponent;

    const currentApplication =
      this.props.appState.applicationsStore.applicationList.filter(
        (application) => application.id === appId
      )[0] ?? this.props.appState.applicationsStore.application;

    const isLeader =
      this.props.appState.userStore.teamRole.currentApplicantId ===
      currentApplication?.applicant.id;

    switch (status) {
      case 'COLLECTION_OF_APPLICATION':
        competitionRightComponent = (
          <div>
            {this.props.anketaOk ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {!applicationActiveStatuses
                  .concat(applicationCompleteStatuses)
                  .includes(currentApplication?.applicationStatus) && (
                  <Link
                    className="button-input btn btn-success"
                    to={{ pathname: `/competition/${id}` }}
                  >
                    <Trans>Apply</Trans>
                  </Link>
                )}
                {applicationActiveStatuses
                  .concat(applicationCompleteStatuses)
                  .includes(currentApplication?.applicationStatus) && (
                  <Link
                    className="button-input btn btn-success"
                    to={`/application/${appId}/${id}`}
                  >
                    <Trans>ViewAsMember</Trans>
                  </Link>
                )}
                {applicationActiveStatuses
                  .concat(applicationCompleteStatuses)
                  .includes(currentApplication?.applicationStatus) &&
                  isLeader &&
                  this.denyButton()}
              </div>
            ) : (
              <button
                className="button-input btn btn-success"
                onClick={this.handleNotActive}
              >
                <Trans>ViewAsMember</Trans>
              </button>
            )}
            <p className="competition__last">
              {competitionLasts > 0 ? `Осталось ${competitionLasts} дней` : ''}
            </p>
          </div>
        );
        break;
      case 'REVIEW':
        competitionRightComponent = (
          <div className="competition__right">
            {this.props.anketaOk ? (
              <>
                <Link
                  className="button-input btn btn-success"
                  to={`/application/${appId}/${id}`}
                >
                  <Trans>ViewAsMember</Trans>
                </Link>
                {this.denyButton()}
                {applicationActiveStatuses.includes(
                  currentApplication?.applicationStatus
                ) &&
                  this.onClickDeny && (
                    <Link
                      className="button-input btn btn-success"
                      style={{ marginTop: '20px' }}
                      to={{ pathname: `/competition/${id}` }}
                    >
                      <Trans>Apply</Trans>
                    </Link>
                  )}
              </>
            ) : (
              <button
                className="button-input btn btn-success"
                onClick={this.handleNotActive}
              >
                <Trans>ViewAsMember</Trans>
              </button>
            )}
            <p className="competition__last">
              {competitionLasts > 0 ? `Осталось ${competitionLasts} дней` : ''}
            </p>
          </div>
        );
        break;
      case 'COMPLETED':
        competitionRightComponent = (
          <div className="competition__right">
            <Link className="results-button" to={`/application/${appId}/${id}`}>
              <Trans>ViewResults</Trans>
            </Link>
            {applicationActiveStatuses.includes(
              currentApplication?.applicationStatus
            ) &&
              this.onClickDeny && (
                <Link
                  className="button-input btn btn-success"
                  style={{ marginTop: '20px' }}
                  to={{ pathname: `/competition/${id}` }}
                >
                  <Trans>Apply</Trans>
                </Link>
              )}
          </div>
        );
        break;
      default:
        competitionRightComponent = <div className="competitionRight" />;
        break;
    }

    return (
      <div className="application-view portal__card__home">
        <h3 className="competition__name">{name}</h3>
        <p className="comp__description">{info}</p>
        <div className="application-view__content">
          <div>
            <p className="portal__text">
              <Trans>StartDateApplication</Trans>
            </p>
            <p className="portal__pi__text">
              <span>
                {dateStart
                  ? format(Date.parse(dateStart), 'dd MMMM u', {
                      locale: localeDate,
                    })
                  : 'Не назначена'}
              </span>
            </p>
            <p className="portal__text">
              <Trans>EndDateApplication</Trans>
            </p>
            <p className="portal__pi__text">
              <span>
                {dateEnd
                  ? format(Date.parse(dateEnd), 'dd MMMM u', {
                      locale: localeDate,
                    })
                  : 'Не назначена'}
              </span>
            </p>
            <p className="portal__text">
              <Trans>CompetitionType</Trans>
            </p>
            <p className="portal__pi__text">{name}</p>
            <p className="portal__text">
              <Trans>ApplicationType</Trans>
            </p>
            <p className="portal__pi__text">
              <Trans>{status}</Trans>
            </p>
          </div>
          {competitionRightComponent}
        </div>
      </div>
    );
  }

  stylePanel() {
    let style;
    if (
      (this.props.isMainPage &&
        this.props.status === 'COLLECTION_OF_APPLICATION') ||
      (this.props.isMainPage && this.props.status === 'REVIEW')
    ) {
      style = {
        background: '#FDF5F1',
        borderBottom: 'none',
      };
    } else if (this.props.isMainPage && this.props.status === 'COMPLETED') {
      style = {
        background: '#b7d1ff',
        borderBottom: 'none',
      };
    }

    return style;
  }

  styleDate() {
    let style;
    if (
      (this.props.isMainPage &&
        this.props.status === 'COLLECTION_OF_APPLICATION') ||
      (this.props.isMainPage && this.props.status === 'REVIEW')
    ) {
      style = {
        background: '#FFC000',
        padding: '6px 4px',
      };
    }

    return style;
  }
}

export default SentApplicationView;
