import * as React from 'react';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../../stores';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18n from 'i18n';
import logo from '../../assets/icons/logo.png';
import eyeIcon from '../../assets/icons/eye.svg';

export interface RestorePasswordProps {}

export interface RestorePasswordState {}

@injectAppState
@observer
class RestorePassword extends React.Component<
  AppStateObserver & RouteComponentProps,
  RestorePasswordProps,
  RestorePasswordState
> {
  state = {
    password: '',
    passwordConfirm: '',
    hash: '',
    error: '',
    showPassword: false,
    showConfirmPassword: false,
  };
  handlePasswordConfirmChange = (event) => {
    this.setState({ passwordConfirm: event.target.value });
  };
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    this.login();
  };

  validate = (password1, password2) => {
    if (password1 === '') {
      this.setState({ error: 'enterThePassword' });
      return false;
    }
    if (password2 === '') {
      this.setState({
        error: 'passwordConfirmError',
      });
      return false;
    }

    if (password1 !== password2) {
      this.setState({ error: 'PasswordsMatch' });
      return false;
    }
    this.setState({ error: '' });
    return true;
  };

  componentDidMount() {
    this.setState({ hash: this.props.match.params['hash'] });
  }

  login = () => {
    const { password, passwordConfirm } = this.state;
    if (this.validate(password, passwordConfirm)) {
      this.props.appState.userStore
        .restoreUserByHash({
          hash: this.state.hash,
          password: this.state.password,
        })
        .then((res) => {
          console.log(res);
          this.props.history.push('/sign-in');
        });
    }
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      password,
      passwordConfirm,
      error,
      showConfirmPassword,
      showPassword,
    } = this.state;
    const { language } = this.props.appState.userStore;
    return (
      <React.Fragment>
        <form
          className="login__card__home form-sign_up scrollbar"
          onSubmit={this.handleOnSubmit}
          autoComplete={'off'}
        >
          <img src={logo} className="sign-in__logo" alt="logo" />
          <h1 className="fs-3 fw-bold form-header">
            <Trans>PasswordRestoration</Trans>
          </h1>
          <div className="mb-3 position-relative">
            <input
              type={showPassword ? 'text' : 'password'}
              className="form-input"
              placeholder={i18n.t('enterThePassword')}
              value={password}
              onChange={(event) => this.handlePasswordChange(event)}
            />
            <img
              className="input__password-icon"
              onClick={() => this.setState({ showPassword: !showPassword })}
              src={eyeIcon}
              alt="eye icon"
            />
          </div>
          <div className="mb-3 position-relative">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              className="form-input"
              placeholder={i18n.t('ConfirmPassword')}
              value={passwordConfirm}
              onChange={(event) => this.handlePasswordConfirmChange(event)}
            />
            <img
              className="input__password-icon"
              onClick={() =>
                this.setState({ showConfirmPassword: !showConfirmPassword })
              }
              src={eyeIcon}
              alt="eye icon"
            />
          </div>
          {this.state.error && (
            <p className="red-error">
              <Trans>{error}</Trans>
            </p>
          )}
          <div className="h-divider" />
          <Link to="/sign-up" className="back__home__reg d-flex mb-2">
            <Trans>SignUp</Trans>
          </Link>
          <button
            onClick={this.login}
            className="form-button btn btn-success btn-lg mb-4"
          >
            <Trans>Confirm</Trans>
          </button>
        </form>
      </React.Fragment>
    );
  }
}

export default RestorePassword;
