import React from 'react';
import { Trans } from 'react-i18next';
import './styles.css';
import convertToMasked from '../../../helpers/pure/convertToMasked';
import renderHeader from './title';
import { ApplicationFormContext } from '../../ApplicationFormProvider';
import { sectionNumber } from '../../ApplicationFormProvider/sectionNumber';
import classNames from 'classnames';
import wrongText from '../../../helpers/wrongText';
import i18next from 'i18next';
import Load from '../../Load';
import { injectAppState } from '../../../stores';
import FileComponent from '../../FileComponent';
import validateFileSize from '../../../helpers/validateFileSize';
import { toast } from 'react-toastify';

const financialRevenueInitial = [
  {
    year: '2021',
    services: '',
  },
  {
    year: '2022',
    services: '',
  },
];
const financialExpensesInitial = [
  {
    year: '2021',
    taxExpenses: '',
    marketing: '',
    directCosts: '',
  },
  {
    year: '2022',
    taxExpenses: '',
    marketing: '',
    directCosts: '',
  },
];

@injectAppState
class FinancialModel extends React.Component<any, any> {
  static contextType = ApplicationFormContext;
  state = {
    financialExpenses:
      this.context.applicationForm.organizationPotential.financialExpenses
        ?.length > 0
        ? this.context.applicationForm.organizationPotential.financialExpenses
        : financialExpensesInitial,
    financialRevenue:
      this.context.applicationForm.organizationPotential.financialRevenue
        ?.length > 0
        ? this.context.applicationForm.organizationPotential.financialRevenue
        : financialRevenueInitial,
    fileLoading: false,
  };

  calculateSum = (a, b) => {
    if (!a && !b) {
      return 0;
    } else if (!a && b) {
      return parseInt(b);
    } else if (!b && a) {
      return parseInt(a);
    }
    return parseInt(a) + parseInt(b);
  };

  calculateSumWithMask = (a, b) => {
    let num = null;
    if (!a && !b) {
      return null;
    } else if (!a && b) {
      num = parseInt(b);
    } else if (!b && a) {
      num = parseInt(a);
    } else {
      num = parseInt(a) + parseInt(b);
    }
    return convertToMasked(num, true);
  };

  calculateSubstr = (a, b, c) => {
    const tovary = b ? b.services : 0;
    const rashody = this.calculateSum(a?.marketing, a?.directCosts);
    const donalogov = tovary - 0 - rashody;
    const pure = donalogov - a?.taxExpenses;
    if (c === 'pure') {
      if (isNaN(pure)) return '';
      const value = convertToMasked(pure);
      if (!tovary && value === 0) return '';
      return value;
    }
    if (c === 'donalogov') {
      if (isNaN(donalogov)) return '';
      const value = convertToMasked(donalogov);
      if (!tovary && value === 0) return '';
      return value;
    }
  };

  handleFRChange = (event, year) => {
    let value = event.target.value.replace(/\s/g, '');
    const inputName = event.target.name;
    if (value.length > 10 || isNaN(Number(value))) return;
    this.setState(
      (prevState) => {
        const financialRevenue = [...prevState.financialRevenue];
        financialRevenue[year][inputName] = value && Number(value);
        return { financialRevenue };
      },
      () => {
        const { financialRevenue } = this.state;
        this.context.handleApplicationFormChange({
          organizationPotential: { financialRevenue },
        });
      }
    );
  };

  handleFEChange = (event, year) => {
    let value = event.target.value.replace(/\s/g, '');
    const inputName = event.target.name;
    if (value.length > 10 || isNaN(Number(value))) return;
    this.setState(
      (prevState) => {
        const financialExpenses = [...prevState.financialExpenses];
        financialExpenses[year][inputName] = value && Number(value);
        return { financialExpenses };
      },
      () => {
        const { financialExpenses } = this.state;
        this.context.handleApplicationFormChange({
          organizationPotential: { financialExpenses },
        });
      }
    );
  };

  handleFileChange = (files) => {
    if (!validateFileSize(50, files)) {
      toast.error(i18next.t('info.fileSizeLimit', { ns: 'errors' }));
      return;
    }
    let file = this.context.applicationForm.organizationPotential.file;

    if (files.length) {
      this.setState({ fileLoading: true });

      Array.from(files).map((item) => {
        this.props.appState.filesStore
          .uploadFile(item)
          .then((response) => {
            file = { ...file, ...response.data };

            this.context.handleApplicationFormChange({
              organizationPotential: { file },
            });
          })
          .catch(() => {
            wrongText(i18next.t('ErrorLoading'));
          })
          .finally(() => {
            this.setState({ fileLoading: false });
          });
      });
    }
  };

  renderDocFile(doc) {
    if (doc) {
      return (
        <FileComponent id={doc.id} name={doc.name} extension={doc.extension} />
      );
    }
  }

  deleteOPFile = () => {
    let file = this.context.applicationForm.organizationPotential.file;
    if (file) {
      for (let key in file) {
        delete file[key];
      }
    }
    this.context.handleApplicationFormChange({
      organizationPotential: { file },
    });
  };

  hanldeInputBlur = () => {
    this.context.clearError('financialRevenue');
  };

  renderFinancialModel = () => {
    const financialRevenue =
      this.context.applicationForm.organizationPotential.financialRevenue ??
      this.state.financialRevenue;

    const financialExpenses =
      this.context.applicationForm.organizationPotential.financialExpenses ??
      this.state.financialExpenses;
    const { readOnly } = this.context;
    const { file } = this.context.applicationForm.organizationPotential;

    return (
      <>
        <table className="second__table finance-table">
          <tbody>
            <tr className="first__table-title">
              <th id="width-40" />
              <th id="width-30">2021</th>
              <th id="width-30">2022</th>
            </tr>
            <tr style={{ background: '#ffffff' }}>
              <td>
                <p className={'fn_table-title'}>
                  <Trans>organizationPotential.financialRevenue</Trans>
                </p>
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <th>
                <Trans>organizationPotential.goods</Trans>
              </th>
              <td
                className="expense-plan__td"
                style={{ background: '#ffffff' }}
              >
                <input
                  className="expense-plan__input expense-plan__input--finance"
                  name="services"
                  type="text"
                  value={
                    financialRevenue[0]?.services?.toLocaleString('ru') || ''
                  }
                  onBlur={this.hanldeInputBlur}
                  onChange={(e) => this.handleFRChange(e, 0)}
                  readOnly={readOnly}
                />
              </td>
              <td
                className="expense-plan__td"
                style={{ background: '#ffffff' }}
              >
                <input
                  className="expense-plan__input expense-plan__input--finance"
                  name="services"
                  type="text"
                  value={
                    financialRevenue[1]?.services?.toLocaleString('ru') || ''
                  }
                  onBlur={this.hanldeInputBlur}
                  onChange={(e) => this.handleFRChange(e, 1)}
                  readOnly={readOnly}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Trans>organizationPotential.totalRevenue</Trans>
              </th>
              <td className="expense-plan__td">
                {convertToMasked(financialRevenue[0]?.services, true)}
              </td>
              <td className="expense-plan__td">
                {convertToMasked(financialRevenue[1]?.services, true)}
              </td>
            </tr>
            <tr style={{ background: '#ffffff' }}>
              <td>
                <p className={'fn_table-title'}>
                  <Trans>organizationPotential.financialExpenses</Trans>
                </p>
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <th>
                <Trans>organizationPotential.direct</Trans>
              </th>
              <td
                className="expense-plan__td"
                style={{ background: '#ffffff' }}
              >
                <input
                  className="expense-plan__input expense-plan__input--finance"
                  name="directCosts"
                  type="text"
                  value={
                    financialExpenses[0]?.directCosts?.toLocaleString('ru') ||
                    ''
                  }
                  onBlur={this.hanldeInputBlur}
                  onChange={(e) => this.handleFEChange(e, 0)}
                  readOnly={readOnly}
                />
              </td>
              <td
                className="expense-plan__td"
                style={{ background: '#ffffff' }}
              >
                <input
                  className="expense-plan__input expense-plan__input--finance"
                  name="directCosts"
                  type="text"
                  value={
                    financialExpenses[1]?.directCosts?.toLocaleString('ru') ||
                    ''
                  }
                  onBlur={this.hanldeInputBlur}
                  onChange={(e) => this.handleFEChange(e, 1)}
                  readOnly={readOnly}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Trans>organizationPotential.marketing</Trans>
              </th>
              <td
                className="expense-plan__td"
                style={{ background: '#ffffff' }}
              >
                <input
                  className="expense-plan__input expense-plan__input--finance"
                  name="marketing"
                  type="text"
                  value={
                    financialExpenses[0]?.marketing?.toLocaleString('ru') || ''
                  }
                  onBlur={this.hanldeInputBlur}
                  onChange={(e) => this.handleFEChange(e, 0)}
                  readOnly={readOnly}
                />
              </td>
              <td
                className="expense-plan__td"
                style={{ background: '#ffffff' }}
              >
                <input
                  className="expense-plan__input expense-plan__input--finance"
                  name="marketing"
                  type="text"
                  value={
                    financialExpenses[1]?.marketing?.toLocaleString('ru') || ''
                  }
                  onBlur={this.hanldeInputBlur}
                  onChange={(e) => this.handleFEChange(e, 1)}
                  readOnly={readOnly}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Trans>organizationPotential.totalExpenses</Trans>
              </th>
              <td className="expense-plan__td">
                {this.calculateSumWithMask(
                  financialExpenses[0]?.marketing,
                  financialExpenses[0]?.directCosts
                )}
              </td>
              <td className="expense-plan__td">
                {this.calculateSumWithMask(
                  financialExpenses[1]?.marketing,
                  financialExpenses[1]?.directCosts
                )}
              </td>
            </tr>
            <tr>
              <th>
                <Trans>organizationPotential.income</Trans>
              </th>
              <td className="expense-plan__td">
                {this.calculateSubstr(
                  financialExpenses[0],
                  financialRevenue[0],
                  'donalogov'
                )}
              </td>
              <td className="expense-plan__td">
                {this.calculateSubstr(
                  financialExpenses[1],
                  financialRevenue[1],
                  'donalogov'
                )}
              </td>
            </tr>
            <tr>
              <th>
                <Trans>organizationPotential.tax</Trans>
              </th>
              <td
                className="expense-plan__td"
                style={{ background: '#ffffff' }}
              >
                <input
                  className="expense-plan__input expense-plan__input--finance"
                  name="taxExpenses"
                  type="text"
                  value={
                    financialExpenses[0]?.taxExpenses?.toLocaleString('ru') ||
                    ''
                  }
                  onBlur={this.hanldeInputBlur}
                  onChange={(e) => this.handleFEChange(e, 0)}
                  readOnly={readOnly}
                />
              </td>
              <td
                className="expense-plan__td"
                style={{ background: '#ffffff' }}
              >
                <input
                  className="expense-plan__input expense-plan__input--finance"
                  name="taxExpenses"
                  type="text"
                  value={
                    financialExpenses[1]?.taxExpenses?.toLocaleString('ru') ||
                    ''
                  }
                  onBlur={this.hanldeInputBlur}
                  onChange={(e) => this.handleFEChange(e, 1)}
                  readOnly={readOnly}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Trans>organizationPotential.netIncome</Trans>
              </th>
              <td className="expense-plan__td">
                {this.calculateSubstr(
                  financialExpenses[0],
                  financialRevenue[0],
                  'pure'
                )}
              </td>
              <td className="expense-plan__td">
                {this.calculateSubstr(
                  financialExpenses[1],
                  financialRevenue[1],
                  'pure'
                )}
              </td>
            </tr>
            {/*OPMemberEmail*/}
          </tbody>
        </table>
        <div className="inputfile-wrap op-file">
          {this.state.fileLoading ? (
            <Load />
          ) : (
            <>
              <label
                className={classNames(
                  'button-input btn btn-success',
                  readOnly && 'disabled'
                )}
                style={{ marginTop: '0' }}
                htmlFor={`inputfileOP`}
              >
                <Trans>ChooseFile</Trans>
              </label>
              <input
                type="file"
                id={`inputfileOP`}
                onChange={(event) => this.handleFileChange(event.target.files)}
                disabled={readOnly}
                readOnly={readOnly}
              />

              {file && !!Object.keys(file)?.length && this.renderDocFile(file)}

              {!readOnly && file && !!Object.keys(file)?.length && (
                <div className="empty_td">
                  <span onClick={() => this.deleteOPFile()} className="icon-delete">+</span>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  render() {
    const programName = this.props.program.name;
    const { getError } = this.context;

    return (
      <>
        <h3 className="form__question" id="financialRevenue">
          {sectionNumber.renderSubSection('financialModel')}.{' '}
          {renderHeader(programName)}
        </h3>
        <p className="form__question__descrption">
          <Trans>organizationPotential.modelExtra</Trans>
        </p>
        <div
          className={
            getError('financialRevenue')
              ? 'red-border-error application-table__body'
              : 'application-table__body'
          }
        >
          <div className="application-table" style={{ marginBottom: 0 }}>
            {this.renderFinancialModel()}
          </div>
        </div>
      </>
    );
  }
}

export default FinancialModel;
