import React from 'react';
import logoWhite from '../../assets/icons/logowhite.png';
import { Container } from 'react-bootstrap';
import './footer.css';
import { Trans } from 'react-i18next';
import { AppStateObserver, injectAppState } from '../../stores';
import { observer } from 'mobx-react';

@injectAppState
@observer
class Footer extends React.Component<AppStateObserver> {
  render() {
    const { language } = this.props.appState.userStore;
    return (
      <Container fluid className="footer">
        <Container className="footer__content">
          <section className="footer__item">
            <img src={logoWhite} className="logowhite" alt="logoWhite" />
            <h2 className="footer__title">
              <Trans>ConnectWidth</Trans>
            </h2>
            <p className="footer__text__title">
              <Trans>questionCompetition</Trans>:
            </p>
            <p className="footer__text">
              <span>+7 (771) 055-79-41</span>
            </p>
            <p className="footer__text">
              <span>sdfgrants@nu.edu.kz</span>
            </p>
            <p className="footer__text__title2">
              <Trans>questionTech</Trans>:
            </p>
            <p className="footer__text">
              <span>+7 (771) 055-79-42 </span>
              (Whatsapp)
            </p>
            <p className="footer__text__portal">
              © <Trans>portalProject</Trans>
            </p>
          </section>
        </Container>
      </Container>
    );
  }
};

export default Footer;
