import * as React from 'react';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../../stores';
import { Link, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import './ChangePassword.css';
import i18next from 'i18next';
import eyeIcon from '../../assets/icons/eye.svg';

export interface ChangePasswordProps {}

export interface ChangePasswordState {}
@injectAppState
@observer
class ChangePassword extends React.Component<
  AppStateObserver & RouteComponentProps,
  ChangePasswordProps,
  ChangePasswordState
> {
  state = {
    login: '',
    oldPw: '',
    newPw: '',
    newPwConfirm: '',
    oldPasswordError: '',
    passwordError: '',
    passwordConfirmError: '',
    error: '',
    showOldPassword: false,
    showNewPassword: false,
    showPasswordConfirm: false,
  };
  handlePasswordConfirmChange = (event) => {
    this.setState({ newPwConfirm: event.target.value });
  };
  handleOldPasswordChange = (event) => {
    this.setState({ oldPw: event.target.value });
  };
  handleNewPasswordChange = (event) => {
    this.setState({ newPw: event.target.value });
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    const { login, oldPw, newPw, newPwConfirm } = this.state;
    if (this.validate(oldPw, newPw, newPwConfirm)) {
      this.props.appState.userStore
        .changePassword({
          login: login,
          oldPw: oldPw,
          newPw: newPw,
        })
        .then((res) => {
          if (JSON.parse(res).status === 'SUCCESS') {
            toast.success(i18next.t('Success'), {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error('Неверный пароль', {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        });
    }
  };

  validate = (oldPassword, password1, password2) => {
    if (oldPassword === '') {
      this.setState({ oldPasswordError: 'Введите старый пароль' });
    } else this.setState({ oldPasswordError: '' });
    if (password1 === '') {
      this.setState({ passwordError: 'Введите новый пароль' });
    } else this.setState({ passwordError: '' });
    if (password2 === '') {
      this.setState({ passwordConfirmError: 'Введите новый пароль повторно' });
    } else this.setState({ passwordConfirmError: '' });
    if (password1 && password2 && oldPassword) {
      if (password1 === password2) {
        this.setState({ error: '' });
        return true;
      } else {
        this.setState({ error: 'Пароли не совпадают' });
        return false;
      }
    } else {
      return false;
    }
  };

  componentDidMount() {
    const login = localStorage.getItem('applicant');
    this.setState({ login });
  }

  constructor(props) {
    super(props);
  }
  render() {
    const {
      login,
      oldPw,
      newPw,
      newPwConfirm,
      oldPasswordError,
      passwordError,
      passwordConfirmError,
      error,
      showNewPassword,
      showOldPassword,
      showPasswordConfirm,
    } = this.state;
    return (
      <React.Fragment>
        <div className="password-change__container">
          <div className="sign-in__login sign-in__login--password mb-3">
            <label htmlFor="" className="input__header">
              <Trans>Username</Trans>
            </label>
            <input
              type="text"
              className="form-input"
              defaultValue={login}
              readOnly
            />
          </div>
          <div className="sign-in__login sign-in__login--password mb-3">
            <label htmlFor="" className="input__header">
              <Trans>OldPassword</Trans>
            </label>
            <div className="input__wrapper">
              <input
                className="form-input"
                type={showOldPassword ? 'text' : 'password'}
                onChange={(event) => this.handleOldPasswordChange(event)}
                // placeholder="••••••••••"
              />
              <img
                className="input__password-icon"
                onClick={() =>
                  this.setState({ showOldPassword: !showOldPassword })
                }
                src={eyeIcon}
                alt="eye icon"
              />
            </div>
          </div>
          <p className="password-change__error">{oldPasswordError}</p>
          <div className="sign-in__login sign-in__login--password mb-3">
            <label htmlFor="" className="input__header">
              <Trans>NewPassword</Trans>
            </label>
            <div className="input__wrapper">
              <input
                className="form-input"
                type={showNewPassword ? 'text' : 'password'}
                onChange={(event) => this.handleNewPasswordChange(event)}
                // placeholder="••••••••••"
              />
              <img
                className="input__password-icon"
                onClick={() =>
                  this.setState({ showNewPassword: !showNewPassword })
                }
                src={eyeIcon}
                alt="eye icon"
              />
            </div>
          </div>
          <p className="password-change__error">{passwordError}</p>
          <div className="sign-in__login sign-in__login--password mb-3 position-relative">
            <label htmlFor="" className="input__header">
              <Trans>ConfirmPassword</Trans>
            </label>
            <div className="input__wrapper">
              <input
                className="form-input"
                type={showPasswordConfirm ? 'text' : 'password'}
                onChange={(event) => this.handlePasswordConfirmChange(event)}
                // placeholder="••••••••••"
              />
              <img
                className="input__password-icon"
                onClick={() =>
                  this.setState({ showPasswordConfirm: !showPasswordConfirm })
                }
                src={eyeIcon}
                alt="eye icon"
              />
            </div>
          </div>
          <p className="password-change__error">{passwordConfirmError}</p>
          <p className="password-change__error">{error}</p>
          <button
            className="button-input btn btn-success"
            onClick={(event) => {
              this.handleOnSubmit(event);
            }}
          >
            <Trans>Save</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default ChangePassword;
