import { action, computed, observable, runInAction } from 'mobx';
import { cubaREST, myCuba } from '../cubaREST';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import errorTranslate from 'helpers/translateErrors';

export class BrowseApplicationPart {
  static ENTITY_NAME = 'fsr_Application';
  static VIEW = 'application-browse-view';
}

export class EditApplicationPart {
  static ENTITY_NAME = 'fsr_Application';
  static VIEW = 'application-edit-view';
}

export class ApplicationsStore {
  rootStore;
  @observable isLoadingList = false;
  @observable offset = 0;
  @observable count: number;
  @observable applicationList = [];
  @observable myApplicationsList = [];

  @observable isLoadingApplication = false;
  @observable isCommittingEntity = false;
  @observable applicationSaved = false;
  @observable application;
  @observable expenseItems = [];
  @observable currencies = [];
  @observable experiences = [];
  @observable states = [];
  @observable socialCategories = [];
  @observable directions = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.getExpenseItems();
    this.getCurrencies();
    this.getStates();
    this.getSocialCategory();
  }

  @action
  loadAppStoreAfterLogin(): void {
    this.getExpenseItems();
    this.getCurrencies();
    this.getStates();
    this.getSocialCategory();
  }

  @computed
  private get loadOptions() {
    return {
      offset: this.offset,
      view: BrowseApplicationPart.VIEW,
      sort: '-updateTs',
    };
  }

  @action
  createEntity(): void {
    this.application = new EditApplicationPart();
    this.application.applicant = 'applicant';
    this.application.competition = 'competition';
    setTimeout(() => {
      this.application.applicant = this.rootStore.applicantsStore.applicant;
      this.application.competition =
        this.rootStore.competitionsStore.competition;
    }, 2000);
  }

  @action
  loadEntities = () => {
    this.isLoadingList = true;
    let email = this.rootStore.userStore.userInfo
      ? this.rootStore.userStore.userInfo.email
      : localStorage.getItem('applicant');
    cubaREST
      .searchEntitiesWithCount(
        'fsr_Application',
        {
          conditions: [
            {
              property: 'applicant.email',
              operator: 'startsWith',
              value: email,
            },
          ],
        },
        this.loadOptions
      )
      .then((resp) => {
        runInAction(() => {
          this.count = resp.count;
          this.applicationList = resp.result;
          this.isLoadingList = false;
        });
      })
      .catch(
        action((err) => {
          this.isLoadingList = false;
          console.log(err);
        })
      );
  };

  @action
  loadEntity = (id?: string) => {
    this.application = null;
    if (!id) return;

    this.isLoadingApplication = true;
    return cubaREST
      .loadEntity('fsr_Application', id, { view: EditApplicationPart.VIEW })
      .then((response) => {
        runInAction(() => {
          this.application = response;

          for (let k = 0; k < this.application.expense.length; k++) {
            for (let l = 0; l < this.expenseItems.length; l++) {
              if (
                this.application.expense[k].item.name ===
                this.expenseItems[l].name
              ) {
                this.application.expense[k].itemName =
                  this.expenseItems[l].name;
                break;
              }
            }
            for (let m = 0; m < this.currencies.length; m++) {
              if (
                this.application.expense[k].currency.name ===
                this.currencies[m].name
              ) {
                this.application.expense[k].currencyName =
                  this.currencies[m].name;
                break;
              }
            }
          }
          for (let i = 0; i < this.states.length; i++) {
            if (this.application.state.id === this.states[i].id) {
              this.application.stateId = this.states[i].id;
              break;
            }
          }
          this.isLoadingApplication = false;
        });

        return this.application;
      })
      .catch(
        action(() => {
          this.isLoadingApplication = false;
        })
      );
  };

  @action
  loadEntity2 = (id) => {
    return cubaREST.loadEntity('fsr_Application', id, {
      view: EditApplicationPart.VIEW,
    });
  };
  @action
  getExpenseItems = () => {
    cubaREST
      .loadEntities('fsr_ExpenseItem')
      .then(
        action((res) => {
          this.expenseItems = res;
        })
      )
      .catch(
        action(() => {
          console.log('ExpenseItems are empty');
        })
      );
  };

  @action
  getCurrencies = () => {
    cubaREST
      .loadEntities('fsr_Currency')
      .then(
        action((res) => {
          this.currencies = res;
        })
      )
      .catch(
        action(() => {
          console.log('currencies are empty');
        })
      );
  };

  @action
  getStates = () => {
    cubaREST
      .loadEntities('fsr_RefState')
      .then(
        action((res) => {
          this.states = res;
        })
      )
      .catch(
        action(() => {
          console.log('states are empty');
        })
      );
  };

  // @action
  // getDirections = () => {
  //   cubaREST
  //     .loadEntities("fsr_DirectionType")
  //     .then(
  //       action((res) => {
  //         this.directions = res;
  //         console.log(toJS(this.directions));
  //       })
  //     )
  //     .catch((err) => {
  //       console.log("directions are empty");
  //     });
  // };
  @action
  getDirections2 = () => {
    return cubaREST.loadEntities('fsr_DirectionType');
  };
  @action
  getExpenseItems2 = () => {
    return cubaREST.loadEntities('fsr_ExpenseItem');
  };
  @action
  getCurrencies2 = () => {
    return cubaREST.loadEntities('fsr_Currency');
  };
  @action
  getStates2 = () => {
    return cubaREST.loadEntities('fsr_RefState');
  };

  @action
  populatorFrontVisible = (appliction_id) => {
    return cubaREST.invokeService(
      'fsr_ApplicationService',
      'populatorFrontVisible',
      { applicationId: appliction_id }
    );
  };

  @action
  updateEntity(application, esp?, password?) {
    this.isCommittingEntity = true;
    let replaced_esp = esp.replace('data:application/x-pkcs12;base64,', '');

    return cubaREST
      .invokeService(
        'fsr_ApplicationService',
        'saveApplicationAndStartProcess',
        {
          application: application,
          password: password,
          certificateStore: replaced_esp,
        }
      )
      .then((res) => {
        this.isCommittingEntity = false;
        this.loadEntities();
        let result = JSON.parse(res as string);
        let status = result.status;
        let message = result.message ? result.message : i18next.t('Error');
        if (status === 'SUCCESS') {
          toast.success(i18next.t('Success'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (status === 'ERROR') {
          toast.error(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (status === 'WARNING') {
          toast.warn(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        return result;
      })
      .catch(
        action((e) => {
          this.isCommittingEntity = false;
          toast.error('Возникла ошибка', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          throw e;
        })
      );
  }

  @action
  updateEntityService(application, esp?, password?, comment?, outcome?, type?) {
    let replaced_esp = esp
      ? esp.replace('data:application/x-pkcs12;base64,', '')
      : '';
    password = password ?? '';
    outcome = outcome ?? '';

    this.isCommittingEntity = true;

    return cubaREST
      .invokeService('fsr_ApplicationService', 'saveApplicationExpenses', {
        application,
        password,
        outcome,
        certificateStore: replaced_esp,
      })
      .then((response) => {
        const result = JSON.parse(response as string);
        const status = result.status;
        const message =
          result.message && !result.message.includes('Exception')
            ? result.message
            : i18next.t('Error');
        if (status === 'SUCCESS') {
          if (type !== 'sign') {
            toast.success(i18next.t('Success'), {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (process.env.REACT_APP_ENV !== 'development') {
              setTimeout(() => {
                window.location.href = '/';
              }, 3000);
            }
          }
          localStorage.setItem('sign-error', 'no');
        } else if (status === 'ERROR') {
          const err_message = errorTranslate(
            result.teamMemberErrorCode,
            result
          );

          localStorage.setItem('sign-error', 'yes');
          toast.error(err_message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (status === 'WARNING') {
          toast.warn(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(i18next.t('BadRequest'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(error.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .finally(() => (this.isCommittingEntity = false));
  }

  @action
  updateExpense(expense): Promise<any> {
    return cubaREST.commitEntity('fsr_Expense', expense);
  }

  @action
  sendApplication(application, esp?, password?): any {
    // let replaced_esp = esp.replace("data:application/x-pkcs12;base64,", "");
    console.log(application);
    // return cubaREST.invokeService(
    //   "fsr_ApplicationService",
    //   "runApplicationProcInstance",
    //   {
    //     application: application,
    //     // password: password,
    //     // certificateStore: replaced_esp,
    //   }
    // );
  }

  @action
  loadEntitiesService = (em) => {
    let email = em ? em : localStorage.getItem('applicant');
    return cubaREST.searchEntities('fsr_Application', {
      conditions: [
        {
          property: 'applicant.email',
          operator: 'startsWith',
          value: email,
        },
      ],
    });
  };

  @action
  loadEntitiesService_forExperience = (id) => {
    let email = this.rootStore.userStore.userInfo
      ? this.rootStore.userStore.userInfo.email
      : localStorage.getItem('applicant');

    return cubaREST.searchEntities('fsr_Application', {
      conditions: [
        {
          property: 'applicant.email',
          operator: 'startsWith',
          value: email,
        },
        {
          property: 'competition.id',
          operator: '=',
          value: id,
        },
      ],
    });
  };

  @action
  sendExperiences = (experiences) => {
    cubaREST
      .commitEntity('fsr_TeamMember', experiences)
      .then(
        action(() => {
          console.log('exp is submitted');
        })
      )
      .catch(
        action(() => {
          console.log('fsr_TeamMember is not submitted');
        })
      );
  };
  @action
  getExperiences = (id?) => {
    return cubaREST.searchEntities('fsr_TeamMember', {
      conditions: [
        {
          property: 'application.id',
          operator: '=',
          value: id,
        },
      ],
    });
  };
  @action
  getSocialCategory = () => {
    myCuba
      .get('queries/fsr_SocialCategory/byPriority')
      .then((response) => {
        this.socialCategories = response.data;
        return response.data;
      })
      .catch(
        action(() => {
          console.log('states are empty');
        })
      );
  };
  @action
  getStarsForApplication = (application_id) => {
    return cubaREST.searchEntities('fsr_ApplicationStar', {
      conditions: [
        {
          property: 'application.id',
          operator: '=',
          value: application_id,
        },
      ],
    });
  };
  // Jas Process
  @action
  StartProcess = (competitionId?, applicantId?) => {
    this.isLoadingApplication = true;
    return cubaREST
      .invokeService(
        'fsr_ApplicationService',
        'saveApplicationAndStartProcess',
        {
          competitionId,
          applicantId,
        }
      )
      .then((response) => {
        this.isLoadingApplication = false;

        return response;
      });
  };

  @action
  approveApplication(
    application?,
    esp?,
    password?,
    comment?,
    outcome?,
    procTaskId?
  ): Promise<any> {
    let replaced_esp = esp.replace('data:application/x-pkcs12;base64,', '');
    return cubaREST.invokeService('fsr_BpmService', 'completeUserTask', {
      application: application,
      password: password,
      certificateStore: replaced_esp,
      comment: comment,
      outcome: outcome,
      procTaskId: procTaskId,
    });
  }
}
