import * as React from 'react';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../../stores';
import GeneralInfo from '../../components/GeneralInfo';

import './Personal.css';
import Load from '../Load';

export interface PersonalProps {}

export interface PersonalState {}

// Deprecated

@injectAppState
@observer
class Personal extends React.Component<
  AppStateObserver,
  PersonalProps,
  PersonalState
> {
  state = {};

  loadFile = (id) => {
    return this.props.appState.filesStore.downloadFile(id);
  };

  render() {
    const { applicant, loading } = this.props.appState.applicantsStore;

    if (loading) {
      return (
        <div className="loader-container">
          <Load />
        </div>
      );
    }

    return applicant && <GeneralInfo applicant={applicant} />;
  }
}

export default Personal;
