import React from 'react';
import { Trans } from 'react-i18next';
import Plan from '../Tables/Plan';
import { SP } from '../../consts';
import renderHeader from './title';
import IndicatorsForPG from '../Tables/IndicatorsForPG';
import { ApplicationFormContext } from '../ApplicationFormProvider';
import Textarea from '../Ui/Textarea';
import { sectionNumber } from '../ApplicationFormProvider/sectionNumber';

class FieldEffectiveness extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.context.handleApplicationFormChange({
      effectiveness: {
        ...this.context.applicationForm.effectiveness,
        [name]: value,
      },
    });
  };

  render() {
    const { idea, geography } = this.context.applicationForm.effectiveness;
    const programName = this.props.program.name;

    return (
      <>
        <div className="compform__divider" />
        <h1 className="main__question">
          {sectionNumber.renderSection('EFFECTIVENESS')}.{' '}
          {renderHeader(programName)}
        </h1>

        {programName !== SP && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('effectiveness.idea')}.{' '}
              <Trans>effectiveness.idea</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>effectiveness.ideaExtra</Trans>
            </p>

            <Textarea
              defaultValue={idea}
              name="idea"
              onChange={this.handleInputChange}
            />

            <h3 className="form__question">
              {sectionNumber.renderSubSection('effectiveness.geography')}.{' '}
              <Trans>geography</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>geographyExtra</Trans>
            </p>
            <Textarea
              defaultValue={geography}
              name="geography"
              onChange={this.handleInputChange}
            />
          </>
        )}

        <IndicatorsForPG program={this.props.program} />
        <Plan program={this.props.program} namespace="effectiveness" />
      </>
    );
  }
}

export default FieldEffectiveness;
