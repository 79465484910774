import TeamMemberApplicationInfo from 'components/TeamMemberApplicationInfo';
import i18next from 'i18next';

const errorTranslate = (teamMemberCode, data) => {
  setCurrLanguage();
  let template = i18next.t('BadRequest');
  if (teamMemberCode == 2) {
    template = i18next.t('errorTeamMemberCode2');
  } else if (teamMemberCode == 3) {
    // Email match
    template = i18next.t('errorTeamMemberCode3');
  }
  template = alterTemplate(template, data.iin, data.email, data.serial);
  return template;
};

const alterTemplate = (template, iin, email, serial) => {
  template = template
    .replace('<iin>', iin)
    .replace('<email>', email)
    .replace('<serial>', serial);
  return template;
};

const setCurrLanguage = () => {
  const lang = localStorage.getItem('i18nextLng');
  console.log(lang);
  i18next.changeLanguage(lang);
};

export default errorTranslate;
