import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Trans, withTranslation } from "react-i18next";
import { Breadcrumb, Nav, Navbar, NavDropdown } from "react-bootstrap";
import cl from "classnames";
import { AppStateObserver, injectAppState } from "../../stores";
import logo from "../../assets/icons/logo.png";

import "./Header.css";
import Popup from "reactjs-popup";
import Breadcrumps from "../Breadcrumps";
import i18n from "i18next";
import HeaderMenu from "../HeaderMenu";

export interface HeaderProps {}

export interface HeaderState {}

@injectAppState
@observer
class Header extends React.Component<
  AppStateObserver & RouteComponentProps,
  HeaderProps,
  HeaderState
> {
  state = {
    showMenu: false,
    isActive: false,
    menu: [
      {
        name: "Main",
        path: "/",
      },
      {
        name: "personalData",
        link: "/cabinet/personal",
      },
    ],
  };

  toggleMenu = () => {
    let show = this.state.showMenu;
    show = !show;
    this.setState({ showMenu: show });
  };

  toggleMenuBurger = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  logout = () => {
    this.props.appState.userStore.logout().then(() => {
      this.props.history.push("/");
    });
  };

  handleLanguageChange(languages) {
    this.props.appState.userStore.changeLanguage(languages);
    this.forceUpdate();
  }

  changeTitleLang(language) {
    switch (language) {
      case "ru":
        document.title =
          "Портал проектов и программ Фонда социального развития.";
        break;
      case "kz":
        document.title =
          "Әлеуметтік даму қорының жобалары мен бағдарламаларының порталы.";
        break;
      case "en":
        document.title =
          "Portal of projects and programs of the Social Development Fund.";
        break;
      default:
        document.title =
          "Портал проектов и программ Фонда социального развития.";
        break;
    }
  }

  isChromeBrowser() {
    if (
      navigator.userAgent.includes("Opera") ||
      navigator.userAgent.includes("OPR")
    ) {
      // Opera
      return false;
    } else if (navigator.userAgent.includes("Edg")) {
      // Edge
      return false;
    } else if (navigator.userAgent.includes("Chrome")) {
      // Chrome
      return true;
    } else if (navigator.userAgent.includes("Safari")) {
      // Safari
      return false;
    } else if (navigator.userAgent.includes("Firefox")) {
      // Firefox
      return false;
    } else {
      return false;
    }
  }

  render() {
    const { authenticated, userName } = this.props.appState.userStore;
    const { links } = this.props.appState.breadcrumbsStore;
    const { menu } = this.state;
    const { isActive } = this.state;

    const renameEntityGrade = {
      GRADUATE_NIS: i18n.t("GraduateNIS"),
      GRADUATE_NU: i18n.t("GraduateNU"),
      STUDENT: i18n.t("Student"),
      EMPLOYEE: i18n.t("Employee"),
      THIRD_PERSON: i18n.t("ThirdPerson"),
    };

    const langObject = {
      kz: "ҚАЗ",
      ru: "РУС",
      en: "ENG",
    };

    const { language } = i18n;
    const { applicant } = this.props.appState.applicantsStore;

    this.changeTitleLang(language);

    return (
      <>
        <div className="body__header body__header--fix">
          <Navbar
            className={!authenticated && "visible"}
            expand="lg"
            expanded={isActive}
            onToggle={authenticated && this.toggleMenuBurger}
          >
            <div className="container container__nav">
              <Link className="img__header" to="/">
                <img
                  src={logo}
                  alt="logo"
                  className="Logoheader d-inline-block aling-top"
                />
              </Link>

              <NavDropdown
                title={langObject[language]}
                id="basic-nav-dropdown"
                className="header__links--mob links-desc"
              >
                <NavDropdown.Item
                  className={this.renderActiveLang("kz")}
                  onClick={() => this.handleLanguageChange("kz")}
                >
                  ҚАЗ
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={this.renderActiveLang("ru")}
                  onClick={() => this.handleLanguageChange("ru")}
                >
                  РУС
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={this.renderActiveLang("en")}
                  onClick={() => this.handleLanguageChange("en")}
                >
                  ENG
                </NavDropdown.Item>
              </NavDropdown>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto nav-header">
                  {authenticated && (
                    <>
                      <Nav.Link
                        onClick={this.toggleMenuBurger}
                        className="header__links header__links--mob"
                        href="/"
                      >
                        <Trans>Comps</Trans>
                      </Nav.Link>
                      <div className="header__links header__links--mob">
                        {this.renderMenu()}
                      </div>
                    </>
                  )}
                  <NavDropdown
                    title={langObject[language]}
                    id="basic-nav-dropdown"
                    className="header__links--mob links-mob"
                  >
                    <NavDropdown.Item
                      className={this.renderActiveLang("kz")}
                      onClick={() => this.handleLanguageChange("kz")}
                    >
                      ҚАЗ
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={this.renderActiveLang("ru")}
                      onClick={() => this.handleLanguageChange("ru")}
                    >
                      РУС
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className={this.renderActiveLang("en")}
                      onClick={() => this.handleLanguageChange("en")}
                    >
                      ENG
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <section
                  className={cl(
                    "header__browser",
                    authenticated && "header__browser--authenticated",
                    !this.isChromeBrowser() && "header__browser--show"
                  )}
                >
                  <Trans>BrowserMessage</Trans>{" "}
                  <a
                    href={
                      language === "ru"
                        ? "https://www.google.kz/intl/ru/chrome"
                        : "https://www.google.kz/intl/en/chrome "
                    }
                    target="_blank"
                  >
                    <Trans>BrowserBtn</Trans>
                  </a>
                </section>
                {authenticated && (
                  <Nav
                    className="justify-content-end header__links--mob"
                    activeKey="/home"
                    id="SignOut__section"
                  >
                    <Popup
                      trigger={
                        <span className="header-menu__logout">
                          {authenticated && <Trans>Logout</Trans>}
                        </span>
                      }
                      modal
                    >
                      {(close) => (
                        <div className="modal">
                          <div className="modal__header modal-title">
                            <h1>
                              <Trans>ConfirmationLogout</Trans>
                            </h1>
                          </div>
                          <div className="btn__actions">
                            <button
                              className="button-input btn btn-success"
                              onClick={() => {
                                close();
                                this.logout();
                              }}
                            >
                              <Trans>Yes</Trans>
                            </button>
                            <button
                              className="button-input btn btn-light btn-popup"
                              onClick={() => {
                                close();
                                this.closeMenu();
                              }}
                            >
                              <Trans>Cancel</Trans>
                            </button>
                          </div>
                        </div>
                      )}
                    </Popup>
                  </Nav>
                )}
              </Navbar.Collapse>
            </div>
          </Navbar>
          {authenticated && (
            <div className="container container__nav" id="header__section2">
              <div className="header__divider" />
              <section className="header__section2">
                <section className="header__breadcrumbs">
                  <Breadcrumb className="breadcrumbs__header">
                    <Breadcrumps links={links} />
                  </Breadcrumb>
                </section>
                <section className="header__name">
                  <div className="authorized__name__content">
                    {applicant && (
                      <div className="header__username">
                        <span className="header__username header__username--name">
                          <span>
                            {renameEntityGrade[applicant.applicantType]}
                          </span>
                        </span>{" "}
                        | <Link to={menu[1].link}>{userName}</Link>
                      </div>
                    )}
                  </div>
                </section>
              </section>
            </div>
          )}
        </div>
      </>
    );
  }

  renderMenu() {
    const { authenticated } = this.props.appState.userStore;
    const { menu } = this.state;
    const { showMenu } = this.state;

    if (authenticated) {
      return (
        <>
          <Link
            to={menu[1].link}
            onClick={this.toggleMenuBurger}
            className="header__link"
          >
            <Trans>Profile</Trans>
          </Link>
          <div className="header-menu__wrapper">
            {showMenu && (
              <HeaderMenu
                logout={this.logout}
                handleClickOutside={this.closeMenu}
              />
            )}
          </div>
        </>
      );
    }
  }

  renderActiveLang(lang) {
    const { language } = i18n;
    return cl("header__language", lang === language && "active");
  }
}

export default withTranslation()(withRouter(Header));
