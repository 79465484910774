import * as React from 'react';
import './SignUp.css';
import { AppStateObserver, injectAppState } from '../../stores';
import { observer } from 'mobx-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18n from '../../i18n';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import InputMask from 'react-input-mask';
import { Accordion } from 'react-bootstrap';
import logo from '../../assets/icons/logo.png';
import Popup from 'reactjs-popup';
import { SignUpStatuses } from 'const';
import PolicyText from '../../components/PolicyText';

export interface SignUpProps {}

export interface SignUpState {}
const POLICY_TEXT = '';

@injectAppState
@observer
class SignUp extends React.Component<
  AppStateObserver & RouteComponentProps,
  SignUpProps,
  SignUpState
> {
  state = {
    form: {
      idNumber: '',
      firstname: '',
      lastname: '',
      middlename: '',
      email: '',
      applicantType: 'STUDENT',
      agree: false,
      iin: '',
    },
    buttons: [
      {
        name: 'Student',
        active: true,
        value: 'STUDENT',
      },
      {
        name: 'Employee',
        active: false,
        value: 'EMPLOYEE',
      },
      // {
      //   name: "Organization",
      //   active: false,
      //   value: "ORGANIZATION",
      // },
      {
        name: 'GraduateNIS',
        active: false,
        value: 'GRADUATE_NIS',
      },
      {
        name: 'GraduateNU',
        active: false,
        value: 'GRADUATE_NU',
      },
      {
        name: 'ThirdPerson',
        active: false,
        value: 'THIRD_PERSON',
      },
    ],
    emailError: '',
    firstnameError: '',
    lastnameError: '',
    idNumberError: '',
    iinError: '',
    isOpen: false,
  };

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const onlyLettersRegex = /[^a-zA-ZА-Яа-яӘІҢҒҮҰҚӨҺәіңғүұқө]/g;
    const textOnlyLabels = ['firstname', 'lastname', 'middlename'];

    let form = { ...this.state.form };

    if (name == 'iin') {
      const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

      if (!isNumeric(value)) {
        return;
      }

      if (value.length <= 12) {
        form['iin'] = value;
        this.setState({
          form,
        });
      }
      return;
    }

    if (textOnlyLabels.includes(name)) {
      if (value.match(onlyLettersRegex)) {
        return;
      }
    }
    form[name] = value;
    this.setState({
      form,
    });
  };

  calculateAge(dateString) {
    const today = new Date();
    let year = Number(dateString.substring(0, 2));
    if (year < 30) {
      year += 2000;
    } else {
      year += 1900;
    }
    const birthDate = new Date(
      year,
      dateString.substring(2, 4) - 1,
      dateString.substring(4)
    );
    let age = today.getFullYear() - birthDate.getFullYear();
    if (
      today <
      new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }

  isUserOldEnough = (iin) => {
    const age = this.calculateAge(iin.substring(0, 6));
    return age >= 16;
  };

  validateForm = (form) => {
    const { language } = i18n;
    this.checkIdNameSurname(form);
    if (form.iin.length !== 12) {
      toast.error(i18next.t('info.validinn', { ns: 'errors' }));
      return false;
    } else if (!this.isUserOldEnough(form.iin)) {
      toast.error(i18next.t('info.age', { ns: 'errors' }));
      return false;
    }
    return (
      form.firstname !== '' &&
      form.lastname !== '' &&
      // form.middlename !== '' &&
      form.email !== '' &&
      form.applicantType !== '' &&
      (language !== 'ru' || form.agree !== false) &&
      form.iin !== '' &&
      form.iin.length === 12
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = { ...this.state.form };
    form.email = form.email.trim();
    if (this.validateForm(form)) {
      delete form.agree;
      this.props.appState.applicantsStore
        .registerApplicant(form)
        .then((res) => {
          let result = res as string;
          let status = JSON.parse(result).status;
          let message = JSON.parse(result).message
            ? JSON.parse(result).message
            : i18next.t('Error');

          switch (status) {
            case SignUpStatuses.Success: {
              toast.success(
                i18next.t('registration.success', { ns: 'errors' })
              );
              this.props.history.push('/sign-up/confirmation');
              break;
            }
            case SignUpStatuses.ErrorEmailDomain: {
              toast.error(i18next.t('emailError'));
              break;
            }
            case SignUpStatuses.ErrorIinDuplicate: {
              toast.error(
                i18next.t('registration.errorIinDuplicate', { ns: 'errors' })
              );
              break;
            }
            case SignUpStatuses.ErrorEmailDuplicate: {
              toast.error(
                i18next.t('registration.errorEmailDuplicate', { ns: 'errors' })
              );
              break;
            }
            case SignUpStatuses.ErrorIdDuplicate: {
              toast.error(
                i18next.t('registration.errorIdDuplicate', { ns: 'errors' })
              );
              break;
            }
            case SignUpStatuses.ErrorGeneralDuplicate: {
              toast.error(
                i18next.t('registration.errorGeneralDuplicate', {
                  ns: 'errors',
                })
              );
              break;
            }
            case 'WARNING': {
              toast.warn(message);
              break;
            }
            default: {
              toast.error(
                i18next.t('registration.errorGeneralDuplicate', {
                  ns: 'errors',
                })
              );
              break;
            }
          }
        })
        .catch((err) => {
          toast.error('Возникла ошибка', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      console.log('oops');
    }
  };

  checkIdNameSurname = (form) => {
    if (form.firstname === '')
      this.setState({
        firstnameError: i18next.t('registration.firstname', { ns: 'errors' }),
      });
    else this.setState({ firstnameError: '' });

    if (form.lastname === '')
      this.setState({
        lastnameError: i18next.t('registration.lastname', { ns: 'errors' }),
      });
    else this.setState({ lastnameError: '' });

    if (form.iin === '')
      this.setState({
        iinError: i18next.t('registration.inn', { ns: 'errors' }),
      });
    else this.setState({ iinError: '' });

    if (form.email === '')
      this.setState({
        emailError: i18next.t('registration.email', { ns: 'errors' }),
      });
    else this.setState({ emailError: '' });

    return !!(form.firstname && form.lastname);
  };

  setApplicantType = (type) => {
    let buttons = [...this.state.buttons];
    buttons.forEach((button) => {
      button.active = button.value === type;
    });
    let form = { ...this.state.form, idNumber: '', iin: '' };
    form.applicantType = type;
    this.setState({ buttons, form });
  };

  PopupPolicy = () => {
    return (
      <Popup
        trigger={
          <div className="policy-header">
            <Trans>policyConf</Trans>
          </div>
        }
        modal
        closeOnDocumentClick
      >
        {(close) => <PolicyText close={close} />}
      </Popup>
    );
  };

  render() {
    const { form, buttons } = this.state;
    const { language } = i18n;
    const { language: lang } = this.props.appState.userStore;
    return (
      <>
        <form
          className="login__card__home form-sign_up scrollbar"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <img src={logo} className="sign-in__logo" alt="logo" />
          <h1 className="fs-3 fw-bold form-header">
            <Trans>registration</Trans>:{' '}
          </h1>
          <Accordion className="accordion">
            {buttons.map((btn, idx) => {
              return (
                <Accordion.Item
                  key={idx}
                  eventKey={idx.toString()}
                  onClick={() => this.setApplicantType(btn.value)}
                >
                  <Accordion.Header>
                    <p className="title">
                      <Trans>{btn.name}</Trans>
                    </p>
                  </Accordion.Header>
                  <Accordion.Body onClick={(e) => e.stopPropagation()}>
                    {(this.state.form.applicantType === 'STUDENT' ||
                      this.state.form.applicantType === 'GRADUATE_NU') && (
                      <div className="mb-3">
                        <input
                          className="form-input"
                          type="text"
                          placeholder="ID"
                          value={form.idNumber}
                          name="idNumber"
                          onChange={this.handleInputChange}
                          autoComplete="off"
                        />
                      </div>
                    )}
                    {this.state.idNumberError && (
                      <p className="red-error">{this.state.idNumberError}</p>
                    )}
                    <div className="mb-3">
                      <input
                        className="form-input"
                        type="text"
                        placeholder={i18n.t('Name')}
                        value={form.firstname}
                        name="firstname"
                        onChange={this.handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                    {this.state.firstnameError && (
                      <p className="red-error">
                        {
                          (this.state.firstnameError = i18next.t(
                            'registration.firstname',
                            {
                              ns: 'errors',
                            }
                          ))
                        }
                      </p>
                    )}
                    <div className="mb-3">
                      <input
                        className="form-input"
                        type="text"
                        placeholder={i18n.t('Surname')}
                        value={form.lastname}
                        name="lastname"
                        onChange={this.handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                    {this.state.lastnameError && (
                      <p className="red-error">
                        {
                          (this.state.lastnameError = i18next.t(
                            'registration.lastname',
                            {
                              ns: 'errors',
                            }
                          ))
                        }
                      </p>
                    )}
                    <div className="mb-3">
                      <input
                        className="form-input"
                        type="text"
                        placeholder={i18n.t('Middlename')}
                        value={form.middlename}
                        name="middlename"
                        onChange={this.handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                    <div className="mb-3">
                      <InputMask
                        className="form-input"
                        defaultValue={form.iin}
                        placeholder={i18n.t('IIN')}
                        name="iin"
                        onChange={(e) => this.handleInputChange(e)}
                        mask="999999999999"
                        maskChar=""
                      />
                    </div>
                    {this.state.iinError && (
                      <p className="red-error">
                        {
                          (this.state.iinError = i18next.t('registration.inn', {
                            ns: 'errors',
                          }))
                        }
                      </p>
                    )}
                    <div className="mb-3">
                      <input
                        className="form-input"
                        type="text"
                        placeholder={i18n.t('EmailAddress')}
                        value={form.email}
                        name="email"
                        onChange={this.handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                    {this.state.emailError && (
                      <p className="red-error">
                        {
                          (this.state.emailError = i18next.t(
                            'registration.email',
                            {
                              ns: 'errors',
                            }
                          ))
                        }
                      </p>
                    )}
                    {language === 'ru' && (
                      <div className="remeber__me__flex sign-up__checkbox">
                        <input
                          type="checkbox"
                          onChange={this.handleInputChange}
                          checked={form.agree}
                          name="agree"
                          id="agree-id"
                          autoComplete="off"
                        />
                        <label htmlFor="agree-id" className="remeber__me">
                          <Trans>SignUpAgreement</Trans>
                        </label>
                      </div>
                    )}
                    {language === 'ru' && this.PopupPolicy()}
                    <div className="mb-3">
                      <button
                        className="form-button btn btn-success btn-lg mb-4"
                        disabled={language === 'ru' && !form.agree}
                      >
                        <Trans>SignUp</Trans>
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
            <div className="h-divider" />
            <Link to="/" className="back__home__reg d-flex mb-2">
              <Trans>goBack</Trans>
            </Link>
          </Accordion>
        </form>
      </>
    );
  }

  renderApplicantTypeClass(active) {
    let buttonClass = 'confirm-button';
    if (!active) buttonClass += '_reverse';
    return buttonClass;
  }
}

export default SignUp;
