import * as React from 'react';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../../stores';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from 'i18n';
import './change-email.css';
import { EmailVerificationStatuses } from 'const';
import Load from 'components/Load';

export interface ChangeEmailProps {}

export interface ChangeEmailState {}

@injectAppState
@observer
class ChangeEmail extends React.Component<
  AppStateObserver & RouteComponentProps,
  ChangeEmailProps,
  ChangeEmailState
> {
  state = {
    code: '',
    status: 'verifiedEmailSuccessfully',
    text: '',
    message: '',
    loading: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(): void {
    this.setState({ code: this.props.match.params['code'], loading: true });
    this.props.appState.applicantsStore
      .verifyEmail(this.props.match.params['code'])
      .then((res) => {
        const parsedRes = JSON.parse(res);
        const { status } = parsedRes;
        this.setState({ loading: false });
        if (status === EmailVerificationStatuses.Success) {
          this.props.appState.userStore.logout();
          this.setState({
            message: i18next.t('verifyEmal.success', { ns: 'errors' }),
            status: status,
          });
        } else if (status === EmailVerificationStatuses.EmailAlreadyActivated) {
          this.setState({
            message: i18next.t('verifyEmal.emailAlreadyActivatedError', {
              ns: 'errors',
            }),
            status: status,
          });
        } else if (status === EmailVerificationStatuses.InvalidLink) {
          this.setState({
            message: i18next.t('verifyEmal.invalidLinkError', {
              ns: 'errors',
            }),
            status: status,
          });
        } else if (status === EmailVerificationStatuses.OldLink) {
          this.setState({
            message: i18next.t('verifyEmal.oldLinkError', { ns: 'errors' }),
            status: status,
          });
        } else if (status === EmailVerificationStatuses.ApplicantNotFound) {
          this.setState({
            message: i18next.t('verifyEmal.applicantNotFoundError', {
              ns: 'errors',
            }),
            status: status,
          });
        } else {
          this.setState({
            message: i18next.t('verifyEmal.generalError', { ns: 'errors' }),
            status: status,
          });
        }
      })
      .catch((err) => {
        this.setState({
          message: i18next.t('verifyEmal.generalError', { ns: 'errors' }),
          status: 'ERROR',
          loading: false,
        });
      });
  }

  render() {
    const { code, status, message, loading } = this.state;
    return (
      <React.Fragment>
        <div className="change-email-container">
          {loading ? (
            <Load />
          ) : (
            <>
              {/* <div className="modal__header modal-title"></div> */}
              <h2>{message}</h2>
              {status === EmailVerificationStatuses.Success ? (
                <Link to="/sign-in" className="button-input btn btn-success">
                  <Trans>authorization</Trans>
                </Link>
              ) : (
                <Link to="/" className="button-input btn btn-success">
                  <Trans>ToMain</Trans>
                </Link>
              )}
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ChangeEmail;
