import * as React from 'react';
import { Link } from 'react-router-dom';

import './SignUpConfirm.css';
import logo from '../../assets/icons/logo.png';
import { Trans } from 'react-i18next';

export interface SignUpConfirmProps {}

const SignUpConfirm: React.SFC<SignUpConfirmProps> = () => {
  return (
    <div className="form-bg">
      <div className="login__card__home form-sign_up scrollbar">
        <img src={logo} className="sign-in__logo" alt="logo" />
        <h1 className="fs-3 fw-bold form-header">Спасибо за обращение</h1>
        <p className="body__p">
          На указанный Вами адрес электронной почты было отправлено письмо. Для
          продолжения регистрации перейдите по ссылке, указанной в письме.
        </p>
        <Link to="/sign-in" className="form-button btn btn-success btn-lg mb-4">
          <Trans>Login</Trans>
        </Link>
        <div className="h-divider" />
        <Link to="/" className="back__home__reg d-flex mb-2">
          <Trans>goBack</Trans>
        </Link>
      </div>
    </div>
  );
};

export default SignUpConfirm;
