import i18next from 'i18next';
import { toast } from 'react-toastify';

const wrongText = (text) => {
  toast.error(i18next.t(text), {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export default wrongText;
