import React from 'react';
import { IG, PG, SE, SG, SI, SP } from '../consts';
import { Trans } from 'react-i18next';

const checkLimits = (sum, prog) => {
  if (sum > 1000000 && prog === SP) {
    return (
      <span className="budget-error">
        <Trans>limits.limitSP</Trans> (1 000 000)
      </span>
    );
  } else if (sum > 5000000 && prog === SE) {
    return (
      <span className="budget-error">
        <Trans>limits.limitSE</Trans> (5 000 000)
      </span>
    );
  } else if (sum > 10000000 && prog === SI) {
    return (
      <span className="budget-error">
        <Trans>limits.limitSI</Trans> (10 000 000)
      </span>
    );
  } else if (sum > 5000000 && prog === IG) {
    return (
      <span className="budget-error">
        <Trans>limits.limitIG</Trans> (5 000 000)
      </span>
    );
  } else if (sum > 3000000 && prog === SG) {
    return (
      <span className="budget-error">
        <Trans>limits.limitSG</Trans> (3 000 000)
      </span>
    );
  } else if (sum > 1000000 && prog === PG) {
    return (
      <span className="budget-error">
        <Trans>limits.limitPG</Trans> (1 000 000)
      </span>
    );
  }
  return null;
};

export default checkLimits;
