import * as React from 'react';
import { Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import MainLayout from './layouts/MainLayout';
import AuthLayout from './layouts/AuthLayout';
import SignIn from './pages/SignIn';

const ProtectedRoute = inject('userState')(observer((props) => {
    const { component: Component, layout: Layout = MainLayout, ...rest } = props;

    const { authenticated } = props.userState;

    if (!authenticated)
      return (
        <AuthLayout>
          <SignIn />
        </AuthLayout>
      );

    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  })
);

export default ProtectedRoute;
