import * as React from 'react';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../../stores';
import Avatar from '../Avatar';
import InputMask from 'react-input-mask';
import PhotoComponent from '../PhotoComponent';
import OutsideClickHandler from 'react-outside-click-handler';

import './GeneralInfo.css';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { cubaREST } from '../../cubaREST';
import i18n from '../../i18n';
import Calendar from '../../assets/images/calendar.png';
import DatePicker from 'react-datepicker';
import { ChangeEmailStatuses, emailRegex } from 'const';
import matchOnlyLetters from '../../helpers/matchOnlyLetters';
import { forwardRef } from 'react';

export interface GeneralInfoProps {
  applicant;
}

export interface GeneralInfoState {}

const ExampleCustomInput = forwardRef(
  ({ onBlur, onKeyDown, value, onClick, onChange, name }: any, ref) => {
    return (
      <InputMask
        mask="99.99.9999"
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={ref}
        name={name}
        maskChar="_"
        placeholder={i18next.t('datePlaceholder')}
        onBlur={onBlur}
      />
    );
  }
);

@injectAppState
@observer
class GeneralInfo extends React.Component<
  GeneralInfoProps & AppStateObserver,
  any
> {
  private datePicker: React.RefObject<unknown>;

  constructor(props) {
    super(props);
    this.avatarComponent = React.createRef();
    this.photoComponent = React.createRef();
    this.datePicker = React.createRef();
  }

  avatarComponent;
  photoComponent;
  // formatDate = (date) => {
  //   console.log(date);

  //   const dateArr = date.split("-");
  //   const dd = dateArr[0];
  //   const yyyy = dateArr[2];
  //   dateArr[0] = yyyy;
  //   dateArr[2] = dd;
  //   return dateArr.join("-");
  // }
  state = {
    graduateNIS: this.props.applicant?.graduateNIS || {},
    graduateNU: this.props.applicant.graduateNU || {},
    thirdPerson: this.props.applicant.thirdPerson || {},
    stillWork:
      this.props.applicant.staff && !this.props.applicant.staff.dateFired,
    birthDay: (this.props.applicant && this.props.applicant.birthDay) || null,
    dateEnroll:
      (this.props.applicant.student &&
        this.props.applicant.student.dateEnroll) ||
      null,
    dateHired:
      (this.props.applicant.staff && this.props.applicant.staff.dateHired) ||
      null,
    dateFired:
      (this.props.applicant.staff && this.props.applicant.staff.dateFired) ||
      null,
    cellNumber:
      (this.props.applicant.phone && this.props.applicant.phone.cellNumber) ||
      null,
    startDate: new Date(),
    info: [
      { name: 'Name', value: 'firstname', type: 'text' },
      { name: 'Surname', value: 'lastname', type: 'text' },
      { name: 'Middlename', value: 'middlename', type: 'text' },
      { name: 'BirthDate', value: 'birthDay', type: 'date' },

      {
        name: 'Gender',
        value: 'gender',
        type: 'radio',
        list: [
          { name: 'MALE', caption: 'Male' },
          { name: 'FEMALE', caption: 'Female' },
        ],
      },
      { name: 'IIN', value: 'iin', type: 'number' },
      { name: 'citizenship', value: 'citizenship', type: 'dropdown' },
      {
        name: 'maritalStatus',
        value: 'maritalStatus',
        type: 'dropdown',
        list: [
          { name: 'SINGLE', caption: 'notMarried' },
          { name: 'MARRIED', caption: 'married' },
          { name: 'DIVORCED', caption: 'divorced' },
          { name: 'WIDOW', caption: 'widow' },
        ],
      },
      { name: 'telefon', value: 'phone', value2: 'cellNumber', type: 'number' },
      { name: 'email', value: 'email', type: 'text' },

      // { name: "Паспорт заявителя" },
      // { name: "Адрес" },
    ],
    studentInfo: [
      {
        name: 'applicantType',
        value: 'applicantType',
        type: 'dropdown',
        list: [
          { name: 'EMPLOYEE', caption: 'Employee' },
          { name: 'STUDENT', caption: 'Student' },
          // { name: "ORGANIZATION", caption: "Организация" },
          { name: 'GRADUATE_NIS', caption: 'GraduateNIS' },
          { name: 'GRADUATE_NU', caption: 'GraduateNU' },
          { name: 'THIRD_PERSON', caption: 'ThirdPerson' },
        ],
      },
      { name: 'IDNumber', value: 'idNumber', type: 'text' },
      { name: 'GPA', value: 'gpa', type: 'number' },
      { name: 'entryPoints', value: 'entryPoints', type: 'number' },
      { name: 'school', value: 'school', type: 'dropdown' },
      { name: 'grade', value: 'grade', type: 'number' },
      { name: 'dateEnroll', value: 'dateEnroll', type: 'date' },
      { name: 'studyProgram', value: 'studyProgram', type: 'dropdown' },
      { name: 'socialStatus', value: 'socialStatus', type: 'dropdown' },
      { name: 'hasGraBolashak', value: 'hasGraBolashak', type: 'checkbox' },
    ],
    employeeInfo: [
      {
        name: 'applicantType',
        value: 'applicantType',
        type: 'dropdown',
        list: [
          { name: 'EMPLOYEE', caption: 'Employee' },
          { name: 'STUDENT', caption: 'Student' },
          // { name: "ORGANIZATION", caption: "Организация" },
          { name: 'GRADUATE_NIS', caption: 'GraduateNIS' },
          { name: 'GRADUATE_NU', caption: 'GraduateNU' },
          { name: 'THIRD_PERSON', caption: 'ThirdPerson' },
        ],
      },
      { name: 'IDNumber', value: 'idNumber', type: 'text' },
      { name: 'company', value: 'company', type: 'dropdown' },
      { name: 'department', value: 'department', type: 'text' },
      { name: 'position', value: 'position', type: 'text' },
      { name: 'dateHired', value: 'dateHired', type: 'date' },
      { name: 'dateFired', value: 'dateFired', type: 'date' },
      { name: 'hasGraBolashak', value: 'hasGraBolashak', type: 'checkbox' },
    ],
    organizationInfo: [
      { name: 'name', value: 'name', type: 'text' },
      { name: 'bank', value: 'bank', type: 'text' },
      { name: 'bin', value: 'bin', type: 'text' },
      { name: 'bik', value: 'bik', type: 'text' },
      { name: 'kbe', value: 'kbe', type: 'text' },
      { name: 'iban', value: 'iban', type: 'text' },
      // { name: "Адрес", value: "address", type: "text" },
      { name: 'description', value: 'description', type: 'text' },
      { name: 'hasGraBolashak', value: 'hasGraBolashak', type: 'checkbox' },
    ],
    graduateNISInfo: [
      {
        name: 'applicantType',
        value: 'applicantType',
        type: 'dropdown',
        list: [
          { name: 'EMPLOYEE', caption: 'Employee' },
          { name: 'STUDENT', caption: 'Student' },
          // { name: "ORGANIZATION", caption: "Организация" },
          { name: 'GRADUATE_NIS', caption: 'GraduateNIS' },
          { name: 'GRADUATE_NU', caption: 'GraduateNU' },
          { name: 'THIRD_PERSON', caption: 'ThirdPerson' },
        ],
      },
      { name: 'IDNumber', value: 'idNumber', type: 'text' },
      // {name: "address", value: "address", type: "text"},
      // {name: "contactPerson", value: "contactPerson", type: "text"},
      { name: 'university', value: 'university', type: 'text' },
      { name: 'faculty', value: 'faculty', type: 'text' },
      { name: 'specialty', value: 'specialty', type: 'text' },
      { name: 'startYear', value: 'startYear', type: 'date' },
      { name: 'endYear', value: 'endYear', type: 'date' },
      // {name: "organization", value: "organization", type: "text"},
      // {name: "department", value: "department", type: "text"},
      // {name: "position", value: "position", type: "text"},
      // {name: "isWork", value: "isWork", type: "checkbox"},
      { name: 'hasGraBolashak', value: 'hasGraBolashak', type: 'checkbox' },
    ],
    graduateNUInfo: [
      {
        name: 'applicantType',
        value: 'applicantType',
        type: 'dropdown',
        list: [
          { name: 'EMPLOYEE', caption: 'Employee' },
          { name: 'STUDENT', caption: 'Student' },
          // { name: "ORGANIZATION", caption: "Организация" },
          { name: 'GRADUATE_NIS', caption: 'GraduateNIS' },
          { name: 'GRADUATE_NU', caption: 'GraduateNU' },
          { name: 'THIRD_PERSON', caption: 'ThirdPerson' },
        ],
      },
      { name: 'IDNumber', value: 'idNumber', type: 'text' },
      // {name: "address", value: "address", type: "text"},
      // {name: "contactPerson", value: "contactPerson", type: "text"},
      // { name: "university", value: "university", type: "text" },
      { name: 'faculty', value: 'faculty', type: 'text' },
      { name: 'school', value: 'school', type: 'dropdown' },
      { name: 'specialty', value: 'specialty', type: 'text' },
      { name: 'startYear', value: 'startYear', type: 'date' },
      { name: 'endYear', value: 'endYear', type: 'date' },
      // {name: "organization", value: "organization", type: "text"},
      // {name: "department", value: "department", type: "text"},
      // {name: "position", value: "position", type: "text"},
      // {name: "isWork", value: "isWork", type: "checkbox"},
      { name: 'hasGraBolashak', value: 'hasGraBolashak', type: 'checkbox' },
    ],
    thirdPersonInfo: [
      {
        name: 'applicantType',
        value: 'applicantType',
        type: 'dropdown',
        list: [
          { name: 'EMPLOYEE', caption: 'Employee' },
          { name: 'STUDENT', caption: 'Student' },
          // { name: "ORGANIZATION", caption: "Организация" },
          { name: 'GRADUATE_NIS', caption: 'GraduateNIS' },
          { name: 'GRADUATE_NU', caption: 'GraduateNU' },
          { name: 'THIRD_PERSON', caption: 'ThirdPerson' },
        ],
      },
      // { name: "IDNumber", value: "idNumber", type: "text" },
      // {name: "address", value: "address", type: "text"},
      // {name: "contactPerson", value: "contactPerson", type: "text"},
      { name: 'university', value: 'university', type: 'text' },
      { name: 'faculty', value: 'faculty', type: 'text' },
      { name: 'specialty', value: 'specialty', type: 'text' },
      { name: 'startYear', value: 'startYear', type: 'date' },
      { name: 'endYear', value: 'endYear', type: 'date' },
      // {name: "organization", value: "organization", type: "text"},
      // {name: "department", value: "department", type: "text"},
      // {name: "position", value: "position", type: "text"},
      // {name: "isWork", value: "isWork", type: "checkbox"},
      { name: 'hasGraBolashak', value: 'hasGraBolashak', type: 'checkbox' },
    ],

    extraInfo: [
      { name: 'address', value: 'address', type: 'text' },
      { name: 'contactPerson', value: 'contactPerson', type: 'text' },
      { name: 'organization', value: 'organization', type: 'text' },
      { name: 'department', value: 'department', type: 'text' },
      { name: 'position', value: 'position', type: 'text' },
      { name: 'isWork', value: 'isWork', type: 'checkbox' },
      { name: 'stillWork', value: 'stillWork', type: 'checkbox' }, // TODO stillWork field is unused, there is already isWork field
    ],
    countryEnums: [],
    studyProgramEnums: [],
    socialStatusEnums: [],
    companyEnums: [],
    schoolEnums: [],
    applicantID: '',
    companyID: this.props.appState.userStore.companies[0]?.id,
    schoolID: '',
    showAvatarModal: false,
    showEmailChangeModal: false,
    changedEmail: '',
    isNotValidEmail: false,
  };

  componentDidMount = () => {
    this.setState({ applicantID: this.props.applicant.id });
    this.getEnums();
  };
  getEnums = () => {
    this.props.appState?.userStore?.enums?.forEach((element) => {
      if (element.name === 'com.company.fsr.entity.Country') {
        this.setState({ countryEnums: element.values });
      }
      if (element.name === 'com.company.fsr.entity.StudyProgram') {
        this.setState({ studyProgramEnums: element.values });
      }
      if (element.name === 'com.company.fsr.entity.SocialStatus') {
        this.setState({ socialStatusEnums: element.values });
      }
    });
    let companies = [];
    this.props.appState.userStore.companies.forEach((company) => {
      if (
        this.props.applicant.staff &&
        this.props.applicant.staff.company &&
        this.props.applicant.staff.company.id === company.id
      ) {
        this.setState({ companyID: company.id });
      }
      companies.push({ name: company.id, caption: company.name });
    });
    let schools = [];
    this.props.appState.userStore.schools.forEach((school) => {
      if (
        this.props.applicant.student &&
        this.props.applicant.student.school &&
        this.props.applicant.student.school.id === school.id
      ) {
        this.setState({ schoolID: school.id });
      }
      schools.push({ name: school.id, caption: school.name });
    });

    this.setState({
      companyEnums: companies,
      schoolEnums: schools,
    });
  };

  loadFile = (id) => {
    return this.props.appState.filesStore.downloadFile(id);
  };

  handleEmailChangeInputChange = (event) => {
    const value = event.target.value;
    this.setState({ changedEmail: value });
  };

  handleInputChange = (event) => {
    event.persist();
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const isOnlyLetters = matchOnlyLetters(value);

    if (name === 'iin') {
      if (value.length > 12) {
        return;
      }
    }

    if (
      target.type === 'text' &&
      name !== 'idNumber' &&
      isOnlyLetters &&
      name !== 'iin'
    ) {
      return;
    }

    if (name === 'birthDate') {
      this.state.birthDay = value;
    }
    this.props.applicant[name] = value;
  };
  handleExtraInputChange = (event) => {
    event.persist();
    const { applicant } = this.props;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'dateEnroll' || name === 'dateHired' || name === 'dateFired') {
      this.state[name] = value;
    }
    let form;
    let applicantTypeKey;
    switch (applicant.applicantType) {
      case 'STUDENT':
        if (!applicant.student) {
          applicant.student = {};
        }
        applicantTypeKey = 'student';
        form = applicant.student;
        break;
      case 'EMPLOYEE':
        if (!applicant.staff) {
          applicant.staff = {};
        }
        applicantTypeKey = 'staff';
        form = applicant.staff;
        break;
      case 'ORGANIZATION':
        if (!applicant.organization) {
          applicant.organization = '';
        }
        applicantTypeKey = 'organization';
        form = applicant.organization;
        break;
      case 'GRADUATE_NIS':
        if (!applicant.graduateNIS) {
          applicant.graduateNIS = {};
        }
        applicantTypeKey = 'graduateNIS';
        form = this.state?.graduateNIS;
        break;
      case 'GRADUATE_NU':
        if (!applicant.graduateNU) {
          applicant.graduateNU = {};
        }
        applicantTypeKey = 'graduateNU';
        form = this.state.graduateNU;
        break;
      case 'THIRD_PERSON':
        if (!applicant.thirdPerson) {
          applicant.thirdPerson = {};
        }
        applicantTypeKey = 'thirdPerson';
        form = this.state.thirdPerson;
        break;
    }
    if ((target.type === 'number' && target.value > 0) || value === '') {
      form[name] = value; // TODO this form object wont affect on render
      this.setState((prev) => ({
        ...prev,
        [applicantTypeKey]: form,
      }));
    }
    if (target.type !== 'number') {
      form[name] = value;
      this.setState((prev) => ({
        ...prev,
        [applicantTypeKey]: form,
      }));
    }
  };

  handleIsWorkCheckbox = (event) => {
    event.persist();
    const { applicant } = this.props;
    const target = event.target;
    const value = target.checked;

    if (applicant.applicantType === 'EMPLOYEE') {
      if (value) {
        this.setState({
          stillWork: true,
        });
      }
      if (!value) {
        this.setState({
          stillWork: !this.state.stillWork,
        });
      }
    }
    if (applicant.applicantType === 'GRADUATE_NIS') {
      if (value) {
        this.setState((prev) => ({
          ...prev,
          graduateNIS: {
            ...prev.graduateNIS,
            position: '',
            organization: '',
            department: '',
            isWork: value,
          },
        }));
      }
      this.setState(
        (prev) => ({
          ...prev,
          graduateNIS: {
            ...prev.graduateNIS,
            isWork: value,
          },
        }),
        () => console.log(this.state.graduateNIS.isWork)
      );
    } else if (applicant.applicantType === 'THIRD_PERSON') {
      if (value) {
        this.setState((prev) => ({
          ...prev,
          thirdPerson: {
            ...prev.thirdPerson,
            position: '',
            organization: '',
            department: '',
            isWork: value,
          },
        }));
      } else {
        this.setState(
          (prev) => ({
            ...prev,
            thirdPerson: {
              ...prev.thirdPerson,
              isWork: value,
            },
          }),
          () => console.log(this.state.thirdPerson.isWork)
        );
      }
    } else if (applicant.applicantType === 'GRADUATE_NU') {
      if (value) {
        this.setState((prev) => ({
          ...prev,
          graduateNU: {
            ...prev.graduateNU,
            position: '',
            organization: '',
            department: '',
            isWork: value,
          },
        }));
      } else {
        this.setState((prev) => ({
          ...prev,
          graduateNU: {
            ...prev.graduateNU,
            isWork: value,
          },
        }));
      }
    }
  };
  handleSelectChange = (event, name) => {
    event.persist();
    this.props.applicant[name] = event.target.value;
  };
  handleExtraSelectChange = (event, name) => {
    const { applicant } = this.props;
    let form;
    switch (applicant.applicantType) {
      case 'STUDENT':
        if (!applicant.student) {
          applicant.student = {};
        }
        form = applicant.student;
        break;
      case 'EMPLOYEE':
        if (!applicant.staff) {
          applicant.staff = {};
        }
        form = applicant.staff;
        break;
      case 'ORGANIZATION':
        if (!applicant.organization) {
          applicant.organization = {};
        }
        form = applicant.organization;
        break;
      case 'GRADUATE_NU':
        if (!applicant.graduateNU) {
          applicant.graduateNU = {};
        }
        form = applicant.graduateNU;
        const schoolName = this.state.schoolEnums.find(
          (school) => school.name === event.target.value
        );
        form.faculty = schoolName.caption;
        break;
    }

    if (name === 'company') {
      this.setState({ companyID: event.target.value });
      form[name] = { id: event.target.value };
    } else if (name === 'school') {
      this.setState({ schoolID: event.target.value });
      form[name] = { id: event.target.value };
    } else {
      form[name] = event.target.value;
    }
  };
  handleRadioChange = (event, name) => {
    this.props.applicant[name] = event.target.value;
  };
  verifyApplicant = () => {
    this.props.applicant.birthDay = this.state.birthDay;
    if (this.props.applicant.student) {
      this.props.applicant.student.dateEnroll = this.state.dateEnroll;
    }
    if (this.props.applicant.staff) {
      this.props.applicant.staff.dateHired = this.state.dateHired;
      this.props.applicant.staff.dateFired = this.props.applicant.staff
        .dateFired
        ? this.state.dateFired
        : null;
    }

    // const regex = new RegExp("([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-]([0-9]{4})");
    const { applicant } = this.props;

    applicant.graduateNIS = this.state.graduateNIS;
    applicant.graduateNU = this.state.graduateNU;
    applicant.thirdPerson = this.state.thirdPerson;
    if (applicant.applicantType === 'EMPLOYEE' && !applicant.staff.company) {
      applicant.staff.company = { id: this.state.companyEnums[0].name };
    }
    if (applicant.applicantType === 'GRADUATE_NU') {
      applicant.graduateNU.university = 'Nazarbayev University';
    }

    console.log(applicant, 'applicant.inn');
    let verified = true;

    if (!applicant.citizenship) {
      applicant.citizenship = 'KAZAKHSTAN';
    }
    if (!applicant.maritalStatus) {
      applicant.maritalStatus = 'SINGLE';
    }
    // if (!applicant.idNumber) {
    //   toast.error("Введите ID Номер", {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //   });
    //   verified = false;
    // }

    if (!applicant.firstname) {
      toast.error(i18next.t('info.firstname', { ns: 'errors' }));
      verified = false;
    } else if (!applicant.lastname) {
      toast.error(i18next.t('info.lastname', { ns: 'errors' }));
      verified = false;
    } else if (!applicant.iin) {
      toast.error(i18next.t('info.inn', { ns: 'errors' }));
      verified = false;
    } else if (this.state.cellNumber?.length <= 0) {
      toast.error(i18next.t('info.cellNumber', { ns: 'errors' }));
      verified = false;
    } else if (!applicant.email) {
      toast.error(i18next.t('info.email', { ns: 'errors' }));
      verified = false;
      // } else if (!applicant.gender) {
      //     toast.error(i18next.t('info.gender', {ns: 'errors'}));
      //     verified = false;
    } else if (applicant.iin.replace(/_/g, '').length < 12) {
      toast.error(i18next.t('info.validinn', { ns: 'errors' }));
      verified = false;
    } else if (
      !this.state.cellNumber ||
      this.state.cellNumber
        .replace(/\s/g, '')
        .replace(/\-/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '').length < 12
    ) {
      toast.error(i18next.t('info.cellNumber', { ns: 'errors' }));
      verified = false;
    } else if (applicant.applicantType === 'STUDENT') {
      if (!applicant.student.studyProgram) {
        applicant.student.studyProgram = this.state.studyProgramEnums[0].name;
      }
      if (!applicant.student.school) {
        applicant.student.school = { id: this.state.schoolEnums[0].name };
      }
      if (!applicant.student.socialStatus) {
        delete applicant.student.socialStatus;
      }
      if (applicant.student.gpa) {
        if (typeof applicant.student.gpa !== 'number') {
          applicant.student.gpa = Number(
            applicant.student.gpa.replace(',', '.')
          );
        }
      }
      // if (!applicant.student.gpa) {
      //     toast.error(i18next.t('info.gpa', {ns: 'errors'}));
      //     verified = false;
      // } else
      //     if (!applicant.student.entryPoints) {
      //     toast.error(i18next.t('info.entryPoints', {ns: 'errors'}));
      //     verified = false;
      // } else
      if (!applicant.student.grade) {
        toast.error(i18next.t('info.grade', { ns: 'errors' }));
        verified = false;
      }
      //     else if (!applicant.student.dateEnroll) {
      //     toast.error(i18next.t('info.dateEnroll', {ns: 'errors'}));
      //     verified = false;
      // }
    } else if (applicant.applicantType === 'EMPLOYEE') {
      if (!applicant.staff.company) {
        applicant.staff.company = this.state.companyEnums[0].name;
      }

      if (!applicant.staff.department) {
        toast.error(i18next.t('info.department', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.staff.position) {
        toast.error(i18next.t('info.position', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.staff.dateHired) {
        toast.error(i18next.t('info.dateHired', { ns: 'errors' }));
        verified = false;
      }
      // else if (!regex.test(applicant.staff.dateHired)) {
      //   toast.error("Формат Даты Найма не верный, попробуйте в формате DD-MM-YYYY", {
      //     position: toast.POSITION.BOTTOM_CENTER,
      //   });
      //   verified = false;
      // }
      if (this.state.stillWork) {
        delete applicant.staff.dateFired;
      }
      if (!this.state.stillWork) {
        if (!applicant.staff.dateFired) {
          toast.error(i18next.t('info.dateFired', { ns: 'errors' }));
          verified = false;
        }
        // else if (!regex.test(applicant.staff.dateFired)) {
        //   toast.error("Формат Даты Увольнения не верный, попробуйте в формате DD-MM-YYYY", {
        //     position: toast.POSITION.BOTTOM_CENTER,
        //   });
        //   verified = false;
        // }
      }
    } else if (applicant.applicantType === 'ORGANIZATION') {
      if (!applicant.organization.name) {
        toast.error(i18next.t('info.orgName', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.organization.bank) {
        toast.error(i18next.t('info.orgBank', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.organization.bin) {
        toast.error(i18next.t('info.orgBin', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.organization.bik) {
        toast.error(i18next.t('info.orgBik', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.organization.kbe) {
        toast.error(i18next.t('info.orgKbe', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.organization.iban) {
        toast.error(i18next.t('info.orgIban', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.organization.description) {
        toast.error(i18next.t('info.orgDesc', { ns: 'errors' }));
        verified = false;
      }
    } else if (applicant.applicantType === 'GRADUATE_NIS') {
      if (!applicant.graduateNIS.specialty) {
        toast.error(i18next.t('info.specialty', { ns: 'errors' }));
        verified = false;
        // } else if (!applicant.graduateNIS.address) {
        //     toast.error(i18next.t('info.address', {ns: 'errors'}));
        //     verified = false;
      } else if (!applicant.graduateNIS.university) {
        toast.error(i18next.t('info.university', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.graduateNIS.startYear) {
        toast.error(i18next.t('info.startYear', { ns: 'errors' }));
        verified = false;
        // } else if (!applicant.graduateNIS.contactPerson) {
        //     toast.error(i18next.t('info.contactPerson', {ns: 'errors'}));
        //     verified = false;
      } else if (!applicant.graduateNIS.endYear) {
        toast.error(i18next.t('info.endYear', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.graduateNIS.faculty) {
        toast.error(i18next.t('info.faculty', { ns: 'errors' }));
        verified = false;
        // } else if (!this.state.graduateNIS.isWork) {
        //     this.setState(
        //         (prev) => ({
        //             ...prev,
        //             graduateNIS: {
        //                 ...prev.graduateNIS,
        //                 isWork: false,
        //             },
        //         }));
        //
        //     if (!applicant.graduateNIS.position) {
        //         toast.error(i18next.t('info.position', {ns: 'errors'}));
        //         verified = false;
        //     } else if (!applicant.graduateNIS.organization) {
        //         toast.error(i18next.t('info.orgName', {ns: 'errors'}));
        //         verified = false;
        //     } else if (!applicant.graduateNIS.department) {
        //         toast.error(i18next.t('info.department', {ns: 'errors'}));
        //         verified = false;
        //     }
      }
    } else if (applicant.applicantType === 'GRADUATE_NU') {
      if (!applicant.graduateNU.specialty) {
        toast.error(i18next.t('info.specialty', { ns: 'errors' }));
        verified = false;
        // } else if (!applicant.graduateNU.address) {
        //     toast.error(i18next.t('info.address', {ns: 'errors'}));
        //     verified = false;
      } else if (!applicant.graduateNU.university) {
        toast.error(i18next.t('info.university', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.graduateNU.startYear) {
        toast.error(i18next.t('info.startYear', { ns: 'errors' }));
        verified = false;
        // } else if (!applicant.graduateNU.contactPerson) {
        //     toast.error(i18next.t('info.contactPerson', {ns: 'errors'}));
        //     verified = false;
      } else if (!applicant.graduateNU.endYear) {
        toast.error(i18next.t('info.endYear', { ns: 'errors' }));
        verified = false;
      } else if (!applicant.graduateNU.faculty) {
        toast.error(i18next.t('info.faculty', { ns: 'errors' }));
        verified = false;
        // } else if (!this.state.graduateNU.isWork) {
        //     this.setState(
        //         (prev) => ({
        //             ...prev,
        //             graduateNU: {
        //                 ...prev.graduateNU,
        //                 isWork: false,
        //             },
        //         }));
        //     if (!applicant.graduateNU.position) {
        //         toast.error(i18next.t('info.position', {ns: 'errors'}));
        //         verified = false;
        //     } else if (!applicant.graduateNU.organization) {
        //         toast.error(i18next.t('info.orgName', {ns: 'errors'}));
        //         verified = false;
        //     } else if (!applicant.graduateNU.department) {
        //         toast.error(i18next.t('info.department', {ns: 'errors'}));
        //         verified = false;
        //     }
      }
      // } else if (applicant.applicantType === "THIRD_PERSON") {
      //     if (!applicant.thirdPerson.specialty) {
      //         toast.error(i18next.t('info.specialty', {ns: 'errors'}));
      //         verified = false;
      //     } else if (!applicant.thirdPerson.address) {
      //         toast.error(i18next.t('info.address', {ns: 'errors'}));
      //         verified = false;
      //     } else if (!applicant.thirdPerson.university) {
      //         toast.error(i18next.t('info.university', {ns: 'errors'}));
      //         verified = false;
      //     } else if (!applicant.thirdPerson.startYear) {
      //         toast.error(i18next.t('info.startYear', {ns: 'errors'}));
      //         verified = false;
      //     } else if (!applicant.thirdPerson.contactPerson) {
      //         toast.error(i18next.t('info.contactPerson', {ns: 'errors'}));
      //         verified = false;
      //     } else if (!applicant.thirdPerson.endYear) {
      //         toast.error(i18next.t('info.endYear', {ns: 'errors'}));
      //         verified = false;
      //     } else if (!applicant.thirdPerson.faculty) {
      //         toast.error(i18next.t('info.faculty', {ns: 'errors'}));
      //         verified = false;
      //     } else if (!this.state.thirdPerson.isWork) {
      //         this.setState(
      //             (prev) => ({
      //                 ...prev,
      //                 thirdPerson: {
      //                     ...prev.thirdPerson,
      //                     isWork: false,
      //                 },
      //             }));
      //
      //         if (!applicant.thirdPerson.position) {
      //             toast.error(i18next.t('info.position', {ns: 'errors'}));
      //             verified = false;
      //         } else if (!applicant.thirdPerson.organization) {
      //             toast.error(i18next.t('info.orgName', {ns: 'errors'}));
      //             verified = false;
      //         } else if (!applicant.thirdPerson.department) {
      //             toast.error(i18next.t('info.department', {ns: 'errors'}));
      //             verified = false;
      //         }
      //     }
    }
    if (verified) {
      this.sendApplicantForm();
    }
  };
  sendPhone = async (cp) => {
    const form: any = {
      applicant: { id: this.props.applicant.id },
      cellNumber: cp,
    };
    return await cubaREST.commitEntity('fsr_PhoneNumber', form);
  };
  formatNumber = (num) => {
    return num.replace(' ', '');
  };
  sendApplicantForm = async () => {
    const { applicant } = this.props;
    const stateSP =
      this.state.cellNumber &&
      this.state.cellNumber
        .replace(/\s/g, '')
        .replace(/\-/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '');
    const cP =
      (this.props.applicant.phone && this.props.applicant.phone.cellNumber) ||
      null;

    if (stateSP !== null && stateSP !== cP) {
      applicant.phone = (await this.sendPhone(stateSP)) || null;
    }

    this.props.appState.applicantsStore.updateApplicant(applicant);
  };
  savePhoto = () => {
    const { applicant } = this.props;
    const newFile = this.avatarComponent.current.state.file;
    this.props.appState.filesStore.uploadFile(newFile).then((res) => {
      applicant.photo = res.data;
      if (this.photoComponent.current.props.photo) {
        this.photoComponent.current.fetchFile();
      }
    });
  };
  openAvatarModal = () => {
    this.setState({ showAvatarModal: true });
  };
  closeAvatarModal = () => {
    this.setState({ showAvatarModal: false });
  };
  handleChangeDate = (date) => {
    this.setState({ birthDay: date });
  };
  handlePhoneChange = (e) => {
    this.setState({ cellNumber: e.target.value });
  };
  handleChangeDateExtra = (date) => {
    if (this.props.applicant.applicantType === 'EMPLOYEE') {
      this.setState({ dateHired: date });
      this.setState({ dateFired: date });
    } else if (this.props.applicant.applicantType === 'STUDENT') {
      this.setState({ dateEnroll: date });
    }
  };

  getMaxDate() {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 16);
    return maxDate;
  }
  render() {
    const { language: lang } = this.props.appState.userStore;
    const language = i18next.language;
    const { info, showAvatarModal, showEmailChangeModal } = this.state;
    const { applicant } = this.props;

    return (
      <div className="general-info__container">
        {/* {JSON.stringify(applicant)} */}

        <div className="general-info__table cabinet-data">
          <div className="home__cabinet">
            <h2 className="title__cabinet__form">
              <Trans>personalData</Trans>
            </h2>
            <div className="cabinet-label mb-3">
              <span className="input__header input__header--general-info">
                <Trans>Photo</Trans>
              </span>
              <PhotoComponent
                getFile={this.loadFile}
                photo={applicant.photo}
                openAvatarModal={this.openAvatarModal}
                ref={this.photoComponent}
              />
            </div>

            {info.map((item, idx) => {
              const labelArray = [
                'Name',
                'Surname',
                'IIN',
                'telefon',
                'email',
                'citizenship',
              ];
              if (labelArray.includes(item.name)) {
                return (
                  <label
                    key={idx}
                    className={
                      item.name === 'Gender'
                        ? 'cabinet-radio mb-3'
                        : 'cabinet-label mb-3'
                    }
                  >
                    <span className="input__header input__header--general-info">
                      <span className="label-required">* </span>
                      {i18next.t(item.name)}
                    </span>
                    {this.renderInput(item)}
                  </label>
                );
              } else {
                return (
                  <label
                    key={idx}
                    className={
                      item.name === 'Gender'
                        ? 'cabinet-radio mb-3'
                        : 'cabinet-label mb-3'
                    }
                  >
                    <span className="input__header input__header--general-info">
                      {i18next.t(item.name)}
                    </span>
                    {this.renderInput(item)}
                  </label>
                );
              }
            })}
          </div>
          <div className="home__cabinet">
            <h2 className="title__cabinet__form">
              <Trans>education</Trans>
            </h2>
            {this.renderExtraInfo(applicant?.applicantType)}
          </div>

          <div className="home__cabinet">
            <h2 className="title__cabinet__form">
              <Trans>additionalInformation</Trans>
            </h2>
            {this.renderExtraInfoData()}
          </div>
        </div>
        <div className="personal-form__footer btn-cabinet">
          <button
            className="button-input btn btn-success"
            onClick={this.verifyApplicant}
          >
            <Trans>Save</Trans>
          </button>
        </div>

        {showEmailChangeModal && (
          <div className="email-change-modal">
            <div className="email-change-modal-content">
              <label htmlFor="change-email">
                <Trans>changeEmail</Trans>
              </label>
              <input
                className="general-info__input form-control-lg border-input"
                type="text"
                value={this.state.changedEmail}
                name="change-email"
                onChange={this.handleEmailChangeInputChange}
                placeholder={i18next.t('enterNewEmail')}
              />
              {this.state.isNotValidEmail && (
                <span className="general-info__change-email__valid-err">
                  <Trans>enterMail</Trans>
                </span>
              )}
              <div className="email-change-modal-content-btn">
                <button
                  className="button-input btn btn-success"
                  onClick={() => {
                    const newEmail = this.state.changedEmail;
                    if (!emailRegex.test(newEmail)) {
                      this.setState({ isNotValidEmail: true });
                      return;
                    }
                    this.props.appState.applicantsStore
                      .changeEmail(newEmail)
                      .then((res) => {
                        const parsedRes = JSON.parse(res);
                        const { status } = parsedRes;
                        switch (status) {
                          case ChangeEmailStatuses.Success: {
                            toast.success(
                              i18next.t('changeEmail.success', {
                                ns: 'errors',
                              })
                            );
                            this.setState({
                              showEmailChangeModal: false,
                              changedEmail: '',
                              isNotValidEmail: false,
                            });
                            break;
                          }
                          case ChangeEmailStatuses.ErrorEmailDuplicate: {
                            toast.error(
                              i18next.t('changeEmail.errorEmailDuplicate', {
                                ns: 'errors',
                              })
                            );
                            break;
                          }
                          case ChangeEmailStatuses.ErrorGeneral: {
                            toast.error(
                              i18next.t('unknown', {
                                ns: 'errors',
                              })
                            );
                            break;
                          }
                          default: {
                            toast.error(
                              i18next.t('unknown', {
                                ns: 'errors',
                              })
                            );
                            break;
                          }
                        }
                      })
                      .catch((err) => {
                        toast.error(
                          i18next.t('unknown', {
                            ns: 'errors',
                          })
                        );
                      });
                  }}
                >
                  <Trans>Confirm</Trans>
                </button>
                <button
                  className="button-input btn btn-light btn-popup"
                  onClick={() => {
                    this.setState({
                      showEmailChangeModal: false,
                      changedEmail: '',
                      isNotValidEmail: false,
                    });
                  }}
                >
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </div>
          </div>
        )}

        {showAvatarModal && (
          <div className="avatar-modal">
            <OutsideClickHandler onOutsideClick={this.closeAvatarModal}>
              <Avatar ref={this.avatarComponent} />
              <div className="btn__actions btn__actions--avatar">
                <button
                  className="button-input btn btn-success"
                  onClick={() => {
                    this.savePhoto();
                    this.closeAvatarModal();
                  }}
                >
                  <Trans>Save</Trans>
                </button>
                <button
                  className="button-input btn btn-light btn-popup"
                  onClick={this.closeAvatarModal}
                >
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </OutsideClickHandler>
          </div>
        )}
      </div>
    );
  }

  renderInput = (item) => {
    const { applicant } = this.props;
    if (item.type === 'text') {
      if (item.value === 'email') {
        return (
          <>
            <input
              defaultValue={applicant[item.value]}
              className="general-info__input form-control-lg border-input"
              name={item.value}
              readOnly
              required
            />
            <button
              className="email-change-show-btn"
              onClick={() => {
                this.setState({ showEmailChangeModal: true });
              }}
            >
              <Trans>changeEmail</Trans>
            </button>
          </>
        );
      }
      return (
        <>
          <input
            value={applicant[item.value]}
            type={item.type}
            className="general-info__input form-control-lg border-input"
            onChange={this.handleInputChange}
            name={item.value}
            required
          />
        </>
      );
    } else if (item.type === 'date') {
      return (
        <>
          <div className="home__content home__calendar">
            <div className="calendar">
              <DatePicker
                wrapperClassName="calendar__datePicker"
                name={'calendar-profile'}
                locale={i18n.language ? i18n.language : 'ru'}
                className="expense-plan__input expense-plan__calendar"
                dateFormat="dd.MM.yyyy"
                selected={
                  this.state.birthDay ? new Date(this.state.birthDay) : ''
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(date: Date) => this.setState({ birthDay: date })}
                isClearable={true}
                maxDate={this.getMaxDate()}
                placeholder={i18next.t('datePlaceholder')}
                customInput={<ExampleCustomInput ref={this.datePicker} />}
                required
              />
              <img
                className="calendar__icon"
                src={Calendar}
                alt="Calendar"
                width={20}
                height={20}
              />
            </div>
          </div>
        </>
      );
    } else if (item.type === 'number') {
      if (item.name === 'telefon') {
        return (
          <InputMask
            defaultValue={this.state.cellNumber}
            name="phone"
            onChange={(e) => this.handlePhoneChange(e)}
            mask="+9 (999) 999-9999"
            maskChar=" "
            className="general-info__input form-control-lg border-input"
            style={{ width: '100%' }}
            required
          />
        );
      }
      return (
        <>
          <InputMask
            key={item.name + applicant.applicantType}
            defaultValue={applicant[item.value]}
            name={item.value}
            onChange={(e) => this.handleInputChange(e)}
            mask="999999999999"
            className="general-info__input form-control-lg border-input"
            required
          />
        </>
      );
    } else if (item.type === 'checkbox') {
      return (
        <>
          <input
            name={item.value}
            type="checkbox"
            id={item.value}
            checked={applicant[item.value]}
            onChange={this.handleInputChange}
            className="general-info__input__checkbox work"
          />
        </>
      );
    } else if (item.type === 'dropdown') {
      if (item.list && item.list.length > 0) {
        return (
          <>
            <select
              value={applicant[item.value]}
              onChange={(event) => this.handleSelectChange(event, item.value)}
              className="general-info__input__select form-control-lg border-input"
            >
              {item.list.map((o, idx) => (
                <option value={o.name} key={idx}>
                  {i18next.t(o.caption)}
                </option>
              ))}
            </select>
          </>
        );
      } else {
        if (item.value === 'citizenship') {
          item.list = this.state.countryEnums;

          if (item.list) {
            if (!applicant[item.value]) {
              applicant[item.value] = 'KAZAKHSTAN';
            }
            return (
              <>
                <select
                  value={applicant[item.value]}
                  onChange={(event) =>
                    this.handleSelectChange(event, item.value)
                  }
                  className="general-info__input__select form-control-lg border-input"
                >
                  {item.list.map((o, idx) => (
                    <option value={o.name} key={idx}>
                      {o.caption}
                    </option>
                  ))}
                </select>
              </>
            );
          }
        }
        if (item.list) {
          return (
            <>
              <select
                value={applicant[item.value]}
                onChange={(event) => this.handleSelectChange(event, item.value)}
                className="general-info__input__select border-input"
              >
                {item.list.map((o, idx) => (
                  <option value={o.name} key={idx}>
                    {/*{o.caption}*/}
                    {i18next.t(o.caption)}
                  </option>
                ))}
              </select>
            </>
          );
        }
      }
    } else if (item.type === 'radio') {
      return (
        <>
          <div style={{ display: 'flex' }} className="field-radio__btn">
            {item.list.map((o, idx) => (
              <div
                key={idx}
                className="field-radio__btn field-radio__btn--group"
              >
                <input
                  type="radio"
                  name={item.value}
                  checked={applicant[item.value] === o.name}
                  value={o.name}
                  id={idx}
                  onChange={(event) =>
                    this.handleRadioChange(event, item.value)
                  }
                  className="field-radio__btn field-radio__btn--input"
                />
                <label
                  htmlFor={idx}
                  className="input__header input__header--general-info weight-normal"
                >
                  {i18next.t(o.caption)}
                </label>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  renderExtraInfoData() {
    const { extraInfo } = this.state;
    const { applicant } = this.props;
    const filterExtraInfo = extraInfo.filter((item) => {
      if (applicant.applicantType === 'EMPLOYEE') {
        return item.name !== 'isWork';
      }
      if (applicant.applicantType === 'STUDENT') {
        return item.name !== 'isWork' && item.name !== 'stillWork';
      } else {
        return item.name !== 'stillWork';
      }
    });

    return filterExtraInfo.map((item, idx) => {
      return item.name === 'isWork' || item.name === 'stillWork' ? (
        <div className="remeber__me__flex sign-up__checkbox" key={idx}>
          {this.renderExtraInput(item)}
          <label
            className="cabinet-label mb-3 remeber__me"
            htmlFor={item.value}
          >
            <span className={'input__header input__header--general-info'}>
              {i18next.t(item.name)}
            </span>
          </label>
        </div>
      ) : (
        <label className="cabinet-label mb-3" key={idx}>
          <span className={'input__header input__header--general-info'}>
            {i18next.t(item.name)}
          </span>
          {this.renderExtraInput(item)}
        </label>
      );
    });
  }

  renderExtraInfo(type) {
    const {
      studentInfo,
      employeeInfo,
      graduateNISInfo,
      graduateNUInfo,
      thirdPersonInfo,
    } = this.state;

    const nameArr = ['applicantType', 'IDNumber', 'hasGraBolashak'];

    switch (type) {
      case 'STUDENT':
        return studentInfo.map((item, idx) => {
          //DELETE WHEN TASK FOR CHANGING APPLICANT TYPE IS READY
          if (item.name === 'applicantType') return;
          const labelArray = ['school', 'grade', 'studyProgram'];
          return item.name === 'hasGraBolashak' ? (
            <div className="remeber__me__flex sign-up__checkbox" key={idx}>
              {nameArr.includes(item.name) && this.renderInput(item)}
              <label
                htmlFor={item.value}
                className="cabinet-label mb-3 remeber__me"
              >
                {labelArray.includes(item.name) ? (
                  <span className={'input__header input__header--general-info'}>
                    <span className="label-required">* </span>
                    {i18next.t(item.name)}
                  </span>
                ) : (
                  <span className={'input__header input__header--general-info'}>
                    {i18next.t(item.name)}
                  </span>
                )}
              </label>
            </div>
          ) : (
            <label key={idx} className="cabinet-label mb-3">
              {labelArray.includes(item.name) ? (
                <span className={'input__header input__header--general-info'}>
                  <span className="label-required">* </span>
                  {i18next.t(item.name)}
                </span>
              ) : (
                <span className={'input__header input__header--general-info'}>
                  {i18next.t(item.name)}
                </span>
              )}
              {nameArr.includes(item.name)
                ? this.renderInput(item)
                : this.renderExtraInput(item)}
            </label>
          );
        });
      case 'EMPLOYEE':
        return employeeInfo.map((item, idx) => {
          //DELETE WHEN TASK FOR CHANGING APPLICANT TYPE IS READY
          if (item.name === 'applicantType') return;
          return item.name === 'hasGraBolashak' ? (
            <div className="remeber__me__flex sign-up__checkbox" key={idx}>
              {nameArr.includes(item.name) && this.renderInput(item)}
              <label
                htmlFor={item.value}
                className="cabinet-label mb-3 remeber__me"
              >
                <span className={'input__header input__header--general-info'}>
                  {i18next.t(item.name)}
                </span>
              </label>
            </div>
          ) : (
            <label key={idx} className="cabinet-label mb-3">
              <span className={'input__header input__header--general-info'}>
                {i18next.t(item.name)}
              </span>
              {nameArr.includes(item.name)
                ? this.renderInput(item)
                : this.renderExtraInput(item)}
            </label>
          );
        });
      case 'GRADUATE_NIS':
        return graduateNISInfo.map((item, idx) => {
          const labelArray = [
            'university',
            'faculty',
            'specialty',
            'startYear',
            'endYear',
          ];
          //DELETE WHEN TASK FOR CHANGING APPLICANT TYPE IS READY
          if (item.name === 'applicantType') return;
          return item.name === 'hasGraBolashak' ? (
            <div className="remeber__me__flex sign-up__checkbox" key={idx}>
              {nameArr.includes(item.name) && this.renderInput(item)}
              <label
                htmlFor={item.value}
                className="cabinet-label mb-3 remeber__me"
              >
                {labelArray.includes(item.name) ? (
                  <span className={'input__header input__header--general-info'}>
                    <span className="label-required">* </span>
                    {i18next.t(item.name)}
                  </span>
                ) : (
                  <span className={'input__header input__header--general-info'}>
                    {i18next.t(item.name)}
                  </span>
                )}
              </label>
            </div>
          ) : (
            <label key={idx} className="cabinet-label mb-3">
              {labelArray.includes(item.name) ? (
                <span className={'input__header input__header--general-info'}>
                  <span className="label-required">* </span>
                  {i18next.t(item.name)}
                </span>
              ) : (
                <span className={'input__header input__header--general-info'}>
                  {i18next.t(item.name)}
                </span>
              )}
              {nameArr.includes(item.name)
                ? this.renderInput(item)
                : this.renderExtraInput(item)}
            </label>
          );
        });
      case 'GRADUATE_NU':
        return graduateNUInfo.map((item, idx) => {
          const labelArray = [
            'university',
            'faculty',
            'specialty',
            'school',
            'startYear',
            'endYear',
          ];
          //DELETE WHEN TASK FOR CHANGING APPLICANT TYPE IS READY
          if (item.name === 'applicantType') return;
          return item.name === 'hasGraBolashak' ? (
            <div className="remeber__me__flex sign-up__checkbox" key={idx}>
              {nameArr.includes(item.name) && this.renderInput(item)}
              <label
                htmlFor={item.value}
                className="cabinet-label mb-3 remeber__me"
              >
                {labelArray.includes(item.name) ? (
                  <span className={'input__header input__header--general-info'}>
                    <span className="label-required">* </span>
                    {i18next.t(item.name)}
                  </span>
                ) : (
                  <span className={'input__header input__header--general-info'}>
                    {i18next.t(item.name)}
                  </span>
                )}
              </label>
            </div>
          ) : (
            <label key={idx} className="cabinet-label mb-3">
              {labelArray.includes(item.name) ? (
                <span className={'input__header input__header--general-info'}>
                  <span className="label-required">* </span>
                  {i18next.t(item.name)}
                </span>
              ) : (
                <span className={'input__header input__header--general-info'}>
                  {i18next.t(item.name)}
                </span>
              )}
              {nameArr.includes(item.name)
                ? this.renderInput(item)
                : this.renderExtraInput(item)}
            </label>
          );
        });
      case 'THIRD_PERSON':
        return thirdPersonInfo.map((item, idx) => {
          //DELETE WHEN TASK FOR CHANGING APPLICANT TYPE IS READY
          if (item.name === 'applicantType') return;
          return item.name === 'hasGraBolashak' ? (
            <div className="remeber__me__flex sign-up__checkbox" key={idx}>
              {nameArr.includes(item.name) && this.renderInput(item)}
              <label
                htmlFor={item.value}
                className="cabinet-label mb-3 remeber__me"
              >
                <span className={'input__header input__header--general-info'}>
                  {i18next.t(item.name)}
                </span>
              </label>
            </div>
          ) : (
            <label key={idx} className="cabinet-label mb-3">
              <span className={'input__header input__header--general-info'}>
                {i18next.t(item.name)}
              </span>
              {nameArr.includes(item.name)
                ? this.renderInput(item)
                : this.renderExtraInput(item)}
            </label>
          );
        });
    }
  }

  renderExtraInput = (item) => {
    const { applicant } = this.props;
    let form;
    const workRelatedInputs = ['organization', 'department', 'position'];

    switch (applicant.applicantType) {
      case 'STUDENT':
        if (!applicant.student) {
          applicant.student = {};
        }
        form = applicant.student;
        break;
      case 'EMPLOYEE':
        if (!applicant.staff) {
          applicant.staff = {};
        }
        form = applicant.staff;
        break;
      // case "ORGANIZATION":
      //   if (!applicant.organization) {
      //     applicant.organization = {};
      //   }
      //   form = { form: applicant.organization };
      //   break;
      case 'GRADUATE_NIS':
        if (!applicant.graduateNIS) {
          applicant.graduateNIS = {};
        }
        form = this.state?.graduateNIS;
        break;
      case 'GRADUATE_NU':
        if (!applicant.graduateNU) {
          applicant.graduateNU = {};
        }
        form = this.state.graduateNU;
        break;
      case 'THIRD_PERSON':
        if (!applicant.thirdPerson) {
          applicant.thirdPerson = {};
        }
        form = this.state.thirdPerson;
        break;
    }
    if (item.type === 'text') {
      const isDisabled = workRelatedInputs.includes(item.value) && form.isWork;
      return (
        <>
          <input
            key={item.name + applicant.applicantType}
            value={form[item.value]}
            className="general-info__input form-control-lg border-input"
            onChange={this.handleExtraInputChange}
            name={item.value}
            disabled={isDisabled}
          />
        </>
      );
    } else if (item.type === 'number') {
      if (applicant.applicantType === 'STUDENT' && item.value === 'gpa') {
        return (
          <>
            <InputMask
              key={item.name + applicant.applicantType}
              value={form[item.value]}
              className="general-info__input form-control-lg border-input"
              mask="9,99"
              maskChar=""
              onChange={this.handleExtraInputChange}
              name={item.value}
              required
            />
          </>
        );
      }
      return (
        <input
          key={item.name + applicant.applicantType}
          type="number"
          value={form[item.value]}
          className="general-info__input form-control-lg border-input"
          onChange={this.handleExtraInputChange}
          name={item.value}
          placeholder={i18next.t(item.name)}
          required
          min="1"
        />
      );
    } else if (item.type === 'date') {
      if (
        applicant.applicantType === 'EMPLOYEE' ||
        applicant.applicantType === 'STUDENT'
      ) {
        return (
          <div className="home__content home__calendar">
            <div className="calendar">
              <DatePicker
                wrapperClassName="calendar__datePicker"
                name={'calendar-profile'}
                locale={i18n.language ? i18n.language : 'ru'}
                className="expense-plan__input expense-plan__calendar"
                dateFormat="dd.MM.yyyy"
                selected={
                  this.state[item.value] ? new Date(this.state[item.value]) : ''
                }
                placeholder={i18next.t('datePlaceholder')}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={this.handleChangeDateExtra}
                isClearable={true}
                required
                customInput={<ExampleCustomInput ref={this.datePicker} />}
              />
              <img
                className="calendar__icon"
                src={Calendar}
                alt="Calendar"
                width={20}
                height={20}
              />
            </div>
          </div>
        );
      }
      return (
        <InputMask
          key={item.name + applicant.applicantType}
          defaultValue={form[item.value]}
          name={item.value}
          onChange={this.handleExtraInputChange}
          mask="2099"
          maskChar=""
          className="general-info__input form-control-lg border-input"
          required
        />
      );
    } else if (item.type === 'checkbox') {
      if (item.value === 'stillWork') {
        return (
          <>
            <input
              key={item.name + applicant.applicantType}
              name={item.value}
              id={item.value}
              type="checkbox"
              checked={this.state.stillWork}
              onChange={this.handleIsWorkCheckbox}
              className="general-info__input__checkbox"
              placeholder={i18next.t(item.name)}
            />
          </>
        );
      }
      if (item.value === 'isWork') {
        if (applicant.applicantType === 'THIRD_PERSON') {
          return (
            <>
              <input
                key={item.name + applicant.applicantType}
                name={item.value}
                id={item.value}
                type="checkbox"
                checked={this.state.thirdPerson.isWork}
                onChange={(e) => this.handleIsWorkCheckbox(e)}
                className="general-info__input__checkbox"
              />
            </>
          );
        }
        if (applicant.applicantType === 'GRADUATE_NU') {
          return (
            <>
              <input
                key={item.name + applicant.applicantType}
                name={item.value}
                id={item.value}
                type="checkbox"
                checked={this.state.graduateNU.isWork}
                onChange={(e) => this.handleIsWorkCheckbox(e)}
                className="general-info__input__checkbox"
              />
            </>
          );
        }
        if (applicant.applicantType === 'GRADUATE_NIS') {
          return (
            <>
              <input
                key={item.name + applicant.applicantType}
                name={item.value}
                id={item.value}
                type="checkbox"
                checked={this.state.graduateNIS.isWork}
                onChange={this.handleIsWorkCheckbox}
                className="general-info__input__checkbox work"
              />
            </>
          );
        }
      }
      // return (
      //   <td>
      //     <input
      //       key={item.name+applicant.applicantType}
      //       name={item.value}
      //       type="checkbox"
      //       checked={this.state.thirdPerson.isWork}
      //       onChange={this.handleExtraInputChange}
      //       className="general-info__input__checkbox"
      //     />
      //   </td>
      // );
    } else if (item.type === 'dropdown') {
      if (item.list && item.list.length > 0) {
        return (
          <>
            <select
              value={form[item.value]}
              onChange={(event) => {
                event.persist();
                this.handleExtraSelectChange(event, item.value);
              }}
              className="general-info__input__select form-control-lg border-input"
            >
              {item.list.map((o, idx) => (
                <option value={o.name} key={idx}>
                  {i18next.t(item.caption)}
                </option>
              ))}
            </select>
          </>
        );
      } else {
        if (item.value === 'studyProgram') {
          return (
            <>
              <select
                value={form[0]?.name}
                onChange={(event) => {
                  event.persist();
                  this.handleExtraSelectChange(event, item.value);
                }}
                className="general-info__input__select form-control-lg border-input"
              >
                {this.state.studyProgramEnums.map((o, idx) => (
                  <option value={o.name} key={idx}>
                    {o.caption}
                  </option>
                ))}
              </select>
            </>
          );
        }
        if (item.value === 'socialStatus') {
          return (
            <>
              <select
                value={form[0]?.name}
                onChange={(event) => {
                  event.persist();
                  this.handleExtraSelectChange(event, item.value);
                }}
                className="general-info__input__select form-control-lg border-input"
              >
                <option value={null} />
                {this.state.socialStatusEnums.map((o, idx) => (
                  <option value={o.name} key={idx}>
                    {o.caption}
                  </option>
                ))}
              </select>
            </>
          );
        }
        if (item.value === 'company') {
          return (
            <>
              <select
                value={this.state.companyID}
                onChange={(event) => {
                  event.persist();
                  this.handleExtraSelectChange(event, item.value);
                }}
                className="general-info__input__select form-control-lg border-input"
              >
                {this.state.companyEnums.map((o, idx) => (
                  <option value={o.name} key={idx}>
                    {o.caption}
                  </option>
                ))}
              </select>
            </>
          );
        }
        if (item.value === 'school') {
          if (this.props.applicant.applicantType === 'GRADUATE_NU') {
            const findSchool = this.state.schoolEnums.find(
              (school) => school.caption === this.state.graduateNU.faculty
            );
            return (
              <>
                <select
                  value={findSchool?.name}
                  onChange={(event) => {
                    event.persist();
                    this.handleExtraSelectChange(event, item.value);
                  }}
                  className="general-info__input__select form-control-lg border-input"
                >
                  {this.state.schoolEnums.map((o, idx) => (
                    <option
                      value={o.name}
                      key={idx}
                      selected={o.caption === this.state.graduateNU.faculty}
                    >
                      {o.caption}
                    </option>
                  ))}
                </select>
              </>
            );
          }
          return (
            <>
              <select
                value={this.state.schoolID}
                onChange={(event) => {
                  event.persist();
                  this.handleExtraSelectChange(event, item.value);
                }}
                className="general-info__input__select form-control-lg border-input"
              >
                {this.state.schoolEnums.map((o, idx) => (
                  <option value={o.name} key={idx}>
                    {o.caption}
                  </option>
                ))}
              </select>
            </>
          );
        }
        // if (item.list) {
        //   return (
        //     <td>
        //       <span>{JSON.stringify(item)}</span>
        //       <select
        //         value={item.list[0]?.name}
        //         onChange={(event) => {
        //           event.persist();
        //           this.handleExtraSelectChange(event, item.value)
        //         }}
        //         className="general-info__input__select"
        //       >
        //         {item.list.map((o, idx) => (
        //           <option value={o.name} key={idx}>
        //             {o.caption}
        //           </option>
        //         ))}
        //       </select>
        //     </td>
        //   );
        // }
      }
    }
  };
}

export default GeneralInfo;
