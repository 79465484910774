import React from 'react';
import { Trans } from 'react-i18next';
import { IG, SG } from '../../consts';
import { ApplicationFormContext } from '../ApplicationFormProvider';
import Textarea from '../Ui/Textarea';
import { sectionNumber } from '../ApplicationFormProvider/sectionNumber';

class FieldInnovativeness extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.context.handleApplicationFormChange({
      innovativeness: { [name]: value },
    });
  };

  render() {
    const { readOnly } = this.context;
    const { innovation, competitors, advantages } =
      this.context.applicationForm.innovativeness;
    const programName = this.props.program.name;

    return (
      <>
        <div className="compform__divider" />
        <h1 className="main__question">
          {sectionNumber.renderSection('INNOVATIVENESS')}. <Trans>INNOV</Trans>
        </h1>

        <h3 className="form__question">
          {sectionNumber.renderSubSection('innovation')}.{' '}
          <Trans>innovation</Trans>
        </h3>
        <p className="form__question__descrption">
          <Trans>innovationExtra</Trans>
        </p>
        <Textarea
          defaultValue={innovation}
          name="innovation"
          onChange={this.handleInputChange}
          readOnly={readOnly}
        />

        <h3 className="form__question">
          {sectionNumber.renderSubSection('competitors')}.{' '}
          <Trans>competitors</Trans>
        </h3>
        <p className="form__question__descrption">
          <Trans>competitorsExtra</Trans>
        </p>

        <Textarea
          defaultValue={competitors}
          name="competitors"
          onChange={this.handleInputChange}
          readOnly={readOnly}
        />

        {programName !== SG && programName !== IG && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('advantages')}.{' '}
              <Trans>advantages</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>advantagesExtra</Trans>
            </p>

            <Textarea
              defaultValue={advantages}
              name="advantages"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}
      </>
    );
  }
}

export default FieldInnovativeness;
