import React from 'react';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import classNames from 'classnames';
import DatePickerMultiComponent from '../DatePickerMulti';
import { injectAppState } from '../../stores';
import { observer } from 'mobx-react';
import { Container } from 'react-bootstrap';
import { ApplicationFormContext } from 'components/ApplicationFormProvider';
import Input from 'components/Ui/Input';

@injectAppState
@observer
class FieldApplicationForm extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.context.handleApplicationFormChange({
      applicationForm: { [name]: value },
    });
  };

  handleRadioChange = (event, value) => {
    this.context.handleApplicationFormChange({
      applicationForm: { applicantKind: value },
    });
  };

  handleDateChange = (d) => {
    this.context.handleApplicationFormChange({
      applicationForm: { projectDuration: d },
    });
  };

  render() {
    const appKind = [
      { name: 'organization', value: 'ORGANIZATION' },
      { name: 'individual', value: 'INDIVIDUAL' },
    ];
    const { applicationForm, readOnly, getError, clearError } = this.context;
    const {
      organizationName,
      activityDirection,
      projectDuration,
      projectName,
      applicantKind,
    } = applicationForm.applicationForm;
    const { language } = this.props.appState.userStore;

    return (
      <>
        <Container className="comp__program__form">
          <div className="home__content">
            <label className="input__header" htmlFor="">
              <Trans>projectName</Trans>
            </label>

            <Input
              defaultValue={projectName}
              name="projectName"
              maxLength={200}
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </div>

          <div className="home__content home__calendar">
            <label className="input__header input__cal" htmlFor="">
              <Trans>projectDuration</Trans>
            </label>

            <DatePickerMultiComponent
              className={classNames('default-input')}
              isNotFilled={getError('projectDuration')}
              name="projectDuration"
              selected={projectDuration}
              handleChangeDate={this.handleDateChange}
              idx={null}
              readOnly={readOnly}
              clearError={clearError}
            />
          </div>

          <div className="field-radio">
            <label className="input__header" htmlFor="">
              <Trans>applicantKind</Trans> <Trans>applicantKindExtra</Trans>
            </label>
            <div className="field-radio__btn">
              {appKind.map((o, idx) => (
                <div
                  className="field-radio__btn field-radio__btn--group radio-form"
                  key={idx}
                >
                  <input
                    type="radio"
                    name="applicantKind"
                    id={'radioAppKind_' + idx}
                    checked={o.value === applicantKind}
                    value={o.value}
                    onChange={
                      !readOnly
                        ? (event) => this.handleRadioChange(event, o.value)
                        : null
                    }
                    readOnly={readOnly}
                    className="field-radio__btn field-radio__btn--input"
                  />
                  <label
                    className="input__header weight-normal"
                    htmlFor={`radioAppKind_${idx}`}
                  >
                    {this.props.program && o.name === 'individual'
                      ? i18next.t('individualI')
                      : i18next.t(o.name)}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {applicantKind === 'ORGANIZATION' && (
            <>
              <div className="home__content">
                <label className="input__header" htmlFor="">
                  <Trans>organizationName</Trans>
                </label>
                <Input
                  defaultValue={organizationName}
                  maxLength={200}
                  name="organizationName"
                  onChange={this.handleInputChange}
                  readOnly={readOnly}
                />
              </div>

              <div className="home__content">
                <label className="input__header" htmlFor="">
                  <Trans>activityDirection</Trans>
                  <div>
                    <Trans>activityDirectionExtra</Trans>
                  </div>
                </label>

                <Input
                  defaultValue={activityDirection}
                  maxLength={200}
                  name="activityDirection"
                  onChange={this.handleInputChange}
                  readOnly={readOnly}
                />
              </div>
            </>
          )}
        </Container>
      </>
    );
  }
}

export default FieldApplicationForm;
