import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Trans } from 'react-i18next';

import { AppStateObserver, injectAppState } from 'stores';
import Competition from 'components/Competition';
import SentApplicationView from 'components/SentApplicationView';
import Load from 'components/Load';
import competitionTypeIcon from 'assets/icons/competitionType-icon.svg';
import './MainPage.css';

export interface MainPageProps {}

export interface MainPageProps {}

export interface MainPageState {}

@injectAppState
@observer
class MainPage extends React.Component<
  AppStateObserver,
  MainPageProps,
  MainPageState
> {
  state = {
    mounted: false,
  };

  compare(a, b) {
    let comparision = 0;
    if (a.dateStartRegistration < b.dateStartRegistration) {
      comparision = 1;
    } else if (a.dateStartRegistration > b.dateStartRegistration) {
      comparision = -1;
    }
    return comparision;
  }

  async componentDidMount() {
    try {
      await this.props.appState.competitionsStore.loadCompetitions();
      await this.props.appState.competitionsStore.loadCompetitionTypes();
      await this.props.appState.applicationsStore.loadEntities();

      await this.props.appState.userStore.teamMemberOrTeamLead(
        this.props.appState.userStore.userInfo.id
      );
    } catch (e) {
      console.warn(e);
    }
  }

  render() {
    const { language } = this.props.appState.userStore;
    const { archivedCompetitions, isLoadingList, anketaOk, competitions } =
      this.props.appState.competitionsStore;

    if (isLoadingList) {
      return (
        <div className="loader-container">
          <Load />
        </div>
      );
    }

    return (
      <div className="container">
        <h1 className="title__home" style={{ marginBottom: '10px' }}>
          <Trans>Comps</Trans>
        </h1>
        <div className="row column column__competiotions">
          <section className="col column__left portal__card--content p-0">
            {competitions && competitions.length > 0 ? (
              this.renderAllCompetitions(competitions, anketaOk)
            ) : (
              <p className="portal__text">
                <Trans>NoComps</Trans>
              </p>
            )}
          </section>
          <section className="col column__right portal__card--content p-0">
            {archivedCompetitions &&
              this.renderArchivedCompetitions(archivedCompetitions, anketaOk)}
          </section>
        </div>
      </div>
    );
  }

  renderAllCompetitions = (comps, isItOk) => {
    const { isTeamMember, applicationId } =
      this.props.appState.userStore.teamRole;

    return comps.slice().map((competition) => {
      return (
        <Fragment key={competition.id}>
          {competition.applicationId || (isTeamMember && applicationId) ? (
            <SentApplicationView
              anketaOk={isItOk}
              key={competition.id}
              name={competition.name}
              dateStart={competition.dateStartRegistration}
              dateEnd={competition.dateEndRegistration}
              id={competition.id}
              status={competition.status}
              info={competition.info}
              isMainPage={true}
              appId={competition.applicationId ?? applicationId}
            />
          ) : (
            <Competition
              anketaOk={isItOk}
              key={competition.id}
              name={competition.name}
              dateStart={competition.dateStartRegistration}
              dateEnd={competition.dateEndRegistration}
              id={competition.id}
              status={competition.status}
              info={competition.info}
              isMainPage={true}
            />
          )}
        </Fragment>
      );
    });
  };

  renderSentApplications(createdApplications, isItOk) {
    let createdApps = [];
    if (createdApplications) {
      createdApps = createdApplications;
    }

    return createdApps
      .slice()
      .map((competition) => (
        <SentApplicationView
          anketaOk={isItOk}
          key={competition.id}
          name={competition.name}
          dateStart={competition.dateStartRegistration}
          dateEnd={competition.dateEndRegistration}
          id={competition.id}
          status={competition.status}
          info={competition.info}
          isMainPage={true}
          appId={competition.applicationId}
          applicationStatus={
            this.props.appState.applicationsStore?.applicationList[0]
          }
        />
      ));
  }

  renderActiveCompetitions(activeCompetitions, isItOk) {
    return activeCompetitions
      .slice()
      .map((competition) => (
        <Competition
          anketaOk={isItOk}
          key={competition.id}
          name={competition.name}
          dateStart={competition.dateStartRegistration}
          dateEnd={competition.dateEndRegistration}
          id={competition.id}
          status={competition.status}
          info={competition.info}
          isMainPage={true}
        />
      ));
  }

  renderArchivedCompetitions(archivedCompetitions, isItOk) {
    return archivedCompetitions
      .slice()
      .map((competition) => (
        <Competition
          anketaOk={isItOk}
          key={competition.id}
          name={competition.name}
          dateStart={competition.dateStartRegistration}
          dateEnd={competition.dateEndRegistration}
          id={competition.id}
          status={competition.status}
          info={competition.info}
          isMainPage={true}
        />
      ));
  }

  renderProjects(compTypes) {
    if (compTypes) {
      return (
        <Fragment>
          <h1 className="competition-types__heading">
            <Trans>ListOfProjectsAndPrograms</Trans>
          </h1>
          {compTypes.map((type) => {
            return (
              <div className="competition-type" key={type.id}>
                {type.code === 'NJC' && (
                  <>
                    <p className="competition-type__name">
                      <img
                        src={competitionTypeIcon}
                        alt=""
                        style={{ marginRight: '4px' }}
                      />
                      {type.name}
                    </p>
                    {type.code === 'NJC' && (
                      <div className="competition-type__contacts-container">
                        <p className="competition-type__contacts">
                          <Trans>managerContacts</Trans>
                        </p>
                        <p className="competition-type__contacts">
                          Zhanar Tulegenova
                        </p>
                        <p className="competition-type__contacts">
                          <Trans>tel</Trans>: + 7 (7172) 72-56-25
                        </p>
                        <p className="competition-type__contacts">
                          Email: zhanar.tulegenova@nu.edu.kz
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </Fragment>
      );
    }
  }
}

export default MainPage;
