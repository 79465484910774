import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ApplicationFormContext } from 'components/ApplicationFormProvider';

interface IProps {
  defaultValue: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  readOnly?: boolean;
  maxLength?: number;
}

// input type=text
export const Input = (props: IProps) => {
  const { defaultValue, name, onChange, className, maxLength = 200 } = props;

  const { readOnly, getError, clearError } = useContext(ApplicationFormContext);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    clearError && clearError(name);
    setValue(event.target.value);
    onChange && onChange(event);
  };

  return (
    <input
      className={classNames(
        'default-input',
        getError(name) && 'input__error',
        className
      )}
      value={value}
      name={name}
      maxLength={maxLength}
      onChange={handleChange}
      readOnly={readOnly}
      type="text"
    />
  );
};

export default Input;
