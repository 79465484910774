import React from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import wrongText from '../../helpers/wrongText';
import FileComponent from '../FileComponent';
import { injectAppState } from '../../stores';
import { PG, SP } from '../../consts';
import Load from '../Load';
import { ApplicationFormContext } from '../ApplicationFormProvider';
import { sectionNumber } from '../ApplicationFormProvider/sectionNumber';
import i18next from 'i18next';
import './TM.css';
import matchOnlyLetters from '../../helpers/matchOnlyLetters';
import InputMask from 'react-input-mask';
import validateFileSize from '../../helpers/validateFileSize';
import { toast } from 'react-toastify';

@injectAppState
class TM extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  state = {
    fileLoading: false,
    teamRowLimitLogVisible: false,
  };

  handleOnBlur = () => {
    this.context.clearError('email');
    this.context.clearError('iin');
  };

  handleInputChangeTM = (event, index) => {
    const target = event.target;
    const { name, type } = target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const experiences = [...this.context.applicationForm.teamMembers];
    const isOnlyLetters = matchOnlyLetters(value);

    if (value.length) {
      if (name === 'iin' && (value.length > 12 || !/^\d+$/.test(value))) {
        return;
      }

      if (name === 'workExperience' && (value.length > 2 || !isOnlyLetters)) {
        return;
      }

      if (
        target.type === 'text' &&
        isOnlyLetters &&
        name !== 'iin' &&
        name !== 'workExperience'
      ) {
        return;
      }
    }
    if (name === 'workExperience') {
      value = Number(value);
    }

    experiences[index][name] = value;
    this.context.handleApplicationFormChange({ teamMembers: experiences });
  };

  addTM = () => {
    this.context.clearError('teamMembers');
    let experienceForm = {
      email: '',
      iin: '',
      firstName: '',
      lastName: '',
      middleName: '',
      workExperience: '',
      role: '',
    };
    const teamMembers = this.context.applicationForm.teamMembers
      ? [...this.context.applicationForm.teamMembers]
      : [];
    if (teamMembers.length < 6) {
      teamMembers.push(experienceForm);
      this.context.handleApplicationFormChange({ teamMembers });
      this.setState({
        teamRowLimitLogVisible: false,
      });
    } else {
      this.setState({
        teamRowLimitLogVisible: true,
      });
    }
  };

  deleteTM = (index) => {
    let experiences = [...this.context.applicationForm.teamMembers];
    if (experiences.length > 0) experiences.splice(index, 1);

    this.context.handleApplicationFormChange({ teamMembers: experiences });
  };

  handleFileChange = (files, idx) => {
    if (!validateFileSize(50, files)) {
      toast.error(i18next.t('info.fileSizeLimit', { ns: 'errors' }));
      return;
    }
    let teamMembers = [...this.context.applicationForm.teamMembers];

    if (files.length) {
      this.setState({ fileLoading: true });

      Array.from(files).map((item) => {
        this.props.appState.filesStore
          .uploadFile(item)
          .then((response) => {
            teamMembers[idx] = {
              ...teamMembers[idx],
              resume: { file: response.data },
            };

            this.context.handleApplicationFormChange({ teamMembers });
          })
          .catch(() => {
            wrongText(i18next.t('ErrorLoading'));
          })
          .finally(() => {
            this.setState({ fileLoading: false });
          });
      });
    }
  };

  loadFile = (id) => {
    return this.props.appState.filesStore.downloadFile(id);
  };

  renderDocFile(doc) {
    if (doc) {
      return (
        <FileComponent id={doc.id} name={doc.name} extension={doc.extension} />
      );
    }
  }

  deleteFile(teamMember, teamMembers) {
    teamMember.resume = '';
    this.context.handleApplicationFormChange({ teamMembers });
  }

  render() {
    const { teamMembers } = this.context.applicationForm;
    const { readOnly, getError } = this.context;
    const programName = this.props.program;
    const tableError = getError('iin') || getError('email');
    const fieldError = getError('teamMembers') && teamMembers.length <= 1;

    return (
      <div className={fieldError && 'tm-error red-border-error'}>
        {programName !== PG && programName !== SP && (
          <>
            <div className="compform__divider" />
            <h1 className="main__question">
              {sectionNumber.renderSection('TEAM')}. <Trans>RESOURCE</Trans>
            </h1>
          </>
        )}
        <div>
          <h3 className="form__question" id="teamMembers">
            {sectionNumber.renderSubSection('teamMembers')}.{' '}
            <Trans>teamMembers</Trans>
          </h3>
          <p className="form__question__descrption">
            <Trans>teamMExtra</Trans>. <Trans>teamMExtra2</Trans>.
          </p>
        </div>
        <div className="application-table__body application-table__body_tm m-t">
          {teamMembers &&
            teamMembers.map((tm, idx) => (
              <div
                className={
                  tableError
                    ? 'application-table application-table-tm red-border-error'
                    : 'application-table application-table-tm'
                }
                key={idx}
              >
                <table className="second__table second__table-tm">
                  <tbody>
                    <tr>
                      <th id="width-40">
                        <Trans>OPMemberEmail</Trans>
                      </th>
                      <td className="expense-plan__td">
                        <input
                          value={tm.email}
                          maxLength={200}
                          className="expense-plan__input"
                          type="email"
                          name="email"
                          required
                          onBlur={this.handleOnBlur}
                          onChange={(event) =>
                            this.handleInputChangeTM(event, idx)
                          }
                          readOnly={readOnly}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th id="width-40">
                        <Trans>IIN</Trans>
                      </th>
                      <td className="expense-plan__td">
                        <InputMask
                          defaultValue=""
                          value={tm.iin}
                          name="iin"
                          onBlur={this.handleOnBlur}
                          onChange={(event) =>
                            this.handleInputChangeTM(event, idx)
                          }
                          // mask="999999999999"
                          className="expense-plan__input"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th id="width-40">
                        <Trans>Name</Trans>
                      </th>
                      <td className="expense-plan__td">
                        <input
                          value={tm.firstName}
                          maxLength={200}
                          className="expense-plan__input"
                          type="text"
                          onBlur={this.handleOnBlur}
                          name="firstName"
                          onChange={(event) =>
                            this.handleInputChangeTM(event, idx)
                          }
                          readOnly={readOnly}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th id="width-40">
                        <Trans>Surname</Trans>
                      </th>
                      <td className="expense-plan__td">
                        <input
                          value={tm.lastName}
                          maxLength={200}
                          className="expense-plan__input"
                          type="text"
                          onBlur={this.handleOnBlur}
                          name="lastName"
                          onChange={(event) =>
                            this.handleInputChangeTM(event, idx)
                          }
                          readOnly={readOnly}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th id="width-40">
                        <Trans>Middlename</Trans>
                      </th>
                      <td className="expense-plan__td">
                        <input
                          value={tm.middleName}
                          onBlur={this.handleOnBlur}
                          maxLength={200}
                          className="expense-plan__input"
                          type="text"
                          name="middleName"
                          onChange={(event) =>
                            this.handleInputChangeTM(event, idx)
                          }
                          readOnly={readOnly}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th id="width-40">
                        <Trans>WorkExperience</Trans>
                      </th>
                      <td className="expense-plan__td">
                        {/*<input*/}
                        {/*  value={tm.workExperience}*/}
                        {/*  maxLength={200}*/}
                        {/*  className="expense-plan__input"*/}
                        {/*  type="number"*/}
                        {/*  name="workExperience"*/}
                        {/*  onChange={(event) =>*/}
                        {/*    this.handleInputChangeTM(event, idx)*/}
                        {/*  }*/}
                        {/*  readOnly={readOnly}*/}
                        {/*/>*/}
                        <input
                          type="text"
                          value={tm.workExperience.toLocaleString('ru')}
                          name="workExperience"
                          className="expense-plan__input"
                          onBlur={this.handleOnBlur}
                          onChange={(event) =>
                            this.handleInputChangeTM(event, idx)
                          }
                          readOnly={readOnly}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th id="width-40">
                        <Trans>specificRole</Trans>
                      </th>
                      <td className="expense-plan__td">
                        <input
                          value={tm.role}
                          className="expense-plan__input"
                          maxLength={200}
                          type="text"
                          name="role"
                          onBlur={this.handleOnBlur}
                          onChange={(event) =>
                            this.handleInputChangeTM(event, idx)
                          }
                          readOnly={readOnly}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th id="width-40">
                        <Trans>Resume</Trans>
                      </th>
                      <td className={'expense-plan__td'}>
                        <div
                          className="inputfile-wrap"
                          style={{ justifyContent: 'center' }}
                        >
                          {tm?.resume?.file && (
                            <div className="flex align-items-center">
                              {this.renderDocFile(tm.resume.file)}
                              <span
                                onClick={() => this.deleteFile(tm, teamMembers)}
                                className="icon-delete"
                              >
                                +
                              </span>
                            </div>
                          )}
                          {this.state.fileLoading ? (
                            <Load />
                          ) : (
                            <>
                              <label
                                className={classNames(
                                  'button-input btn btn-success',
                                  readOnly && 'disabled'
                                )}
                                style={{ marginTop: '0' }}
                                htmlFor={`inputfileTM-${idx}`}
                              >
                                <Trans>ChooseFile</Trans>
                              </label>
                              <input
                                type="file"
                                id={`inputfileTM-${idx}`}
                                onChange={(event) =>
                                  this.handleFileChange(event.target.files, idx)
                                }
                                disabled={readOnly}
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {!readOnly && (
                  <button
                    className="button-input btn btn-danger tm-delete-btn"
                    onClick={() => this.deleteTM(idx)}
                  >
                    <Trans>delete</Trans>
                  </button>
                )}
              </div>
            ))}
        </div>
        <div className="add-expense__container">
          <button
            className="button-input btn btn-success"
            onClick={this.addTM}
            disabled={(teamMembers && teamMembers.length >= 10) || readOnly}
          >
            <Trans>Add</Trans>
          </button>
          {this.state.teamRowLimitLogVisible && (
            <p
              className="form__question__descrption"
              style={{
                color: '#dc3545',
                fontSize: '12px',
              }}
            >
              <br />
              <Trans>TMlimit</Trans>
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default TM;
