import React from 'react';
import { Trans } from 'react-i18next';
import { IG } from '../../consts';
import renderTitle from './title';
import { ApplicationFormContext } from '../ApplicationFormProvider';
import Textarea from '../Ui/Textarea';
import { sectionNumber } from '../ApplicationFormProvider/sectionNumber';

class BusinessModel extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.context.handleApplicationFormChange({
      businessModel: { [name]: value },
    });
  };

  handleInputChangeFM = (event) => {
    if (this.context.getError('costAndVolume'))
      this.context.clearError('costAndVolume');
    if (this.context.getError('expenditure'))
      this.context.clearError('expenditure');
    if (this.context.getError('incomeDistribution'))
      this.context.clearError('incomeDistribution');

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.context.handleApplicationFormChange({
      businessModel: { financialModel: { [name]: value } },
    });
  };

  render() {
    const { readOnly, getError } = this.context;
    const { product, clientPortrait, marketingChannels } =
      this.context.applicationForm.businessModel;
    const programName = this.props.program.name;
    const tableError =
      getError('costAndVolume') ||
      getError('expenditure') ||
      getError('incomeDistribution');

    return (
      <>
        <div className="compform__divider" />
        <h1 className="main__question">
          {sectionNumber.renderSection('BUSINESS')}. {renderTitle(programName)}
        </h1>
        {programName !== IG && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('businessModel.product')}.{' '}
              <Trans>businessModel.product</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>businessModel.productExtra</Trans>
            </p>

            <Textarea
              defaultValue={product}
              name="product"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}

        <h3 className="form__question">
          {sectionNumber.renderSubSection('businessModel.portrait')}.{' '}
          <Trans>businessModel.portrait</Trans>
        </h3>
        <p className="form__question__descrption">
          <Trans>businessModel.portraitExtra</Trans>
        </p>

        <Textarea
          defaultValue={clientPortrait}
          name="clientPortrait"
          onChange={this.handleInputChange}
          readOnly={readOnly}
        />

        <h3 className="form__question">
          {sectionNumber.renderSubSection('projectIdea.marketingChannels')}.{' '}
          <Trans>projectIdea.marketingChannels</Trans>
        </h3>
        <p className="form__question__descrption">
          <Trans>projectIdea.marketingChannelsExtra</Trans>
        </p>
        <Textarea
          defaultValue={marketingChannels}
          name="marketingChannels"
          onChange={this.handleInputChange}
          readOnly={readOnly}
        />

        <h3 className="form__question">
          {sectionNumber.renderSubSection('businessModel.FMBaseExtra')}.{' '}
          {this.renderHeading(programName)}
        </h3>
        <p className="form__question__descrption">
          <Trans>businessModel.FMBaseExtra</Trans>
        </p>
        <div
          className={
            tableError
              ? 'red-border-error application-table__body m-t'
              : 'application-table__body m-t'
          }
        >
          <div className="application-table">{this.renderFinancialModel()}</div>
        </div>
      </>
    );
  }

  renderHeading = (programName) => {
    if (programName === IG) {
      return <Trans>businessModel.FMBaseIG</Trans>;
    }
    return <Trans>businessModel.FMBase</Trans>;
  };

  renderFinancialModel() {
    const { readOnly } = this.context;
    const { financialModel } = this.context.applicationForm.businessModel;

    return (
      <table className="second__table">
        <tbody>
          <tr className="first__table-title">
            <th id="width-60">
              <Trans>financialModel.costAndVolume</Trans>
            </th>
            <td className="expense-plan__td m-t-10">
              <input
                value={financialModel?.costAndVolume}
                maxLength={200}
                className="expense-plan__input"
                type="text"
                name="costAndVolume"
                onChange={(event) => this.handleInputChangeFM(event)}
                readOnly={readOnly}
              />
            </td>
          </tr>
          <tr>
            <th id="width-60">
              <Trans>expenditure</Trans>
            </th>
            <td className="expense-plan__td">
              <input
                value={financialModel?.expenditure}
                maxLength={200}
                className="expense-plan__input"
                type="text"
                name="expenditure"
                onChange={(event) => this.handleInputChangeFM(event)}
                readOnly={readOnly}
              />
            </td>
          </tr>
          <tr>
            <th id="width-60">
              <Trans>financialModel.incomeDistribution</Trans>
            </th>
            <td className="expense-plan__td">
              <input
                value={financialModel?.incomeDistribution}
                maxLength={200}
                className="expense-plan__input"
                type="text"
                name="incomeDistribution"
                onChange={(event) => this.handleInputChangeFM(event)}
                readOnly={readOnly}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default BusinessModel;
