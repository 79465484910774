import React from 'react';
import { Trans } from 'react-i18next';
import BeneficiaryText from './BeneficiaryText';
import { injectAppState } from '../../stores';
import { observer } from 'mobx-react';
import Textarea from '../Ui/Textarea';
import { ApplicationFormContext } from '../ApplicationFormProvider';

@injectAppState
@observer
class FieldRelevance extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.context.handleApplicationFormChange({
      relevance: {
        [name]: value,
        application_id: this.context.applicationForm.application.id,
      },
    });
  };

  render() {
    const { readOnly } = this.context;
    const { couse, beneficiaty, socialProblem, territory } =
      this.context.applicationForm.relevance;
    const { language } = this.props.appState.userStore.language;

    return (
      <>
        <h1 className={'extra-h1'}>
          <Trans>ACTUALNOST</Trans>
        </h1>
        <table style={{ paddingTop: '20px' }} className="talap-form">
          <tbody>
            <tr className={'extra-tr'}>
              <th>
                <label className={'main-label'} htmlFor="">
                  <Trans>socialProblem</Trans>
                </label>
                <p className={'extra-label'}>
                  <Trans>socialProblemExtra</Trans>
                </p>
              </th>
              <td>
                <Textarea
                  className="table__text"
                  defaultValue={socialProblem}
                  name="socialProblem"
                  onChange={this.handleInputChange}
                  readOnly={readOnly}
                />
              </td>
            </tr>
            <tr className={'extra-tr'}>
              <th>
                <label className={'main-label'} htmlFor="">
                  <Trans>couse</Trans>
                </label>
                <p className={'extra-label'}>
                  <Trans>couseExtra</Trans>
                </p>
              </th>
              <td>
                <Textarea
                  className="table__text"
                  defaultValue={couse}
                  name="couse"
                  onChange={this.handleInputChange}
                  readOnly={readOnly}
                />
              </td>
            </tr>
            <tr className={'extra-tr'}>
              <th>
                <label className={'main-label'} htmlFor="">
                  <Trans>territory</Trans>
                </label>
                <p className={'extra-label'}>
                  <Trans>territoryExtra</Trans>
                </p>
              </th>
              <td>
                <Textarea
                  className="table__text"
                  defaultValue={territory}
                  name="territory"
                  onChange={this.handleInputChange}
                  readOnly={readOnly}
                />
              </td>
            </tr>
            <tr className={'extra-tr'}>
              <th>
                <BeneficiaryText />
                <p className={'extra-label'}>
                  <Trans>beneficiatyExtra</Trans>
                </p>
              </th>
              <td>
                <Textarea
                  className="table__text"
                  defaultValue={beneficiaty}
                  name="beneficiaty"
                  onChange={this.handleInputChange}
                  readOnly={readOnly}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default FieldRelevance;
