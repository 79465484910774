import * as React from 'react';
import { Link } from 'react-router-dom';

import './Breadcrumps.css';
import i18next from 'i18next';
import { AppStateObserver } from '../../stores';

export interface BreadcrumbsProps {
  links;
}

const Breadcrumps: React.FC<AppStateObserver & BreadcrumbsProps> = (props) => {
  const links = props.links;
  const lastLinks = links.length - 1;
  return (
    <div className="breadcrumps__container">
      <Link to={'/'} />
      {links
        ? links.map((link, idx) => {
            return (
              <div className="breadcrumps__item" key={idx}>
                <Link to={link.path} className="breadcrumps__link">
                  {idx === lastLinks
                    ? i18next.t(link.name)
                    : i18next.t(link.name) + ' / '}
                </Link>
              </div>
            );
          })
        : ''}
    </div>
  );
};

export default Breadcrumps;
