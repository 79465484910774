import * as React from 'react';
import { differenceInCalendarDays, format } from 'date-fns';
import parse from 'html-react-parser';
import FileComponent from '../components/FileComponent';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react';
import { AppStateObserver, injectAppState } from '../stores';
import localeChanger from '../helpers/localeChanger';
import i18n from 'i18next';

export interface CompetitionInfoProps {
  appStatus?;
  competition;
  statuses;
  loadFile;
  scrollToForm?;
}

export interface CompetitionInfoState {}

@injectAppState
@observer
class CompetitionInfo extends React.Component<
  AppStateObserver & CompetitionInfoProps,
  CompetitionInfoState
> {
  state = {};

  render() {
    const { competition } = this.props;
    const { language } = this.props.appState.userStore;
    const localeDate = localeChanger(i18n.language ? i18n.language : 'ru');

    return (
      <>
        <h1 className="comp__header comp__header--h1">
          {competition.competitionType.name}
        </h1>
        <section className="status__tab2">
          <p className="portal__text portal__text--header">
            {competition.info}
          </p>

          <p className="portal__text">
            <Trans>StartDateApplication</Trans>
          </p>
          <p className="portal__pi__text">
            {competition.dateStartRegistration
              ? format(
                  Date.parse(competition.dateStartRegistration),
                  'dd MMMM u',
                  {
                    locale: localeDate,
                  }
                )
              : 'Не назначена'}
          </p>
          <p className="portal__text">
            <Trans>EndDateApplication</Trans>
          </p>
          <p className="portal__pi__text">
            {competition.dateEndRegistration
              ? format(
                  Date.parse(competition.dateEndRegistration),
                  'dd MMMM u',
                  {
                    locale: localeDate,
                  }
                )
              : 'Не назначена'}
          </p>
          {this.props.appStatus === 'BUSINESS_PLAN' && (
            <>
              <p className="portal__text">
                <Trans>dateDeadline</Trans>
              </p>
              <p className="portal__pi__text">
                {competition.dateDeadline
                  ? format(Date.parse(competition.dateDeadline), 'dd MMMM u', {
                      locale: localeDate,
                    })
                  : 'Не назначена'}
              </p>
            </>
          )}
          <p className="portal__text">
            <Trans>CompetitionType</Trans>
          </p>
          <p className="portal__pi__text">{competition.competitionType.name}</p>
          <p className="portal__text">
            <Trans>Status</Trans>
          </p>
          <p className="portal__pi__text">
            <Trans>{competition.status}</Trans>
          </p>
        </section>
        {competition.status === 'COMPLETED' &&
          this.renderResults(competition.resultText, competition.resultFiles)}
      </>
    );
  }

  renderCompetitionButton(status, dateStart) {
    const { scrollToForm } = this.props;
    const competitionLasts = dateStart
      ? differenceInCalendarDays(new Date(dateStart), new Date())
      : null;

    if (status === 'COLLECTION_OF_APPLICATION') {
      return (
        <div className="competition__right">
          <button className="apply-button" onClick={scrollToForm}>
            <Trans>Apply</Trans>
          </button>
          <p className="competition__last">
            {competitionLasts > 0 ? `Осталось ${competitionLasts} дней` : ''}
          </p>
        </div>
      );
    }
    return <div className="competitionRight" />;
  }

  renderResults(text, files) {
    const { loadFile } = this.props;

    if (files) {
      return (
        <div className="comp-results">
          <h1 className="comp-results__heading">Результаты</h1>
          {text && <p className="comp-results__text">{parse(text)}</p>}

          {files.map((file, idx) => {
            return (
              <div className="comp-result__file" key={idx}>
                <FileComponent
                  key={idx}
                  getFile={loadFile}
                  id={file.file.id}
                  name={file.file.name}
                  extension={file.file.extension}
                  withFileIcon={true}
                  withDownloadIcon={false}
                />
              </div>
            );
          })}
        </div>
      );
    }
  }
}

export default CompetitionInfo;
