import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { AppStateObserver, injectAppState } from '../../stores';
import CompetitionRequirements from '../../components/CompetitionRequirements';
import CompetitionInfo from '../../components/CompetitionInfo';
import ApplicationEditForm from '../../components/ApplicationEditForm';
import ProcessApplicationFormForBusinessPlanView from '../../components/ProcessApplicationFormForBusinessPlanView';
import PersonalTables from '../../components/PersonalTables';
import PersonalViewTables from '../../components/PersonalViewTables';
import ApplicationInfo from '../../components/ApplicationInfo';
import { cubaREST } from '../../cubaREST';
import './applicationDetailNew.css';
import { format } from 'date-fns';
import ProcessApplicationEditForm from '../../components/ProcessApplicationEditForm';
import { Trans } from 'react-i18next';
import LoadButtons from '../../helpers/loadButtons';
import localeChanger from '../../helpers/localeChanger';
import { Container } from 'react-bootstrap';
import Load from '../../components/Load';
import { ApplicationFormProvider } from 'components/ApplicationFormProvider';
import ProgressBarUi from '../../components/Ui/ProgressBarUi';
import i18n from 'i18next';

export interface ApplicationDetailProps {}

export interface ApplicationDetailState {}

@injectAppState
@observer
class ApplicationDetail extends React.Component<
  AppStateObserver & RouteComponentProps,
  ApplicationDetailProps,
  ApplicationDetailState
> {
  state = {
    file: null,
    base64URL: '',
    password: null,
    tabs: [
      { name: 'Programs', active: true },
      // { name: "PlanRashodov", active: false },
      { name: 'teamMembers', active: false },
    ],
    mounted: false,
    experiences: [],
    links: [
      {
        name: 'Main',
        path: '/',
      },
      {
        name: 'li4nyKabinet',
        path: '/cabinet/applications',
      },
      {
        name: 'viewTheApplication',
        path: this.props.match.params['compId'],
      },
    ],
    statuses: {
      NEW: 'Новый',
      COLLECTION_OF_APPLICATION: 'Сбор заявок',
      REVIEW: 'На рассмотрении',
      COMPLETED: 'Завершен',
    },
    intervalId: null,
    directions: [],
    buttonsInfo: new Map(),
    comment: '',
    competitionType: null,
    dataMap: null,
    checkProgram: true,
    agreeProgramPassCheck: false,
    loading: false,
    loadingECP: false,
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.personalRef = React.createRef();
  }

  formRef;
  personalRef;

  loadEntitiesService2 = async () => {
    let application_id = this.props.match.params['id'];
    let competition_id = this.props.match.params['compId'];

    await this.props.appState.applicationsStore.loadEntity(application_id);
    let competition = await this.props.appState.competitionsStore.loadEntity(
      competition_id
    );

    let competitionType = competition.competitionType;
    this.setState({ competitionType });

    let userInfo = await this.props.appState.userStore.getUserInfo2();

    if (competitionType.isEasyStart) {
      this.props.appState.competitionsStore
        .getButtons(application_id, userInfo.id)
        .then((response) => {
          this.setState({ buttonsInfo: JSON.parse(response) });
        })
        .catch(() => {
          this.setState({
            errorMessage:
              'Вы не сможете подать так как у нас отсутствует ИИН или вы не гражданин Казахстана',
          });
        });
    }

    if (competitionType.needTeamMembers === false) {
      this.setState({
        tabs: [
          { name: 'Программы', active: true },
          { name: 'План расходов', active: false },
        ],
      });

      let exp = await this.props.appState.applicationsStore.getExperiences(
        application_id
      );
      this.setState({
        experiences: exp,
      });
    }

    if (competitionType.code === 'SCP') {
      let res = await this.props.appState.applicationsStore.getDirections2();
      this.setState({
        directions: res,
      });
    }

    this.setState({
      mounted: true,
    });
  };

  componentDidMount() {
    this.loadEntitiesService2();

    this.props.appState.breadcrumbsStore.setLinks(this.state.links);
  }

  componentWillUnmount() {
    this.props.appState.breadcrumbsStore.clearLinks();
  }

  loadDocument = (id) => {
    return this.props.appState.filesStore.loadDocument(id);
  };

  loadAttachment = (id) => {
    return this.props.appState.filesStore.loadAttachment(id);
  };

  loadFile = (id) => {
    return this.props.appState.filesStore.downloadFile(id);
  };

  handleLoading = (isLoading) => {
    this.setState({
      loading: isLoading,
      loadingECP: isLoading,
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL: string | ArrayBuffer = '';
      // Make new FileReader
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  handleCommentChange = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  hideByName = (name, applicant) => {
    if (
      name === 'Проверки' ||
      name === 'Направление на членов комиссии' ||
      name === 'Звезды по итогам внутренней оценки' ||
      name === 'Направление на членов внешней комиссии' ||
      name === 'Звезды по итогам внешней оценки' ||
      name === 'Звезды по итогам бизнес плана'
    ) {
      return <Trans>RO</Trans>;
    } else if (name === 'Голосование SP' || name === 'Голосование') {
      return <Trans>InnerCommission</Trans>;
    } else if (
      name === 'Итоговое Правление' ||
      name === 'Правление по итогам внешней оценки' ||
      name === 'Правление по итогам внутренней оценки'
    ) {
      return <Trans>BoardMember</Trans>;
    } else if (name === 'Внешнее голосование') {
      return <Trans>OuterCommission</Trans>;
    } else {
      return applicant;
    }
  };

  loadStars = () => {
    const { application } = this.props.appState.applicationsStore;
    return (
      <React.Fragment>
        <h2 className="stars-count">
          <Trans>starCount</Trans>
        </h2>
        <table className="stars-table">
          <thead>
            <tr>
              <th>
                <p className="stars__info__header">
                  <Trans>ImyaShaga</Trans>
                </p>
              </th>
              <th>
                <p className="stars__info__header">
                  <Trans>KolvoZvezd</Trans>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {application.star.map((star) => (
              <tr>
                <td>
                  <p className="stars__info__value">{star.starDict.taskName}</p>
                </td>
                <td>
                  <p className="stars__info__value">{star.amount}</p>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <p className="stars__info__value">
                  <Trans>Itogo</Trans>
                </p>
              </td>
              <td>
                <p className="stars__info__value">
                  {application.star.reduce((sum, el) => sum + el.amount, 0)}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  statusTable = (dataMap) => {
    const { language } = this.props.appState.userStore;
    const localeDate = localeChanger(i18n.language ? i18n.language : 'ru');
    return (
      <React.Fragment>
        <h2
          style={{
            marginTop: '100px',
            paddingBottom: '10px',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Trans>ProccessState</Trans>
        </h2>
        <table className="datamap-table">
          <thead>
            <tr>
              <th>
                <p className="datamap__info__header">
                  <Trans>Applicant</Trans>
                </p>
              </th>
              <th>
                <p className="datamap__info__header">
                  <Trans>answer</Trans>
                </p>
              </th>
              <th>
                <p className="datamap__info__header">
                  <Trans>naimenovaniye</Trans>
                </p>
              </th>
              <th>
                <p className="datamap__info__header">
                  <Trans>startDate</Trans>
                </p>
              </th>
              <th>
                <p className="datamap__info__header">
                  <Trans>endDate</Trans>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {dataMap.map((data) => {
              return (
                <React.Fragment>
                  <tr>
                    <td>
                      <p className="datamap__info__value">
                        {this.hideByName(data.name, data.applicant)}
                      </p>
                    </td>
                    <td>
                      <p className="datamap__info__value">{data.outcome}</p>
                    </td>
                    <td>
                      <p
                        className="datamap__info__value"
                        style={{ padding: '10px 0px' }}
                      >
                        {data.name}
                      </p>
                    </td>
                    <td>
                      <p className="datamap__info__value">
                        {data.startDate &&
                          format(
                            Date.parse(data.startDate),
                            'dd MMMM u HH:mm',
                            {
                              locale: localeDate,
                            }
                          )}
                      </p>
                    </td>
                    <td>
                      <p className="datamap__info__value">
                        {data.endDate &&
                          format(Date.parse(data.endDate), 'dd MMMM u HH:mm', {
                            locale: localeDate,
                          })}
                      </p>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  handleCheckProgram = (bool) => {
    this.setState({
      checkProgram: bool,
    });
  };

  render() {
    const { language } = this.props.appState.userStore;
    const { competition, isLoadingCompetition } =
      this.props.appState.competitionsStore;
    const { applicant } = this.props.appState.applicantsStore;
    const { application } = this.props.appState.applicationsStore;
    const {
      base64URL,
      password,
      tabs,
      mounted,
      experiences,
      directions,
      competitionType,
      buttonsInfo,
    } = this.state;

    if (isLoadingCompetition) {
      return (
        <div className="loader-container">
          <Load />
        </div>
      );
    }

    if (!cubaREST.restApiToken) {
      return <Redirect to="/sign-in" />; //programPassCheck
    }

    if (
      cubaREST.restApiToken &&
      competition &&
      application &&
      competitionType
    ) {
      return (
        <Container className="wrapper">
          <ProgressBarUi className="progress-page" />
          <Container className="portal__content">
            {this.state.competitionType.isEasyStart && (
              <h1 className="title__cabinet">
                <Trans>viewTheApplication</Trans>
              </h1>
            )}
            <Container>
              <div className="portal__card__home portal__card--content">
                <Container className="status__tab">
                  <ApplicationInfo application={application} />
                  <CompetitionInfo
                    appStatus={application.applicationStatus}
                    competition={competition}
                    statuses={this.state.statuses}
                    loadFile={this.loadFile}
                  />
                  <CompetitionRequirements
                    loadAttachment={this.loadAttachment}
                    loadFile={this.loadFile}
                  />
                </Container>
                <div className="compform__divider" />

                <ApplicationFormProvider>
                  {(application.applicationStatus === 'NEW' ||
                    application.applicationStatus === 'REWORK_DOCS' ||
                    application.applicationStatus === 'PENDING' ||
                    application.applicationStatus === 'TEAMLEAD_REWORK' ||
                    application.applicationStatus ===
                      'REVISION_AFTER_VALIDATION') &&
                    (this.state.competitionType.isEasyStart ? (
                      <ProcessApplicationEditForm
                        espFile={base64URL}
                        password={password}
                        application={application}
                        ref={this.formRef}
                        tabs={tabs}
                        mounted={mounted}
                        experiences={experiences}
                        directions={directions}
                        comment={this.state.comment}
                        checkProgram={this.state.checkProgram}
                        handleCheckProgram={this.handleCheckProgram}
                      />
                    ) : (
                      <ApplicationEditForm
                        espFile={base64URL}
                        password={password}
                        documents={competition.reqDocs}
                        application={application}
                        ref={this.formRef}
                        tabs={tabs}
                        mounted={mounted}
                        experiences={experiences}
                        directions={directions}
                      />
                    ))}
                  {this.state.checkProgram &&
                    application.applicationStatus !== 'NEW' &&
                    application.applicationStatus !== 'REWORK_DOCS' &&
                    application.applicationStatus !== 'PENDING' &&
                    application.applicationStatus !== 'TEAMLEAD_REWORK' &&
                    application.applicationStatus !==
                      'REVISION_AFTER_VALIDATION' && (
                      <ProcessApplicationFormForBusinessPlanView
                        espFile={base64URL}
                        password={password}
                        application={application}
                        ref={this.formRef}
                        checkProgram={this.state.checkProgram}
                        requiredDocuments={competition.reqDocs}
                        comment={this.state.comment}
                      />
                    )}

                  {competition.status !== 'COMPLETED' &&
                    competition.competitionType.code === 'TLP' &&
                    (application.applicationStatus === 'NEW' ||
                      application.applicationStatus === 'REWORK_DOCS') &&
                    applicant && (
                      <PersonalTables
                        applicant={applicant}
                        loadFile={this.loadFile}
                        ref={this.personalRef}
                      />
                    )}
                  {competition.status !== 'COMPLETED' &&
                    competition.competitionType.code === 'TLP' &&
                    application.applicationStatus !== 'NEW' &&
                    application.applicationStatus !== 'REWORK_DOCS' && (
                      <PersonalViewTables
                        applicant={applicant}
                        loadFile={this.loadFile}
                        ref={this.personalRef}
                      />
                    )}
                  {application.applicationStatus === 'PHASE_1_FINISHED' && (
                    <>
                      {this.state.competitionType &&
                        this.state.competitionType.isEasyStart && (
                          <h2
                            style={{
                              color: '#007e6d',
                              fontSize: '24px',
                            }}
                          >
                            <Trans>phase1Finished</Trans>
                          </h2>
                        )}
                    </>
                  )}
                  {competition.status !== 'COMPLETED' &&
                    application.applicationStatus !== 'NEW' &&
                    application.applicationStatus !== 'PENDING' &&
                    application.applicationStatus !== 'TEAMLEAD_REWORK' && (
                      <>
                        {this.state.competitionType &&
                          this.state.competitionType.isEasyStart &&
                          application.star &&
                          application.star.length !== 0 &&
                          this.loadStars()}
                      </>
                    )}

                  {application.applicationStatus !== 'NEW' &&
                    this.state.competitionType &&
                    this.state.competitionType.isEasyStart &&
                    this.state.dataMap &&
                    this.statusTable(this.state.dataMap)}

                  {competition.status !== 'COMPLETED' &&
                    this.state.competitionType.isEasyStart &&
                    buttonsInfo && (
                      <LoadButtons
                        appId={this.props.match.params['id']}
                        buttonsInfo={buttonsInfo}
                        loading={this.state.loading}
                      />
                    )}
                </ApplicationFormProvider>
              </div>
            </Container>
          </Container>
        </Container>
      );
    }

    return null;
  }
}

export default ApplicationDetail;
