import React from 'react';
import { Trans } from 'react-i18next';
import renderHeader from './title';
import DatePickerMultiComponent from '../../DatePickerMulti';
import { ApplicationFormContext } from '../../ApplicationFormProvider';
import { sectionNumber } from '../../ApplicationFormProvider/sectionNumber';

interface IPlanProps {
  namespace: string;
  program: {
    name: string;
    id: string;
  };
}

class Plan extends React.Component<IPlanProps, any> {
  static contextType = ApplicationFormContext;

  addPlan = () => {
    const planForm = {
      event: '',
      description: '',
      deadline: '-',
      number: Math.random() * 1000,
    };
    const calendarPlan = this.context.applicationForm[this.props.namespace]
      .calendarPlan
      ? [...this.context.applicationForm[this.props.namespace].calendarPlan]
      : [];

    if (calendarPlan.length === 20) {
      return;
    }

    calendarPlan.push(planForm);

    this.context.handleApplicationFormChange({
      [this.props.namespace]: { calendarPlan },
    });
  };

  deletePlan = (index) => {
    let calendarPlan = [
      ...this.context.applicationForm[this.props.namespace].calendarPlan,
    ];

    if (calendarPlan.length > 0) calendarPlan.splice(index, 1);

    this.context.handleApplicationFormChange({
      [this.props.namespace]: { calendarPlan },
    });
  };

  handleInputChangePlan = (event, index) => {
    this.context.clearError('calendarPlan');
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const calendarPlan =
      this.context.applicationForm[this.props.namespace].calendarPlan;
    calendarPlan[index][name] = value;

    this.context.handleApplicationFormChange({
      [this.props.namespace]: { calendarPlan },
    });
  };

  handleDateChange = (d, idx) => {
    this.context.clearError('calendarPlan');
    const calendarPlan =
      this.context.applicationForm[this.props.namespace].calendarPlan;
    calendarPlan[idx]['deadline'] = d;

    this.context.handleApplicationFormChange({
      [this.props.namespace]: { calendarPlan },
    });
  };

  onDateChange = ({ target }) => {
    console.log(target.value);
  };

  render() {
    const { readOnly } = this.context;
    const programName = this.props.program.name;
    const calendarPlan =
      this.context.applicationForm[this.props.namespace].calendarPlan;

    return (
      <>
        <h3 className="form__question" id="calendarPlan">
          {sectionNumber.renderSubSection(
            `${this.props.namespace}.calendarPlan`
          )}
          . {renderHeader(programName)}
        </h3>
        <p className="form__question__descrption">
          <Trans>planExtra</Trans>
        </p>
        <div
          className={
            this.context.getError('calendarPlan')
              ? 'red-border-error application-table__body table-calendar'
              : 'application-table__body table-calendar'
          }
        >
          <div className="application-table">
            <table className="second__table">
              <tbody>
                <tr className="first__table-title">
                  <th
                    id="width-70"
                    className="table-calendar__th"
                  >
                    <Trans>event</Trans>
                  </th>
                  <th id="width-30">
                    <Trans>deadline</Trans>
                  </th>
                </tr>
                {calendarPlan &&
                  calendarPlan.map((plan, idx) => {
                    return (
                      <tr className="position-relative" key={idx}>
                        <td className="expense-plan__td m-t-10">
                          <input
                            value={plan.event}
                            className="expense-plan__input"
                            type="text"
                            autoComplete="none"
                            maxLength={200}
                            name="event"
                            onChange={(event) =>
                              this.handleInputChangePlan(event, idx)
                            }
                            readOnly={readOnly}
                          />
                        </td>
                        <td className="expense-plan__td" style={{minWidth: "140px"}}>
                          <DatePickerMultiComponent
                            selected={plan.deadline}
                            idx={idx}
                            handleChangeDate={this.handleDateChange}
                            readOnly={readOnly}
                          />
                        </td>
                        {!readOnly && (
                          <span
                            onClick={() => this.deletePlan(idx)}
                            className="icon-delete top-right"
                          >
                            +
                          </span>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {calendarPlan?.length < 20 && (
          <div className="add-expense__container">
            <button
              className="button-input btn btn-success"
              onClick={this.addPlan}
              disabled={readOnly}
            >
              <Trans>Add</Trans>
            </button>
          </div>
        )}
      </>
    );
  }
}

export default Plan;
