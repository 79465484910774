import * as React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Triangle } from '../../assets/icons/triangle.svg';
import onClickOutside from 'react-onclickoutside';
import { Trans } from 'react-i18next';

import './HeaderMenu.css';

export interface HeaderMenuProps {
  logout;
  handleClickOutside;
}

export interface HeaderMenuState {}

class HeaderMenu extends React.Component<HeaderMenuProps, HeaderMenuState> {
  state = {
    // menu: [
    //   { name: "personalData", link: "/cabinet/personal" },
    //   // { name: "fsrMessages", link: "/cabinet/messages" },
    //   { name: "myApplications", link: "/cabinet/applications" },
    //   // { name: "myContracts", link: "/cabinet/contracts" },
    //   { name: "changePassword", link: "/cabinet/change-password" },
    // ],
  };

  handleClickOutside = () => {
    console.log('logout');
    this.props.handleClickOutside();
  };

  render() {
    // const { menu } = this.state;

    return (
      <div className="header-menu">
        {/*<Triangle className="triangle" />*/}
        <div className="header-menu__container">
          {/*{menu.map((item, idx) => (*/}
          {/*  <Link*/}
          {/*    className="header-menu__item"*/}
          {/*    to={item.link}*/}
          {/*    key={idx}*/}
          {/*    onClick={this.props.handleClickOutside}*/}
          {/*  >*/}
          {/*    <Trans>{item.name}</Trans>*/}
          {/*  </Link>*/}
          {/*))}*/}
        </div>
      </div>
    );
  }
}

export default onClickOutside(HeaderMenu);
